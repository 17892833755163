#pages-offer-detail {
  max-width: 720px !important;
  width: 100% !important;

  .container {
    position: relative;
    padding: 40px;
    background-color: $white;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .slide-wrapper {
      height: calc(100% - 54px);

      .sub-title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 28.8px */
        letter-spacing: -0.18px;
        color: $grey900;
        margin-bottom: 8px;
      }

      > section.intro-section {
        height: 100%;

        .intro-background {
          height: 100%;
          position: relative;
          background: #4c000a;

          h2 {
            font-size: 80px;
            font-style: normal;
            font-weight: 600;
            line-height: 89%; /* 71.2px */
            letter-spacing: -0.8px;
            position: absolute;
            top: 50%;
            width: 100%;
            text-align: center;
            transform: translateY(-50%);
          }

          .logo {
            width: auto;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
              height: 100%;
              width: auto;
            }
          }
        }
      }

      > section.employment-section {
        article.recruitment-title {
          margin-bottom: 40px;

          h3 {
            font-weight: 700;
            padding: 8px;
            background-color: $grey50;
            border-radius: 8px;
            color: $black;
            text-align: center;
          }
        }

        article.contents-wrapper {
          margin-bottom: 24px;

          > aside.contents-desc {
            margin-bottom: 24px;

            p {
              color: $grey900;
              font-weight: 400;
            }
          }

          > aside.contents-employment {
            table {
              width: 100%;

              caption {
                text-indent: -100000px;
                position: absolute;
              }

              thead,
              tbody {
                tr {
                  th,
                  td {
                    padding: 16px 10px;
                    font-size: 12px;
                    line-height: 120%;
                  }

                  th {
                    font-weight: 700;
                    color: $grey900;

                    span {
                      color: $grey500;
                      font-weight: 500;
                      display: block;
                    }
                  }

                  td {
                    color: $grey800;
                    font-weight: 500;
                  }
                }
              }

              thead {
                tr {
                  th {
                    background-color: $grey50;
                  }
                }
              }
            }

            > .position-wrapper {
              border-radius: 4px;
              overflow: hidden;
              border: 1px solid $grey300;
              margin-bottom: 24px;

              table {
                thead,
                tbody {
                  tr {
                    th,
                    td {
                      position: relative;

                      &:not(:last-child) {
                        &::after {
                          content: '';
                          display: block;
                          width: 1px;
                          height: 12px;
                          position: absolute;
                          top: 50%;
                          right: 0;
                          transform: translateY(-50%);
                        }
                      }
                    }
                  }
                }

                thead {
                  tr {
                    border-bottom: 1px solid $grey100;

                    th {
                      text-align: left;

                      &:first-child {
                        padding-left: 26px;
                      }
                      &:last-child {
                        padding-right: 26px;
                      }
                      &::after {
                        background-color: $grey600;
                      }
                    }
                  }
                }
                tbody {
                  tr {
                    td {
                      &:first-child {
                        padding-left: 26px;
                      }
                      &:last-child {
                        padding-right: 26px;
                      }
                      &::after {
                        background-color: $grey200;
                      }
                    }
                  }
                }
              }
            }

            > .pay-wrapper {
              border-radius: 4px;
              overflow: hidden;
              border: 1px solid $grey300;
              margin-bottom: 24px;

              table {
                thead,
                tbody {
                  tr {
                    border-bottom: 1px solid $grey200;

                    th,
                    td {
                      &:not(:last-child) {
                        border-right: 1px solid $grey200;
                      }
                    }
                  }
                }

                thead {
                  tr {
                    &.pd-8 {
                      th {
                        padding-top: 8px;
                        padding-bottom: 8px;

                        &.short-line {
                          border-right: none;
                          position: relative;

                          &:not(:last-child) {
                            &::after {
                              content: '';
                              display: block;
                              width: 1px;
                              height: 12px;
                              background-color: $grey200;
                              position: absolute;
                              top: 50%;
                              right: 0;
                              transform: translateY(-50%);
                            }
                          }
                        }
                      }
                    }

                    th {
                      vertical-align: middle;
                      word-break: keep-all;
                    }
                  }
                }

                tbody {
                  tr {
                    border-bottom: none;

                    td {
                      text-align: center;
                      vertical-align: middle;

                      &.short-line {
                        border-right: none;
                        position: relative;

                        &:not(:last-child) {
                          &::after {
                            content: '';
                            display: block;
                            width: 1px;
                            height: 12px;
                            background-color: $grey200;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            > .tip-wrapper {
              text-align: right;

              span {
                color: $grey900;
              }
            }
          }
        }

        article.offer-detail {
          > .offer-detail-title-box {
            p {
              &::before {
                content: '*';
              }
            }
          }

          p {
            color: $grey900;
            line-height: 160% !important;
            word-break: keep-all;
            position: relative;
            padding-left: 16px;

            &::before {
              content: '-';
              position: absolute;
              top: 0px;
              left: 0;
            }
          }

          > aside.offer-detail-title-box {
            margin-bottom: 40px;
          }
        }
      }

      > section.benefits-section {
        display: flex;
        flex-direction: column;
        gap: 40px;

        article.recruitment-title {
          h3 {
            font-weight: 700;
            padding: 8px;
            background-color: $grey50;
            border-radius: 8px;
            color: $black;
            text-align: center;
          }
        }

        article.benefit-contents-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;

          aside.sub-title {
            margin-bottom: 0;
            font-size: 20px;
          }

          aside.contents {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding-left: 20px;

            .content {
              .content-title {
                color: $grey900;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%; /* 28.8px */
                letter-spacing: -0.18px;

                span {
                  font-size: 14px;
                  color: $grey700;
                  font-weight: 500;
                }
              }

              ul.content-list {
                li {
                  color: $grey900;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 160%;
                  letter-spacing: -0.18px;
                  position: relative;

                  &::after {
                    content: '';
                    display: block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: $grey900;
                    position: absolute;
                    top: 12px;
                    left: -14px;
                  }

                  i {
                    color: $red500;
                  }

                  ul.sub-list {
                    padding-left: 20px;

                    li {
                      &::after {
                        width: 6px;
                        height: 1px;
                        top: 14px;
                      }
                    }
                  }
                }
              }
            }
          }

          aside.caution {
            p {
              color: $grey900;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 160%;
              letter-spacing: -0.18px;
              position: relative;

              i {
                color: $red500;
              }
            }
          }
        }

        article.benefit-button-wrapper {
          .flex {
            display: flex;
            gap: 14px;

            > * {
              flex: 1;
            }
          }
        }
      }
    }

    .button-wrapper {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      section.button-box {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .page-num {
          text-align: center;
          width: 84px;
          font-size: 13px;
          font-weight: 500;
          color: #4d4d4d;
        }

        .arrow {
          cursor: pointer;
          font-size: 0;

          &.prev {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}
