.components-logo-auth-logo {
  padding: 25px 36px;
  border: 1px solid $blue500;
  border-radius: 20px;
  margin-bottom: 50px;

  > .logo-title {
    font-weight: bold;
    color: $blue500;
    // font-size: 24px;
  }
}

// .components-logo-main-logo {
//     // width: 260px;
//     // height: 93px;
//     // border: 1px solid $blue500;
//     // border-radius: 20px;
//     // display: inline-flex;
//     // justify-content: center

//     // >svg {
//     //     &:nth-child(2) {
//     //         margin-right: 2px;
//     //     }

//     //     &:nth-child(3) {
//     //         position: relative;
//     //         top: 4px;
//     //         margin-right: 3px;
//     //     }

//     //     &:nth-child(4) {
//     //         position: relative;
//     //         top: 4px;
//     //         margin-right: 1px;
//     //     }

//     //     &:nth-child(5) {
//     //         position: relative;
//     //         top: 4px;
//     //         margin-right: 1px;
//     //     }

//     //     &:nth-child(6) {
//     //         position: relative;
//     //         top: 1px;
//     //         margin-right: 2px;
//     //     }

//     //     &:nth-child(7) {
//     //         position: relative;
//     //         top: 1px;
//     //         margin-right: 2px;
//     //     }

//     //     &:nth-child(8) {
//     //         position: relative;
//     //         top: 1px;
//     //         margin-right: 1px;
//     //     }

//     //     &:nth-child(9) {
//     //         position: relative;
//     //         top: 1.4px;
//     //         margin-right: 1px;
//     //     }

//     //     &:nth-child(10) {
//     //         position: relative;
//     //         top: 2.4px;
//     //         margin-right: 1px;
//     //     }

//     //     &:nth-child(11) {
//     //         position: relative;
//     //         top: 2.4px;
//     //     }
//     // }
// }

.components-input-auth-input {
  width: 100%;

  > .auth-input {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    border: 1px solid $grey300;
    border-radius: 4px;
    padding: 0 15px;
    gap: 15px;
    transition: border 0.2s, height 0.2;
    vertical-align: middle;

    > input {
      flex: 1;
      // font-size: 16px;
      background-color: transparent;
      z-index: 2;
    }

    > textarea {
      flex: 1;
      // font-size: 16px;
      background-color: transparent;
      overflow-y: hidden;
      resize: none;
      vertical-align: middle;
      z-index: 2;
    }

    &.textarea {
    }

    > .auth-input-placeholder {
      position: absolute;
      left: 15px;
      z-index: 1;
      transition: transform 0.2s, font-size 0.2s, background-color 0.4s,
        top 0.2s;
      color: $grey500;
    }

    > .auth-input-icon {
      &.active {
        cursor: pointer;
      }
    }

    &:focus-within,
    &.fill {
      border: 1px solid $blue500;

      > .auth-input-placeholder {
        // transform: translateY(-28px);
        top: -8px;
        // font-size: 10px;
        background-color: $app_background_color;
        padding: 0 10px;
        z-index: 1;
        left: 5px;

        &.white {
          background-color: $white;
        }
      }
    }

    &.disabled {
      border: 1px solid $grey_disabled_color;

      > input {
        color: $grey300;

        &::placeholder {
          color: $grey300;
        }
      }

      > .auth-input-placeholder {
        color: $grey300;
      }
    }
  }

  > .message {
    // font-size: 12px;
    margin-top: 8px;
    margin-left: 0.2em;
  }

  &.form-input-time {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    width: 80px;
    height: 40px;
    background: $white;
    > .auth-input {
      border: 1px solid $grey300 !important;
      border-radius: 4px !important;
      > input {
        width: 50px;
      }
      &:hover,
      &.active,
      &.selected {
        border: 1px solid $grey900 !important;
      }
    }
  }

  &.primary {
    > .auth-input {
      border: 1px solid $blue500;
    }

    > .message {
      color: $blue500;
    }
  }

  &.error {
    > .auth-input {
      border: 1px solid $pink500;
    }

    > .message {
      color: $pink500;
    }
  }
}

.components-select-auth-select-box {
  position: relative;
  width: 100%;

  > .toggle {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    border: 1px solid $grey300;
    border-radius: 4px;
    padding: 0 15px;
    gap: 15px;
    transition: border 0.2s;
    margin-right: 15px;

    > .toggle-placeholder {
      left: 0;
      opacity: 0;
      position: absolute;
      z-index: -1;
      transition: transform 0.2s, font-size 0.2s, background-color 0.4s;
      color: $grey500;
    }

    &::after {
      content: url('/assets/images/icon/chevron-bottom.svg');
      position: absolute;
      top: 14px;
      right: 15px;
    }

    &:hover,
    &.active {
      border: 1px solid $blue500;
    }

    &.selected {
      > .toggle-placeholder {
        opacity: 1;
        padding: 0 10px;
        background-color: $app_background_color;
        // font-size: 10px;
        z-index: 1;
        left: 5px;
        transform: translateY(-28px);
      }
    }
  }

  > .message {
    // font-size: 12px;
    margin-top: 8px;
    margin-left: 0.2em;
  }

  &.form-time-select {
    > .toggle {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 4px 8px 12px;
      gap: 24px;
      width: 77px;
      height: 40px;
      background: $white;
      border: 1px solid $grey300;
      border-radius: 4px;
      margin-right: 0px;
      &:hover,
      &.active,
      &.selected {
        border: 1px solid $grey900 !important;
      }
      &::after {
        top: 4px;
      }
    }
    > .dropdown {
      display: none;
    }
  }
  // &.form-question-select{
  //     >.toggle{
  //         box-sizing: border-box;
  //         display: flex;
  //         flex-direction: row;
  //         align-items: center;
  //         padding: 8px 4px 8px 12px;
  //         gap: 24px;
  //         width: 97px;
  //         height: 40px;
  //         background: $white;
  //         border: 1px solid $grey300;
  //         border-radius: 4px;
  //         >.toggle-placeholder{
  //             display: none;
  //         }
  //         &::after {
  //             top: 4px;
  //         }
  //     }
  // }

  &.primary {
    > .toggle {
      border: 1px solid $blue500;
    }

    > .message {
      color: $blue500;
    }
  }

  &.error {
    > .toggle {
      border: 1px solid $red500;
    }

    > .message {
      color: $red500;
    }
  }

  > .dropdown {
    position: absolute;
    width: 100%;
    left: -10px;
    top: 56px;
    overflow: hidden;
    z-index: 10;
    padding: 10px;
    box-sizing: content-box;

    > ul {
      max-height: 250px;
      overflow: auto;
      background-color: white;
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
        0px 1px 2px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      padding: 8px 0;

      &.slide-in {
        animation: slide-in ease-out 0.15s;
      }

      &.slide-out {
        animation: slide-out ease-out 0.15s forwards;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $grey500;
        border-radius: 10px;
        border-left: 1px solid $grey100;
        border-right: 1px solid $grey100;
      }

      &::-webkit-scrollbar-track {
        margin: 3px 0;
        border-radius: 10px;
        background-color: $grey100;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
      }

      @keyframes slide-in {
        0% {
          transform: translateY(-100%);
        }

        100% {
          transform: translateY(0);
        }
      }

      @keyframes slide-out {
        0% {
          transform: translateY(0);
        }

        100% {
          transform: translateY(calc(-100% - 10px));
        }
      }

      > li {
        cursor: pointer;
        padding: 15px 15px;

        &:hover {
          background-color: rgba($grey500, 0.1);
        }

        &:active {
          background-color: rgba($grey500, 0.2);
        }
      }
    }
  }
}

.components-dropdown-dropdown {
  position: relative;

  > .container {
    position: absolute;
    left: -5px;
    width: calc(100% + 10px);
    height: 150px;
    overflow: hidden;

    > ul {
      position: absolute;
      left: -5px;
      top: 5px;
      padding-left: 0;
      list-style: none;
    }
  }
}

.components-pager-table-pager {
  display: inline-flex;
  align-items: center;

  > .title {
    // font-size: 16px;
    color: $grey700;

    &::after {
      content: ':';
      margin-right: 8px;
    }
  }

  > .offset {
    margin-right: 16px;
    position: relative;
    width: 86px;
    height: 32px;

    > .offset-toggle {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 1px solid $grey300;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;

      &::before {
        content: url('/assets/images/icon/list.svg');
      }

      &::after {
        content: url('/assets/images/icon/chevron-bottom-pager.svg');
        position: relative;
        top: -2px;
      }

      &:hover {
        border: 1px solid $grey500;
      }
    }

    ul {
      position: absolute;
      top: calc(100% + 5px);
      width: 100%;
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
        0px 1px 2px rgba(0, 0, 0, 0.3);
      background-color: white;
      padding: 4px 0;

      > li {
        cursor: pointer;
        height: 36px;
        padding: 0 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
          background-color: $grey100;
        }
      }
    }
  }

  > .current {
    color: $grey700;
    // font-size: 16px;

    span {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }

  > .prev {
    margin-right: 4px;
  }

  > .prev,
  > .next {
    width: 40px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: $grey100;
    }

    &:active {
      background-color: rgba($grey500, 0.3);
    }
  }
}

.components-logo-empty-table,
.components-logo-empty-assessments {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  > img {
    margin-bottom: 40px;
  }

  > p {
    text-align: center;
    // line-height: 36.4px;
    // font-size: 20px;
  }
}

.components-table-common-table {
  > thead {
    > tr {
      > th {
      }
    }
  }

  > tbody {
    > tr {
      > td {
        &.actions {
          > .container {
            display: flex;
            justify-content: flex-end;

            > .actions-icon {
              cursor: pointer;
              width: 24px;
              height: 24px;
              display: inline-flex;
              justify-content: center;
              align-items: center;

              &::before {
                content: '';
                width: 4px;
                height: 16px;
                mask-image: url('/assets/images/icon/more.svg');
                mask-position: center;
                mask-size: contain;
                background-color: $grey900;
              }
            }
          }
        }
      }
    }
  }
}

.test {
  > .card {
    // width: 322px;
    height: 350px;
    background-color: white;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > header {
      // font-size: 18px;
      // line-height: 26.1px;
      font-weight: bold;
    }

    > main {
      > ul {
        display: flex;
        gap: 16px;

        > li {
          padding: 6px 12px;
          background-color: rgba($blue500, 0.3);
          border-radius: 8px;
          // font-size: 14px;
          color: $grey900;
        }
      }
    }

    > footer {
      > span {
        // font-size: 18px;
        font-weight: bold;

        &::before {
          content: url('/assets/images/icon/timer.svg');
          margin-right: 7px;
          position: relative;
          top: 3px;
        }
      }
    }
  }
}

.components-card-skill-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 306px;
  height: 292px;
  background: $white;
  border: 1px solid $grey300;
  border-radius: 16px;
  justify-content: space-between;
  position: relative;
  &:hover,
  &.selected {
    border: 1px solid $blue500;
  }
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;

    > .types {
      display: flex;
      flex-direction: row;
      gap: 4px;
    }
    > .lock {
      cursor: pointer;
    }
  }

  > .title {
    // font-size: 18px;
    // line-height: 26.1px;
    font-weight: bold;
    height: 52px;
  }

  > ul {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    height: 80px;
    // overflow: auto;
    overflow: hidden;

    > li {
      padding: 6px 12px;
      background: $blue50;
      border-radius: 8px;
      // font-size: 14px;
      color: $grey900;
      max-width: 100%;
      height: 32px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  > footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 258px;
    height: 40px;

    > .duration {
      font-style: normal;
      font-weight: 500;
      align-items: center;
      color: $grey700;
      display: flex;
      flex-grow: 1;

      &::before {
        content: url('/assets/images/icon/timer.svg');
        margin-right: 2px;
        position: relative;
        top: 3px;
      }
    }

    > button {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      height: 40px;
      background: $white;
      border: 1px solid $grey300;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      color: $grey900;
      flex-grow: 0;
      &.help {
        color: $blue200;
        border: 1px solid $blue200;
      }
    }
  }

  &.pending {
  }
}

.components-card-category-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: 306px;
  height: 268px;
  background: $white;
  border: 1px solid $grey300;
  border-radius: 16px;
  justify-content: space-between;
  position: relative;
  > .title {
    width: 258px;
    // height: 52px;
    font-weight: 700;
    color: $grey900;
  }

  > ul {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    > li {
      padding: 6px 12px;
      background-color: rgba($blue500, 0.3);
      border-radius: 8px;
      color: $grey900;
    }
  }

  > footer {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: 0px;

    > button {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      width: 78px;
      height: 40px;
      background: $white;
      border: 1px solid $blue500;
      border-radius: 4px !important;
      > span {
        font-style: normal;
        font-weight: 500;
        display: flex;
        align-items: center;
        text-align: center;
        color: $blue500;
      }

      &.disabled {
        border: 1px solid $blue200;
        pointer-events: none;
      }

      &.selected {
        background: $blue500;
        gap: 8px;
        color: $white !important;
        > span {
          color: $white !important;
        }
      }
      &.selected:after {
        width: 12px;
        height: 12px;
        display: inline-block;
        content: '';
        -webkit-mask: url('/assets/images/icon/close-assessments.svg') no-repeat
          50% 50%;
        mask: url('/assets/images/icon/close-assessments.svg') no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
      }
      &.selected.white:after {
        background-color: $white;
      }
      &.selected:hover {
        background: $blue600;
      }

      &:after {
        width: 12px;
        height: 12px;
        display: inline-block;
        content: '';
        -webkit-mask: url('/assets/images/icon/add-assessments.svg') no-repeat
          50% 50%;
        mask: url('/assets/images/icon/add-assessments.svg') no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
      }
      &.blue:after {
        background-color: $blue500;
      }
      &:hover {
        background: $blue50;
      }
    }
  }

  &.pending {
  }
  &:hover {
    background: $grey50;
  }
  &.selected {
    border: 2px solid $blue500;
  }
}

// icon select dropdown
.dropdown-container {
  position: relative;

  .dropdown-toggle-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 8px;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.01em;
    color: $grey900;
    border: 1px solid $grey300;
    border-radius: 4px;
    width: 100%;
    transition: all 0.2s;

    > span {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    > i {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0;
      width: 20px;
      height: 20px;
    }

    &:hover {
      border-color: $grey700;
    }
    &:focus {
      border-color: $blue500;
      box-shadow: 0px 0px 0px 3px rgba(50, 148, 238, 0.25);
    }
    &:disabled {
      * {
        opacity: 0.6;
        color: $grey500;
      }
    }
  }

  .dropdown-list-box {
    position: absolute;
    width: calc(100% + 20px);
    top: 100%;
    overflow: hidden;
    padding: 0 10px 10px;
    left: -10px;
    z-index: 2;

    > .dropdown-list {
      padding: 8px 0;
      background-color: $white;
      overflow: auto;
      width: 100%;
      border-radius: 4px;
      text-align: left;
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
        0px 1px 2px rgba(0, 0, 0, 0.3);

      &.slide-in {
        animation: slide-in ease-out 0.15s;
      }

      &.slide-out {
        animation: slide-out ease-out 0.15s forwards;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $grey500;
        border-radius: 10px;
        border-left: 1px solid $grey100;
        border-right: 1px solid $grey100;
      }

      &::-webkit-scrollbar-track {
        margin: 3px 0;
        border-radius: 10px;
        background-color: $grey100;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
      }

      @keyframes slide-in {
        0% {
          transform: translateY(-100%);
        }

        100% {
          transform: translateY(0);
        }
      }

      @keyframes slide-out {
        0% {
          transform: translateY(0);
        }

        100% {
          transform: translateY(calc(-100% - 10px));
        }
      }

      > .dropdown-list-item {
        padding: 12px 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 145%;
        cursor: pointer;
        transition: background 0.2s;

        &:hover {
          background: $grey100;
        }
      }
    }
  }
}

.timePicker {
  margin-top: 8px !important;
  border: 1px solid $grey300;

  &.false {
    background-color: $grey100;
    pointer-events: none;

    .Mui-error {
      fieldset {
        border-color: #ccc;
      }
    }
  }
}

// 언어 테스트 result 언어별 component
.language-result-container {
  width: 100%;

  .title-box {
    margin-bottom: 16px;

    h4 {
      font-weight: 700;
    }
  }

  .table {
    border: 1px solid $grey100;
    border-radius: 4px;
    // head-tr
    .head-tr {
    }

    // body-tr
    .body-tr {
      .body-td {
        font-weight: 500;

        &.grade {
          font-weight: 700;
          color: $grey400;

          .flex {
            display: flex;
            align-items: center;
            gap: 16px;
          }
        }

        &.blue {
          background: linear-gradient(180deg, #3294ee 0%, #0066ff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }

        &.yellow {
          background: linear-gradient(180deg, #ffc42b 0%, #ff8a00 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }

        &.red {
          background: linear-gradient(336.21deg, #fe463a 8.85%, #ff842c 94.65%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }

      .total-result {
        svg {
          max-width: 30px;
          max-height: 36px;
        }
      }
    }
  }
}

// 공통 버튼 스타일(<Button />)
.button-component {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  padding: 8px 12px;
  width: auto;
  height: auto;
  text-align: center;
  background: #2e3338;
  border-radius: 4px;
  font-weight: 500;
  line-height: 120%;
  color: $white;
  cursor: pointer;
  font-weight: 500;

  // button size
  &.size-26 {
    min-height: 26px;
    max-height: 26px;
    padding: 4px 8px;
  }

  &.size-32 {
    min-height: 32px;
    padding: 8px;
  }
  &.size-40 {
    min-height: 40px;
    padding: 8px 12px;
  }
  &.size-48 {
    min-height: 48px;
    padding: 8px 24px;
  }
  &.size-52 {
    min-height: 52px;
    padding: 8px 24px;
  }
  &.size-56 {
    min-height: 56px;
    padding: 8px 24px;
  }

  // button-theme
  &.grey700 {
    background: $grey700;

    &:hover,
    &:focus {
      background: $grey900;
    }
    &:active {
      background: $grey900;
    }
    &:disabled {
      background: $grey300;
    }
  }
  &.grey {
    background: $grey900;

    &:hover,
    &:focus {
      background: $black;
    }
    &:active {
      background: $black;
    }
    &:disabled {
      background: $grey400;
    }
  }
  &.blue {
    background: $blue500;

    &:hover {
      background: $blue600;
    }
    &:focus,
    &:active {
      background: $blue700;
    }
    &:disabled {
      background: $blue200;
    }
  }
  &.red {
    background: $red500;

    &:hover {
      background: $red600;
    }
    &:focus,
    &:active {
      background: $red700;
    }
    &:disabled {
      background: $red200;
    }
  }
  &.borderGrey {
    background: $white;
    border: 1px solid $grey300;
    color: $grey900;

    &:hover {
      background: $grey100;
    }
    &:focus,
    &:active {
      background: $grey200;
    }
    &:disabled {
      background: $white;
      color: $grey300;
      border-color: $grey300;
      opacity: 0.5;
    }
  }
  &.borderBlue {
    background: $white;
    border: 1px solid $blue500;
    color: $blue500;

    &:hover {
      background: $blue50;
    }
    &:focus,
    &:active {
      background: $blue100;
    }
    &:disabled {
      background: $white;
      color: $blue200;
      border-color: $blue200;
    }
  }

  &:disabled {
    cursor: default;
  }
}

// Basic Input
.basic-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .input-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.14px;
    color: $grey700;
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      color: $red500;
    }
  }

  .input-box {
    position: relative;
    display: inline-block;

    .input {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 145%;
      letter-spacing: -0.16px;
      padding: 16px 14px;
      border: 1px solid $grey300;
      border-radius: 4px;
      width: 100%;

      &.password,
      &.showIcon {
        padding-right: 42px;
      }

      &.padding-left {
        padding-left: 70px;
      }

      &::placeholder {
        color: $grey400;
      }
    }

    .default-text {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      color: $grey500;
      font-weight: 500;
    }

    .password-icon,
    .icon {
      position: absolute;
      max-width: 20px;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
    }
  }

  .not-allow {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.14px;
    color: $red500;
  }

  .input-caution {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 130% !important; /* 15.6px */
    letter-spacing: -0.24px;
    color: $grey700;
  }
}

// Basic Select
.basic-select {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;

  .input-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.14px;
    color: $grey700;

    i {
      color: $red500;
    }
  }

  .toggle {
    cursor: pointer;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.16px;
    padding: 16px 14px;
    border: 1px solid $grey300;
    border-radius: 4px;

    &.disabled {
      cursor: default;
      background-color: $grey50 !important;
      color: $grey400;
    }

    span {
      max-width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    svg {
      transition: all 0.2s ease-out;
    }

    &.true {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  .dropdown-box {
    position: absolute;
    width: calc(100% + 20px);
    top: 100%;
    padding: 0 10px 10px;
    left: -10px;
    z-index: 2;
    overflow: hidden;

    ul.dropdown-list {
      padding: 8px 0;
      background-color: $white;
      overflow: auto;
      width: 100%;
      border-radius: 4px;
      text-align: left;
      overflow-y: auto;
      max-height: 250px;
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
        0px 1px 2px rgba(0, 0, 0, 0.3);

      li.dropdown-list-item {
        padding: 12px 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 145%;
        cursor: pointer;
        transition: background 0.2s;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
          background: $grey100;
        }
      }

      &.slide-in {
        animation: slide-in ease-out 0.15s;
      }

      &.slide-out {
        animation: slide-out ease-out 0.15s forwards;
      }
    }
  }

  .input-caution {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 130% !important; /* 15.6px */
    letter-spacing: -0.24px;
    color: $grey700;
  }
}

// Basic File
.basic-file {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;

  .input-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.14px;
    color: $grey700;

    i {
      color: $red500;
    }
  }

  .input-box {
    label {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 145%;
      letter-spacing: -0.16px;
      padding: 16px 14px;
      border: 1px solid $grey300;
      border-radius: 4px;
      cursor: pointer;
      color: $grey400;

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }

      span {
        max-width: 90%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .input-caution {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 15.6px */
    letter-spacing: -0.24px;
    color: $grey700;
  }
}

// toggle slide
.toggle-container {
  // common header
  ul.toggle-header {
    display: flex;
    border-top: 1px solid $grey100;
    border-bottom: 1px solid $grey100;

    li {
      padding: 17px 16px;
      color: $grey900;
      font-weight: 700;

      &:nth-child(1) {
        flex: 5;
      }
      &:nth-child(2) {
        flex: 2;
      }
      &:nth-child(3) {
        flex: 5;
      }
      &:nth-child(4) {
        flex: 1;
      }
    }
  }

  .toggle-body-header {
    ul.toggle-header {
      border: none;
      align-items: center;
      cursor: pointer;

      li {
        i.tag {
          display: inline-block;
          padding: 4px 8px;
          color: $white;
          background-color: $blue500;
          border-radius: 16px;

          &.video {
            background-color: $pink500;
          }
          &.essay {
            background-color: $orange700;
          }
          &.objective {
            background-color: $purple500;
          }
          &.file-upload {
            background-color: $orange500;
          }
          &.multiple-choice {
            background-color: $purple500;
          }
          &.styling {
            background-color: $green900;
          }
          &.code,
          &.front-code {
            background-color: $indigo900;
          }
        }

        span.arrow-toggle-button {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.15s ease-in-out;

          &.true {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .toggle-body-contents {
    overflow: hidden;

    .toggle-body-wrapper {
      padding: 40px 16px;

      &.slide-in {
        animation: slide-in ease-out 0.15s;
      }

      &.slide-out {
        animation: slide-out ease-out 0.15s forwards;
      }
    }
  }
}

.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .no-data-image-box {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
  }

  .no-data-warning-box {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    width: 400px;
    background: #f1f3f5;
    .no-data-warning-text {
      text-align: center;
      color: #57616b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%;
      letter-spacing: -0.14px;
    }
  }
}

.no-candidate-container {
  display: flex;
  height: 80%;
  padding: 32px;
  margin: 25px 80px 80px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  background-color: #f8f9fa;
  .no-candidate-image-box {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
  }

  .no-candidate-warning-box {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    width: 400px;
    background: #f1f3f5;
    .no-candidate-warning-text {
      text-align: center;
      color: #57616b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%;
      letter-spacing: -0.14px;
    }
  }
}

// bar graph score
.bar-graph-score {
  text-align: left;

  .percent-wrapper {
    span {
      font-weight: 500;
      color: $grey900;
    }
  }

  .progress-wrapper {
    font-size: 0;
    display: flex;
    border-radius: 4px;
    overflow: hidden;

    svg {
      width: 100%;
      background-color: $grey100;
    }
  }
}

// skill & proctoring round
.round-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  .skill-round {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    background-color: $grey500;
    border-radius: 50%;
    font-size: 12px;
    color: $white;
    font-weight: 700;

    .tooltip {
      display: none;
      flex-direction: row;
      padding: 8px;
      gap: 10px;
      width: 200px;
      background: $grey100;
      border-radius: 4px;
      font-weight: 500;
      align-items: center;
      text-align: center;
      letter-spacing: -0.02em;
      color: $grey900;
      margin-left: -90px;
    }

    &:hover {
      .tooltip {
        display: block;
        position: fixed;
      }
    }
  }
}

// tag
.common-tag {
  display: inline-block;
  padding: 4px 6px;
  border-radius: 16px;
  color: $white;
  font-weight: 700;
  text-align: center;

  &.red500 {
    background-color: $red500;
  }
  &.green500 {
    background-color: $green500;
  }
  &.orange500 {
    background-color: $orange500;
  }
  &.grey300 {
    background-color: $grey300;
    color: $grey600;
  }
  &.blue50 {
    background-color: $blue50;
    color: $blue600;
  }
  &.blue500 {
    background-color: $blue500;
  }
  &.orange500 {
    background-color: $orange500;
  }
}

// 댓글 input
.comment-input-box {
  .input-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;

    textarea.comment-input {
      display: inline-block;
      padding: 20px;
      padding-right: 80px;
      width: 100%;
      font-weight: 500;
      color: $grey900;
      background-color: $grey100;
      border: 1px solid $grey200;
      border-radius: 8px;
      overflow-x: hidden;

      &::placeholder {
        color: $grey700;
      }
    }

    button.submit-button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }
}

// score bar chart
.bar-chart-box {
  display: flex;
  flex-wrap: wrap;

  .number {
    display: inline-block;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.24px;
    order: 3;
    width: 60px;
    margin-left: 8px;
    text-align: center;

    i {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .bar-chart-graph {
    position: relative;
    height: 30px;
    max-width: calc(100% - 68px);
    overflow: hidden;
    border-radius: 6px;
    order: 2;

    .score-graph {
      background-color: $blue500;
      position: absolute;
      top: 50%;
      left: 0;
      height: 20px;
      width: 100%;
      transform: translateY(-50%);
      border-radius: 6px;
      transition: all 0.2s ease-in-out;
    }
  }

  .chart-title {
    order: 1;
    display: inline-block;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;
    color: $grey700;
    word-break: keep-all;
    width: 100%;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

// assessment test toggle
.test-toggle-box {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .toggle-header {
    padding-right: 10px;

    .toggle-header-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &.disabled {
        cursor: default;

        .toggle-button {
          display: none;
          cursor: default;
        }
      }

      .toggle-button {
        cursor: pointer;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;

        &.active {
          transform: rotate(180deg);
        }
      }
    }

    .toggle-header-score-box {
      .toggle-header-score {
      }

      .toggle-header-feedback {
        display: flex;
        gap: 20px;
        padding: 20px 0;

        .toggle-header-feedback-title {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 145%; /* 26.1px */
          letter-spacing: -0.18px;
          color: $grey700;
          width: 100px;
          text-align: center;
        }

        .toggle-header-feedback-desc {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 145%; /* 20.3px */
          letter-spacing: -0.14px;
          color: $grey800;
          white-space: pre-wrap;
        }

        .feedback-container {
          display: flex;
          gap: 14px;
          width: 100%;

          .feedback-wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;

            &.score-bar {
              flex: 1;
              padding: 12px 20px;
              background-color: $white;
              border: 1px solid $grey300;
              border-radius: 8px;

              .feedback-score {
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;

                .feedback-title {
                  color: $grey900;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 145%;
                  letter-spacing: -0.14px;
                }

                .feedback-score {
                  color: $blue500;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 145%; /* 29px */
                  letter-spacing: -0.2px;
                }
              }
            }

            .feedback-score {
              display: flex;
              align-items: center;
              gap: 12px;

              .feedback-title {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 145%; /* 26.1px */
                letter-spacing: -0.18px;
                color: $grey700;
              }
            }
          }
        }
      }
    }
  }

  .toggle-body-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    overflow: hidden;

    .toggle-body-content {
      &.styling {
        padding: 28px 32px;
        flex-direction: row;
        border-radius: 8px;
        background-color: $grey100;

        .styling-title {
          text-align: left;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 145%; /* 23.2px */
          letter-spacing: -0.16px;
          color: $grey900;
          margin-bottom: 8px;
          color: $grey800;
        }

        .toggle-body-answer-box {
          .img-box {
            flex: 5;
            text-align: center;

            .img-bg {
              background-color: $white;

              img {
                display: inline-block;
                width: auto;
                height: 400px;
                margin: 0 auto;
              }
            }
          }

          .answer-box {
            flex: 5;
            display: block;

            .answer-wrapper {
              .result-stt-box {
                padding: 44px 20px;
                p {
                  max-height: 270px;
                }
              }
            }
          }
        }

        .print-toggle-body-answer-box {
        }
      }

      &.language {
        .category-section {
          display: flex;
          flex-direction: column;
          gap: 50px;

          .category-score-box {
            display: flex;
            padding: 10px 0;

            .category-title {
              display: inline-block;
              width: 100px;
              font-weight: 600;
              color: $grey700;
            }

            .score-wrapper {
              width: calc(100% - 100px);

              .score-container {
                width: 100%;

                .right {
                  width: 100%;

                  .score-box {
                    width: 100%;
                    cursor: default;

                    .middle {
                      flex: 1;
                    }
                  }
                }
              }

              .measurement-box {
                display: flex;
                align-items: center;
                position: relative;
                padding-top: 28px;

                &::after {
                  content: '';
                  display: block;
                  height: 1px;
                  width: 100%;
                  background-color: $grey300;
                  position: absolute;
                  top: 16px;
                  left: 0;
                }

                p {
                  position: relative;
                  text-align: center;
                  flex: 1;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 140%; /* 16.8px */
                  letter-spacing: -0.12px;
                  color: $grey500;

                  &::after {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 12px;
                    background-color: $grey300;
                    position: absolute;
                    top: -17px;
                    right: 0;
                  }

                  &:first-child {
                    &::before {
                      content: '';
                      display: block;
                      width: 1px;
                      height: 12px;
                      background-color: $grey300;
                      position: absolute;
                      top: -17px;
                      left: 0;
                    }
                  }
                }
              }
            }
          }

          .toggle-body-answer-box {
            margin-top: -10px;
            width: 100%;
          }
        }
      }

      &.multiple-choice {
        &.print {
          .toggle-body-answer-box {
            width: 100%;
            display: block !important;
          }

          .table-border {
            overflow: hidden;
            width: 100%;

            .multiple-table {
              min-width: fit-content;

              th,
              td {
                padding: 16px;
              }

              thead {
                tr {
                  background-color: $grey100;

                  th {
                    font-size: 12px;
                  }
                }
              }

              tbody {
                background-color: $white;

                tr {
                  td {
                    font-size: 12px;
                    color: $grey700;

                    &.bold {
                      font-weight: 700;
                      color: $grey900;
                    }

                    &.cat_title {
                      padding-left: 16px;
                    }
                  }
                }
              }
            }

            .bar-graph-score {
              .percent-wrapper {
                > span {
                  font-size: 12px !important;
                }
              }
            }
          }
        }

        .multiple-table {
          th,
          td {
            padding: 16px;
          }

          thead {
            tr {
              background-color: $grey100;

              th {
                font-size: 14px;
              }
            }
          }

          tbody {
            background-color: $white;

            tr {
              td {
                font-size: 16px;
                color: $grey700;

                &.bold {
                  font-weight: 700;
                  color: $grey900;
                }

                &.cat_title {
                  padding-left: 32px;
                }
              }
            }
          }
        }
      }

      &.coding {
        .toggle-body-answer-box {
          flex-direction: column;
          gap: 40px;

          .answer-box {
            .question-box {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .flex {
                display: flex;
                align-items: center;
                gap: 40px;

                .coding-title {
                  color: $grey700;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 140%;
                  letter-spacing: -0.2px;
                }
              }

              .coding-question {
                color: $grey900;
                font-size: 16px !important;
                font-style: normal;
                font-weight: 500;
                line-height: 145%;
                letter-spacing: -0.16px;
                word-break: keep-all;
              }
            }

            article.coding-answer-content {
              display: flex;
              background-color: $grey900;
              min-height: 400px;
              max-height: 600px;

              aside.coding-answer-left-container {
                flex: 6;
              }

              aside.coding-answer-right-container {
                flex: 4;

                .coding-answer-result-title {
                  padding: 8px 16px;
                  background-color: $grey800;

                  h6 {
                    color: $white;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 145%;
                    letter-spacing: -0.16px;
                  }
                }

                .coding-answer-box {
                  padding: 8px 16px;
                  height: calc(100% - 40px);
                  overflow-y: auto;

                  pre {
                    color: $white;
                    font-size: 16px !important;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 145%;
                    letter-spacing: -0.16px;
                  }
                }
              }
            }
          }
        }
      }

      &.custom-multiple {
        .toggle-body-answer-box {
          > * {
            flex: 1 !important;
          }

          > aside {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &.question-box {
              ul {
                li {
                  border-color: $blue500;
                }
              }
            }

            span {
              display: inline-block;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 145%;
              letter-spacing: -0.16px;
              color: $grey800;
            }

            ul {
              display: flex;
              flex-direction: column;
              gap: 16px;

              li {
                &.selected {
                  border-color: $blue500;
                }

                color: $grey900;
                padding: 16px;
                border-radius: 4px;
                border: 1px solid $grey300;
                background-color: $grey50;
              }
            }
          }
        }
      }

      &.custom-file-upload {
        display: flex;
        flex-direction: column;
        gap: 16px;

        & > * {
          flex: 1;
        }

        .toggle-body-answer-box {
          .answer-box {
            flex-direction: row;

            & > * {
              flex: 1;
            }

            .result-file-upload-box {
              span {
                display: inline-block;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 145%;
                letter-spacing: -0.16px;
                color: $grey800;
                margin-bottom: 16px;
              }
            }
          }
        }
      }

      .toggle-body-title-box {
        display: flex;
        gap: 6px;
        margin-bottom: 24px;

        span.question-number {
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 31.2px */
          letter-spacing: -0.24px;
          color: $grey900;
        }

        pre.question {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          letter-spacing: -0.16px;
          color: $grey900;
        }
      }

      .toggle-body-answer-box {
        display: flex;
        gap: 26px;

        .video-box {
          flex: 4;
          text-align: center;
          border-radius: 8px;
          overflow: hidden;

          video {
            width: auto;
            max-height: 230px;
          }
        }

        .answer-box {
          flex: 6;
          display: flex;
          flex-direction: column;
          gap: 16px;

          .result-stt-box {
            p {
              max-height: 200px;
              overflow-y: auto;
            }
          }
        }
      }

      &.slide-in {
        animation: slide-in ease-out 0.15s;
      }

      &.slide-out {
        animation: slide-out ease-out 0.15s forwards;
      }
    }
  }

  .coding-tab-container {
    background-color: $grey50;
    position: relative;
    z-index: 10;

    .coding-tab-list {
      display: flex;
      padding: 0 20px;
      border-bottom: 1px solid $grey200;

      .coding-tab-list-item {
        color: $grey900;
        padding: 20px 24px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 145%; /* 26.1px */
        letter-spacing: -0.18px;
        position: relative;
        cursor: pointer;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0px;
          height: 2px;
          transition: all 0.2 ease-in-out;
        }

        &.selected {
          color: $blue500;

          &::after {
            width: calc(100% - 48px);
            background-color: $blue500;
          }
        }
      }
    }
  }
}

// assessment progress chart
.total-score-container {
  display: flex;
  width: 100%;
  gap: 30px;
  justify-content: center;
  align-items: center;

  .score-box {
    height: 12px;
    border-radius: 50px;
    background: $grey200;
    width: calc(100% - 100px - 30px);
    overflow: hidden;

    .score-bar {
      border-radius: 50px;
      height: 12px;
      transition: width 1s ease-in-out;
      background-color: $blue600;
    }
  }

  .score-percent {
    width: 100px;

    > span {
      color: $blue500;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 145%;
      letter-spacing: -0.32px;

      i {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 145%;
        letter-spacing: -0.16px;
        color: $grey900;
      }
    }
  }
}

// assessment score tag
.result-score-box {
  padding: 4px 10px;
  display: flex;
  width: fit-content;
  gap: 6px;
  align-items: center;
  border-radius: 50px;
  background-color: $blue50;

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 145%; /* 23.2px */
    letter-spacing: -0.16px;
    color: $blue500;
  }
}

// standard score
.score-container {
  display: flex;
  width: 100%;

  .NA-box {
    display: flex;
    width: 90px;
    height: 60px;
    padding: 24px 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background-color: white;
    color: $grey800;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.2px;
    margin-right: 12px;

    &.selected {
      color: white;
      font-weight: bold;
      background-color: $red500;
    }
  }

  .score-box {
    display: flex;
    flex-direction: row;
    color: $grey500;
    font-style: normal;
    height: 60px;
    padding: 24px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;

    .middle {
      display: flex;
      width: 60px;
      height: 60px;
      padding: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      transition: all 0.2s ease-in-out;
      &.high-selected {
        height: 74px;
        color: white;
        font-weight: bold;
        background-color: $blue500;
        box-shadow: 0 5px 8px 0 rgba(130, 130, 130, 0.2);
        border-radius: 12px;
      }
      &.middle-selected {
        height: 74px;
        color: white;
        font-weight: bold;
        background-color: $orange500;
        box-shadow: 0 5px 8px 0 rgba(130, 130, 130, 0.2);
        border-radius: 12px;
      }
      &.low-selected {
        height: 74px;
        color: white;
        font-weight: bold;
        background-color: $red500;
        box-shadow: 0 5px 8px 0 rgba(130, 130, 130, 0.2);
        border-radius: 12px;
      }
    }
  }
}

// print language score
.language-score-box {
  display: flex;
  align-items: center;
  gap: 10px;

  .language-score-title {
    h5 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.16px;
      color: $grey700;
    }
  }

  .language-score-content {
    .score-bg {
      background-color: $grey300;
      border-radius: 50px;
      padding: 4px 8px 4px 4px;
      display: flex;
      align-items: center;
      gap: 4px;

      &.Begginer {
        background-color: $red500;

        .score-number {
          color: $red500;
        }
      }
      &.Intermediate {
        background-color: $orange500;

        .score-number {
          color: $orange500;
        }
      }
      &.Advanced {
        background-color: $blue500;

        .score-number {
          color: $blue500;
        }
      }

      .score-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 145%; /* 17.4px */
        letter-spacing: -0.12px;
        background-color: $white;
        border-radius: 50%;
      }

      .score-grade {
        color: $white;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 145%; /* 17.4px */
        letter-spacing: -0.12px;
      }
    }
  }
}
