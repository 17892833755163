@import '../include.scss';

.admin-video-scoring {
  .admin-video-scoring-title-box {
    height: 104px;
    display: flex;
    align-items: center;

    .admin-video-scoring-title {
      font-weight: 700;
    }
  }

  .admin-video-scoring-contents {
    background-color: $white;
    padding: 40px;

    .admin-video-search-wrap {
      .admin-video-input {
        display: flex;
        align-items: center;
        gap: 40px;
      }
    }
  }
}
