@import '../include.scss';

/* for highlight */
.none {
  display: none;
}
pre {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 150% !important;
  word-break: break-all;
  white-space: pre-wrap;
  &:not(&:last-child) {
    margin-bottom: 16px;
  }
}
code.hljs {
  padding: 24px !important;
}
.hljs {
  background: $grey900 !important;
  border-radius: 8px !important;
}

.time-picker-box {
  width: 100%;
  display: flex;
  gap: 24px;
  .time-label {
    flex: 1;
  }
}

.codeBlock {
  width: 100%;
  margin-top: 16px;
  > p {
    font-size: 18px;
    font-weight: bold;
    line-height: 23.2px;
    color: $grey900;
    margin-bottom: 8px;
    word-break: break-word;
    white-space: pre-wrap;
  }
}
.codeBlock-sub {
  width: 100%;
}

.pages-protected-assessments {
  padding-top: 0px !important;
  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 0;
    gap: 16px;
    width: 100%;
    height: 104px;
    justify-content: space-between;

    > .header-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      height: 36px;
      font-weight: bold;
    }
    > .header-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;
      > .header-right-search {
        box-sizing: border-box;
        min-width: 360px;
        height: 56px;
        background: $white;
      }
      > .header-right-purpose {
        min-width: 122px;
        height: 56px;
        background: $white;
        .toggle {
          color: $grey500;
        }
      }
    }

    > .header-create {
      padding: 0 24px;
      height: 56px;
      border-radius: 36px;

      > svg {
        margin-right: 11px;
      }
    }
  }
  > .content {
    width: 100%;
    background: $white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 32px;
    gap: 32px;
    background: $white;
    border: 1px solid $grey50;
    position: relative;

    > ul.tabs-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px 24px;
      height: 64px;
      border-bottom: 1px solid $grey100;

      > li.tabs-list-item {
        height: 100%;

        > .tabs-list-item-container {
          padding: 0 24px;
          height: 100%;
          cursor: pointer;
          color: $grey500;
          transition: background-color 0.2s;

          > span {
            display: inline-flex;
            height: 100%;
            font-weight: 700;
            color: $grey900;
            align-items: center;
            justify-content: center;
            border-bottom: 2px solid transparent;
          }

          &:hover {
            background-color: $grey100;
          }

          &.selected {
            > span {
              color: $blue500;
              border-bottom: 2px solid $blue500;
            }
          }

          &.mobile {
            display: none;
          }
        }
      }

      li:nth-child(1).selected ~ .indicator {
        transform: translateX(100px * 0);
      }

      li:nth-child(2).selected ~ .indicator {
        transform: translateX(100px * 1);
      }

      li:nth-child(3).selected ~ .indicator {
        transform: translateX(100px * 2);
      }
    }
    > ul.assessments-list {
      display: grid;
      width: 100%;
      padding: 0 24px;
      grid-template-columns: repeat(auto-fill, 306px);
      grid-gap: 24px;
      justify-content: space-around;
      > li.assessments-list-item {
        > .compoments-card-assessment {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding: 24px;
          gap: 17px;
          width: 306px;
          height: 320px;
          background: $white;
          border: 1px solid $grey300;
          border-radius: 16px;
          cursor: pointer;
          &:hover {
            border: 1px solid $blue500;
          }
          > .title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            width: 100%;
            height: 136px;
            > .header {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
              padding: 0px;
              gap: 24px;
              width: 100%;
              height: 24px;
              > .purpose {
                flex-direction: row;
                align-items: center;
                padding: 4px 6px;
                min-width: 67px;
                height: 24px;
                border-radius: 16px;
                font-style: normal;
                font-weight: 700;
                text-align: center;
                color: $white;

                &.employee-assessment {
                  background: $red500;
                }
                &.recruitment-assessment {
                  background: $blue500;
                }
              }
              > .more {
                .more-icon {
                  width: 24px;
                  height: 24px;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
              }
            }
            > .name {
              font-style: normal;
              font-weight: 700;
              color: $grey900;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              height: 78px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          > .candidates {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 4px;
            width: 258px;
            height: 50px;
            > .status {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0px;
              width: 100%;
              > span {
                flex: 1 1;
                width: 100%;
                font-style: normal;
                font-weight: 700;
                align-items: center;
                text-align: center;
                color: $grey500;
              }
            }
            > .cnt {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0px;
              gap: 4px;
              width: 100%;
              height: 26px;
              > span {
                flex: 1 1;
                width: 100%;
                font-style: normal;
                font-weight: 700;
                align-items: center;
                text-align: center;
                color: $grey900;
              }
            }
          }
          > .skills {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            width: 100%;
            height: 32px;
            > .skill {
              padding: 6px 12px;
              max-width: 100%;
              min-width: 50px;
              height: 32px;
              background: $grey100;
              border-radius: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-style: normal;
              font-weight: 500;
              text-align: center;
              color: $grey900;
            }
          }
          > .current-status {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 16px;
            width: 258px;
            height: 40px;
            justify-content: space-between;
            > button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 8px 12px;
              gap: 8px;
              height: 40px;
              border-radius: 4px;
              font-weight: 500;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: -0.01em;
              color: $white;
              &.inprogress {
                background: $blue500;
              }
              &.closed {
                background: $grey700;
              }
              &.preparing {
                background: $orange500;
              }
            }
            > .date {
              height: 20px;
              font-style: normal;
              font-weight: 500;
              align-items: center;
              text-align: right;
              color: $grey700;
            }
          }
        }
        &.create {
          background: $grey50;
          border-radius: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 24px;
          gap: 17px;
          width: 100%;
          min-height: 320px;
          cursor: pointer;
          > .inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px;
            gap: 8px;
            width: 258px;
            height: 71px;
            > button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 8px;
              width: 40px;
              height: 40px;
              background: $white;
              border: 1px solid $grey100;
              border-radius: 36px;
              > svg > path {
                fill: $grey900;
              }
            }
            > span {
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              text-align: center;
              color: $grey900;
            }
          }
        }
      }

      &.no {
        display: block;
        > li.no-assessment-list-item {
          width: 100%;
          text-align: center;
          padding: 115px 0;

          > .image-box {
            margin-bottom: 8px;
          }

          > h4 {
            font-weight: 700;
          }
        }
      }
    }
    .more-dropdown {
      position: absolute;
      top: calc(var(--dropdown-top) + 50px);
      left: calc(var(--dropdown-left) + 125px);

      > ul.more-dropdown-list {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
          0px 1px 2px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        > li.more-dropdown-list-item {
          cursor: pointer;
          width: 140px;
          height: 48px;
          display: inline-flex;
          align-items: center;
          background-color: white;
          padding: 0 16px;

          &:hover {
            background-color: $grey100;
          }

          &.inactive {
            cursor: default;
            background-color: $white;
            color: $grey300;
          }
        }
      }
    }
  }

  > .selector {
    margin-bottom: 40px;

    > ul.selector-list {
      display: inline-flex;
      border: 1px solid $grey500;

      > li.selector-list-item {
        cursor: pointer;
        width: 76px;
        height: 56px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: rgba($grey500, 0.5);
        }

        &.selected {
          background-color: black;
          color: white;
        }
      }
    }
  }

  .MuiBox-root {
    .MuiPaper-root {
      .MuiTableContainer-root {
        .detail {
          display: none;
        }

        .more-icon {
          width: 24px;
          height: 24px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .align-right {
          text-align: right;
        }

        .thead {
          &.date-created {
            > .container {
              display: inline-flex;
              align-items: center;
              cursor: pointer;
              color: $grey900;

              > .sorting-icon {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 16px;
                height: 16px;
                margin-left: 3.33px;

                &::after {
                  content: '';
                  width: 9.33px;
                  height: 13.33px;
                  mask-image: url('/assets/images/icon/arrow-bottom.svg');
                  mask-repeat: no-repeat;
                  mask-position: center;
                  mask-size: contain;
                  background-color: $grey900;
                  transition: transform 0.2s;
                }

                &.asc::after {
                  content: '';
                  transform: rotate(180deg);
                }
              }

              &:hover {
                color: $grey500;

                > .sorting-icon {
                  &::after {
                    background-color: $grey500;
                  }
                }
              }

              &:active {
                color: $grey900;

                > .sorting-icon {
                  &::after {
                    background-color: $grey900;
                  }
                }
              }
            }
          }
        }

        .tbody {
          &.progress {
            > .container {
              display: flex;
              border-radius: 4px;
              overflow: hidden;

              > .box {
                flex: 1;
                height: 40px;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                &.cnt-0 {
                  display: none;
                }

                &.ready {
                  background-color: $grey100;
                }

                &.start {
                  background-color: $grey300;
                }

                &.in-progress {
                  background-color: $indigo500;

                  > span {
                    color: white;
                  }
                }

                &.complete {
                  background-color: $green500;

                  > span {
                    color: white;
                  }
                }

                &.cancel {
                  background-color: $red500;

                  > span {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    .pending-row {
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .empty-table {
      margin: 40px 0;
      display: flex;
      justify-content: center;
      width: 100%;
      min-height: 100%;
    }
  }

  .table {
    position: relative;

    .more-dropdown {
      position: absolute;
      right: 40px;
      top: calc(var(--dropdown-top) + 36px);

      > ul.more-dropdown-list {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
          0px 1px 2px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        > li.more-dropdown-list-item {
          cursor: pointer;
          width: 140px;
          height: 48px;
          display: inline-flex;
          align-items: center;
          background-color: white;
          padding: 0 16px;

          &:hover {
            background-color: $grey100;
          }

          &.inactive {
            cursor: default;
            background-color: $white;
            color: $grey300;
          }
        }
      }
    }
  }
}

.pages-protected-assessments-active,
.pages-protected-assessments-archived {
  > table {
    width: 100%;
    background-color: white;
    border-radius: 16px;

    th,
    td {
      padding: 12px 16px;
      border-bottom: 1px solid $grey100;

      &.name {
        width: 25%;
      }

      &.num-of-candidates {
        width: 10%;
      }

      &.created-at {
        width: 10%;
      }

      &.more {
        width: 10%;
      }
    }

    th {
      color: $grey900;
    }

    > thead {
      > tr {
        > th {
          > .container {
            text-align: start;

            &.more {
              text-align: end;
            }
          }
        }
      }
    }

    > tbody {
      > tr {
        cursor: pointer;

        &:hover {
          background-color: $grey100;
        }
        > td {
          vertical-align: middle;

          > .container {
            &.name {
            }

            &.num-of-candidates {
            }

            &.status {
              display: inline-flex;
              width: 100%;
              height: 40px;

              > div {
                width: 20%;
                height: 100%;
              }

              > .ready {
                background-color: $app_background_color;
              }

              > .start {
                background-color: $blue500;
              }

              > .in-progress {
                background-color: $green500;
              }

              > .done {
                background-color: $red500;
              }

              > .cancel {
                background-color: $grey400;
              }
            }

            &.created-at {
            }

            &.more {
              text-align: end;

              > .more-button {
                cursor: pointer;
                width: 24px;
                height: 24px;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                &::after {
                  content: url('/assets/images/icon/more.svg');
                }
              }
            }
          }
        }
      }
    }

    > tfoot {
      > tr {
        > td {
          > .container {
            display: flex;
            justify-content: flex-end;
            height: 32px;
          }
        }
      }
    }
  }

  > .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}

.pages-protected-assessments-create {
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-top: 24px !important;

  .advanced-option {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    > .title {
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      color: $grey900;
    }
    > .sub {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;
      width: 100%;
      .sub-title {
        > h6 {
          font-weight: 500;
          color: $grey900;
        }
      }
      .select {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 16px;
        width: 100%;
        height: 68px;
        background: $white;
        border: 1px solid $grey100;
        border-radius: 4px;
        cursor: pointer;
        > .radio-button {
          color: $grey900;
        }
        > p {
          width: 516px;
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          color: $grey900;
        }

        &:hover {
          background: $grey50;
          border: 1px solid $grey100;
        }
        &.selected {
          background: $blue500;
          > .radio-button {
            color: $white;
          }
          > p {
            color: $white;
          }
        }
      }
      .intro-input {
        width: 100%;
        display: flex;
        gap: 24px;
        flex: 1 0 0;

        .input-box {
          width: 50%;
          height: 218px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .input-video-label {
            display: block;
            width: 100%;
            height: 218px;
            margin: 0 auto;
            cursor: pointer;
            background-color: $grey100;
            border-radius: 4px;

            &.disable {
              cursor: default;
              background-color: $grey50;
              color: $grey500;
              svg > path {
                fill: $grey500;
              }
            }

            &:hover {
              background-color: $grey50;
            }

            .label-inner {
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              > svg {
                margin-bottom: 16px;
              }

              > p {
                text-align: center;
                white-space: pre-wrap;
              }

              > span {
                margin-top: 20px;
              }
            }
          }
          .input-video {
            appearance: none;
            background-color: initial;
            cursor: default;
            align-items: baseline;
            color: inherit;
            text-overflow: ellipsis;
            white-space: pre;
            text-align: start !important;
            padding: initial;
            border: initial;
            overflow: hidden !important;
          }
          .delete-box {
            display: flex;
            width: 100%;
            padding: 8px 12px;
            align-items: center;
            justify-content: center;
            border: 1px solid $grey300;
            background-color: $white;
            border-radius: 4px;
            transition: all 0.2s;
            cursor: pointer;

            &:hover {
              background-color: $grey100;
            }

            &.disable {
              cursor: default;
              svg > path {
                fill: $grey300;
              }
              .delete-btn {
                font-weight: 500;
                color: $grey300;
                margin-left: 8px;
              }
              &:hover {
                background-color: $white;
              }
            }

            .delete-btn {
              font-weight: 500;
              color: $grey900;
              margin-left: 8px;
            }
          }
        }

        .video-preview-box {
          width: 50%;
          max-height: 218px;
          min-height: 218px;

          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;

          .background-grey100 {
            background-color: $grey100;
          }

          video {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  #fixed-header {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    padding: 12px 40px;
    border: 1px solid $grey100;
    height: 94px;
    z-index: 10;
    transition: all 0.2s;

    &.fixed {
      opacity: 1;
      visibility: visible;
    }
  }

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;

    > .header-left {
      > .header-left-title {
        > .header-left-title-main {
          font-weight: bold;
          margin-bottom: 8px;
        }

        > .header-left-title-sub {
          > span {
            &:nth-child(1) {
              margin-right: 19px;

              &::before {
                content: url('/assets/images/icon/thunder.svg');
                margin-right: 11px;
                position: relative;
                top: 3px;
              }
            }

            &:nth-child(2) {
              &::before {
                content: url('/assets/images/icon/timer.svg');
                margin-right: 11px;
                position: relative;
                top: 3px;
              }
            }
          }
        }
      }
    }

    > .header-right {
      display: inline-flex;
      align-items: center;
      gap: 16px;

      > button.header-right-go-back {
        width: 133px;
        height: 56px;
        border-radius: 36px;

        &::before {
          content: url('/assets/images/icon/arrow-left.svg');
          margin-right: 11px;
          position: relative;
          top: 2px;
        }
      }

      > button.header-right-go-next {
        width: 133px;
        height: 56px;
        border-radius: 36px;

        &::after {
          content: url('/assets/images/icon/arrow-right.svg');
          margin-left: 11px;
          position: relative;
          top: 2px;
        }
      }

      > button.header-right-done {
        width: 133px;
        height: 56px;
        border-radius: 36px;

        &::after {
          content: url('/assets/images/icon/arrow-right.svg');
          margin-left: 11px;
          position: relative;
          top: 2px;
        }
      }
    }
  }

  > .new-steps {
    display: flex;
    background-color: white;
    border-radius: 0.36px;
    padding: 24px;
    gap: 16px;

    > .left {
      font-weight: bold;
      color: $blue500;
    }

    > .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      top: 4px;

      > .title {
        font-weight: bold;
        margin-bottom: 12px;
      }

      > .progress-bar {
        width: 100%;
        height: 8px;
        border-radius: 4px;
        background-color: $grey100;

        > .progress-bar-fill {
          height: 8px;
          background-color: $blue500;
          border-radius: 4px;
          transition: width 1s;
        }
      }
    }
  }

  > .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    > .steps-step {
      cursor: pointer;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: auto;
      height: 138px;
      gap: 16px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(50% - 100px);
        width: 200px;
        height: 100%;
        border-radius: 8px;
      }

      &:not(:first-child):after {
        content: '';
        position: absolute;
        top: 53px;
        right: calc(50% + 40px);
        width: calc(100% - 80px);
        height: 1px;
        background-color: $grey300;
      }

      > .steps-step-number {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border: 1px solid $grey300;
        border-radius: 50%;
        // font-size: 16px;
        font-weight: bold;

        &::after {
          content: '';
          width: 100%;
          height: 100%;
          transform: scale(0.9);
          border-radius: 50%;
          background-color: $grey300;
          position: absolute;
          z-index: -1;
        }
      }

      &:hover {
        &::before {
          background-color: $grey100;
          z-index: -10;
        }
      }

      &.current {
        > .steps-step-number {
          &::after {
            animation: riffle-effect-amination 1s cubic-bezier(0, 0, 0.2, 1)
              infinite;
          }
        }
      }

      &.current,
      &.complete {
        &::after {
          background-color: $blue500;
        }

        > .steps-step-number {
          border: 1px solid $blue500;
          color: white;

          &::after {
            background-color: $blue500;
          }
        }

        > .steps-step-title {
          font-weight: bold;
          color: $blue500;
        }
      }

      @keyframes riffle-effect-amination {
        0% {
          transform: scale(0.3);
        }

        100% {
          transform: scale(1);
        }
      }
    }
  }
}

.pages-protected-assessments-create-step-1 {
  width: 100%;
  display: flex;
  justify-content: center;

  > .form {
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    > .form-input {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;

      > .helper {
        font-style: normal;
        font-weight: 500;
        color: $grey500;
      }

      > input {
        width: 100%;
      }
    }

    > .form-job-role {
      > p {
        margin-bottom: 16px;
      }

      > .form-select {
        position: relative;

        > .form-select-toggle {
          cursor: pointer;
          width: 100%;
          height: 56px;
          border-radius: 4px;
          border: 1px solid $grey300;
          display: inline-flex;
          align-items: center;
          padding: 0 16px;
          position: relative;

          &::after {
            content: url('/assets/images/icon/chevron-bottom-dropdown.svg');
            position: absolute;
            top: 16px;
            right: 16px;
          }
        }

        > .form-select-dropdown {
          position: absolute;
          top: 61px;
          width: 100%;

          > ul.form-select-dropdown-list {
            width: 100%;
            background-color: white;
            box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
              0px 1px 2px rgba(0, 0, 0, 0.3);
            border-radius: 4px;

            > li.form-select-dropdown-list-item {
              cursor: pointer;
              width: 100%;
              height: 48px;
              display: inline-flex;
              align-items: center;
              padding: 0 16px;

              &:hover {
                background-color: $grey100;
              }
            }
          }
        }
      }
    }

    > .form-recommend {
      margin-top: 16px;

      > p {
        margin-bottom: 16px;
      }

      > ul.form-recommend-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        > li.form-recommend-list-item {
          padding: 6px 12px;
          background-color: rgba($blue500, 0.15);
          border-radius: 8px;
        }
      }
    }

    > .form-language {
      .components-select-auth-select-box > .toggle {
        color: $grey900;
      }

      > p {
        margin-bottom: 16px;
      }

      > .form-language-select {
      }
    }
    > .form-purpose {
      .components-select-auth-select-box > .toggle {
        color: $grey900;
      }

      > p {
        margin-bottom: 16px;
      }

      > .form-purpose-select {
      }
    }
    > .form-assessment {
      > p {
        margin-bottom: 16px;
      }
      > .selector {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        width: 100%;
        height: 103px;
        > .box {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px;
          gap: 8px;
          flex: 1;
          height: 103px;
          background: $white;
          border: 1px solid $grey300;
          border-radius: 16px;
          cursor: pointer;
          > .content {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 8px;
            height: 55px;

            > .text {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px;
              gap: 4px;
              width: calc(100% - 40px);
              > .title {
                width: 100%;
                height: 28px;
                font-weight: 700;
                color: $grey700;
              }
              > .comment {
                width: 100%;
                font-weight: 500;
                color: $grey700;
              }
            }
            > .icon {
              width: 40px;
              height: 40px;
              &.left {
                &::after {
                  content: url('/assets/images/icon/ico-direct-select-2.svg');
                  position: absolute;
                }
              }
              &.right {
                &::after {
                  content: url('/assets/images/icon/ico-package-select.svg');
                  position: absolute;
                }
              }
            }
          }
          &.selected {
            border: 2px solid $blue500;
            pointer-events: none;
            > .content > .text > .title,
            > .content > .text > .title,
            > .content > .text > .comment {
              color: $grey900;
            }
          }
          &.disabled {
            background: $white;
            border: 1px solid $grey400;
            pointer-events: none;
            > .content > .text > .title,
            > .content > .text > .title,
            > .content > .text > .comment {
              color: $grey400;
            }
            > .content > .icon {
              &.left {
                &::after {
                  content: url('/assets/images/icon/ico-direct-select-disabled.svg');
                  position: absolute;
                }
              }
              &.right {
                &::after {
                  content: url('/assets/images/icon/ico-package-select-disabled.svg');
                  position: absolute;
                }
              }
            }
          }
          &:hover {
            background: $grey50;
          }
        }
      }
      > .selector-info {
        margin-top: 8px;
        width: 800px;
        height: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: $pink500;
      }
    }
  }
}

.pages-protected-assessments-create-step-2 {
  > .result {
    position: relative;
    margin-bottom: 40px;
    --dropdown-top: 0px;

    > p {
      font-weight: bold;
      margin-bottom: 24px;
    }

    .form-question-select {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 73px;
      height: 40px;
      background: $white;
      border: 1px solid $grey300;
      border-radius: 4px;
    }

    .duration {
      > .duration-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 2px;

        &::before {
          content: '';
          width: 18px;
          height: 21px;
          mask-image: url('/assets/images/icon/timer.svg');
          margin-right: 7px;
          mask-repeat: no-repeat;
          background-color: $grey900;
          mask-position: center;
        }
      }

      > span {
        position: relative;
        bottom: 2px;
      }
    }

    .more {
      width: 24px;
      height: 24px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &::after {
        content: '';
        width: 4px;
        height: 16px;
        mask-image: url('/assets/images/icon/more.svg');
        background-color: black;
        mask-repeat: no-repeat;
      }
    }

    > .result-dropdown {
      position: absolute;
      right: 40px;
      top: calc(var(--dropdown-top) + 36px);

      > ul.result-dropdown-list {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
          0px 1px 2px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        > li.result-dropdown-list-item {
          cursor: pointer;
          width: 140px;
          height: 48px;
          display: inline-flex;
          align-items: center;
          background-color: white;
          padding: 0 16px;

          &:hover {
            background-color: $grey100;
          }
        }
      }
    }

    .empty-test {
      > .container {
        display: flex;
        align-items: center;
        font-weight: bold;

        &::before {
          content: url('/assets/images/icon/info.svg');
          margin-right: 10px;
          position: relative;
          top: 2px;
        }
      }
    }
  }

  > .position {
    cursor: pointer;
    background-color: white;
    background-image: url('https://user-images.githubusercontent.com/24651852/175465030-566abdfa-90ab-4749-9fd2-52c73d6fb22a.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 300px;
    width: 100%;
    padding: 24px 300px 24px 24px;
    border-radius: 8px;
    margin-bottom: 40px;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.05),
      0px 1px 2px rgba(0, 0, 0, 0.15);
    position: relative;

    &:hover {
      transform: scale(1.005);
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05),
        0px 2px 4px rgba(0, 0, 0, 0.15);
    }

    &:active {
      transform: scale(0.997);
      box-shadow: 0px -1px -3px -1px rgba(0, 0, 0, 0.05),
        0px -1px -2px rgba(0, 0, 0, 0.15);
    }

    > .position-title {
      font-weight: bold;
      margin-bottom: 8px;
    }
  }

  > .guide {
    margin-bottom: 40px;

    > p {
      margin-bottom: 16px;
    }

    > ul.guide-select-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;

      > li.guide-select-list-item {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $grey500;
        height: 78px;
        padding: 9px 30px 9px 18px;
        // word-break: break-word;
        word-break: keep-all;
        word-wrap: break-word;
        border-radius: 16px;
        flex: 1;
        border: 1px dashed $grey500;
        position: relative;
        font-weight: bold;

        > svg {
          position: absolute;
          right: 18px;

          path {
            fill: $blue500;
          }
        }

        &.selected {
          border: 1px solid $blue500;
          // background-color: $blue500;
          color: black;

          > svg {
            cursor: pointer;
          }
        }
      }
    }
    .empty-test {
      > .container {
        display: flex;
        align-items: center;
        font-weight: bold;

        &::before {
          content: url('/assets/images/icon/info.svg');
          margin-right: 10px;
          position: relative;
          top: 2px;
        }
      }
    }
    .duration {
      > .duration-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 2px;

        &::before {
          content: '';
          width: 18px;
          height: 21px;
          mask-image: url('/assets/images/icon/timer.svg');
          margin-right: 7px;
          mask-repeat: no-repeat;
          background-color: $grey900;
          mask-position: center;
        }
      }

      > span {
        position: relative;
        bottom: 2px;
      }
    }
    .types {
      display: flex;
      flex-direction: row;
      gap: 4px;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 8px 16px;
      gap: 4px;
      height: 58px;
      > button {
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 8px;
        background-position: center;
        background-repeat: no-repeat;

        &.disabled {
          > svg > path {
            fill: $grey300;
          }
        }
        :hover {
          background: $grey100;
        }
      }
    }
    .tablecell {
      padding: 0 16px !important;
    }
  }

  > .header {
    > .form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      > .form-header-search {
        > .components-input-auth-input {
          width: 360px;
        }
      }

      > .form-header-type {
        > .components-select-auth-select-box {
          width: 360px;
        }
      }
    }
  }

  > .form {
    background: $white;
    border: 1px solid $grey100;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 32px;
    gap: 32px;
    width: 100%;
    > .form-tabs {
      width: 100%;

      > ul.form-tabs-list {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-left: 24px;
        width: 100%;
        height: 64px;
        border-bottom: 1px solid $grey100;

        > li.form-tabs-list-item {
          height: 100%;
          > .container {
            cursor: pointer;
            padding: 0 24px;
            height: 100%;
            transition: background-color 0.2s;

            > span {
              display: flex;
              align-items: center;
              height: 100%;
              border-bottom: 2px solid transparent;
              font-weight: 700;
            }

            &:hover {
              background-color: $grey100;
            }
          }
          &.disabled {
            pointer-events: none;
          }

          &.selected {
            > .container {
              > span {
                color: $blue500;
                border-bottom: 2px solid $blue500;
              }
            }
          }
        }
      }
    }

    > .form-tests {
      width: 100%;
      padding: 0 24px;
      ul.form-tests-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, 306px);
        grid-gap: 24px;
        justify-content: space-around;

        > li.form-tests-list-item {
        }
      }

      .container {
        display: inline-flex;
        align-items: center;

        &::before {
          content: url('/assets/images/icon/clock.svg');
          margin-right: 7px;
        }
      }

      .table-title {
        &.suggested {
          &::after {
            content: 'suggested';
            margin-left: 4px;
            padding: 1px 6px;
            border-radius: 16px;
            background-color: $pink600;
            color: white;
            font-weight: bold;
            // font-size: 12px;
          }
        }
      }

      button {
        // width: 86px;
        min-width: 78px;
        height: 40px;
        &.add {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          gap: 8px;
          height: 40px;
          background: $white;
          border: 1px solid $blue500;
          border-radius: 4px;
          font-style: normal;
          font-weight: 500;
          text-align: center;
          color: $blue500;
        }

        &.selected {
          background: $blue500;
          color: $white;
        }
      }

      .empty-table {
        margin: 40px 0;
        display: flex;
        justify-content: center;
        width: 100%;
        min-height: 100%;
      }
    }
  }
}

.pages-protected-assessments-create-step-3 {
  > .result {
    position: relative;
    margin-bottom: 40px;
    --dropdown-top: 0px;

    > p {
      font-weight: bold;
      margin-bottom: 24px;
    }

    td {
      word-break: keep-all;
      word-wrap: break-word;
      &.title {
        > * {
          all: revert;
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }

    .duration {
      > .duration-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 2px;

        &::before {
          content: '';
          width: 18px;
          height: 21px;
          mask-image: url('/assets/images/icon/timer.svg');
          margin-right: 7px;
          mask-repeat: no-repeat;
          background-color: $grey900;
          mask-position: center;
        }
      }

      > span {
        position: relative;
        bottom: 2px;
      }
    }

    .more {
      width: 24px;
      height: 24px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &::after {
        content: '';
        width: 4px;
        height: 16px;
        mask-image: url('/assets/images/icon/more.svg');
        background-color: black;
        mask-repeat: no-repeat;
      }
    }

    > .result-dropdown {
      position: absolute;
      right: 40px;
      top: calc(var(--dropdown-top) + 36px);

      > ul.result-dropdown-list {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
          0px 1px 2px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        > li.result-dropdown-list-item {
          cursor: pointer;
          width: 140px;
          height: 48px;
          display: inline-flex;
          align-items: center;
          background-color: white;
          padding: 0 16px;

          &:hover {
            background-color: $grey100;
          }
        }
      }
    }

    .empty-test {
      > .container {
        display: flex;
        align-items: center;
        font-weight: bold;

        &::before {
          content: url('/assets/images/icon/info.svg');
          margin-right: 10px;
          position: relative;
          top: 2px;
        }
      }
    }
  }

  > .form {
    > .form-custom {
      margin-bottom: 40px;

      > p {
        font-weight: bold;
        margin-bottom: 24px;
      }

      > .form-custom-list {
        display: flex;
        gap: 24px;

        > .form-custom-list-item {
          flex: 1;
          height: 72px;
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid $blue500;
          border-radius: 6px;
          padding: 0 20px;
          cursor: pointer;
          text-align: center;
          gap: 8px;
          background: $white;

          > .form-custom-list-item-icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }

          > span {
            // font-size: 18px;
            font-weight: bold;
            color: $blue500;
          }
          &.tag-multiple-choice {
            border: 1px solid $purple500;
            > span {
              color: $purple500;
            }
          }
          &.tag-essay {
            border: 1px solid $orange700;
            > span {
              color: $orange700;
            }
          }
          &.tag-subjective {
            border: 1px solid $indigo500;
            > span {
              color: $indigo500;
            }
          }
          &.tag-video {
            border: 1px solid $pink500;
            > span {
              color: $pink500;
            }
          }
          &.tag-file-upload {
            border: 1px solid $orange500;
            > span {
              color: $orange500;
            }
          }
          &.tag-spreadseat {
            border: 1px solid $green700;
            > span {
              color: $green700;
            }
          }
          &.tag-presentation {
            border: 1px solid $red500;
            > span {
              color: $red500;
            }
          }
          &.tag-code {
            border: 1px solid $indigo900;
            > span {
              color: $indigo900;
            }
          }

          &:hover {
            background-color: rgba($blue500, 0.07);
          }

          &:active {
            background-color: rgba($blue500, 0.11);
          }
        }
      }
    }

    > .form-library {
      > p {
        margin-bottom: 24px;
        // font-size: 20px;
        font-weight: bold;
      }

      pre {
        // word-break: break-all;
        word-break: keep-all;
        word-wrap: break-word;
        white-space: pre-wrap;
      }

      td {
        word-break: keep-all;
        word-wrap: break-word;
        &.title {
          > * {
            all: revert;
          }
        }
      }

      .duration {
        > .duration-icon {
          position: relative;
          top: 2px;
          display: inline-flex;
          width: 24px;
          height: 24px;
          justify-content: center;
          align-items: center;
          margin-right: 4px;

          &::after {
            content: '';
            width: 18px;
            height: 21px;
            mask-image: url('/assets/images/icon/timer.svg');
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: $grey900;
          }
        }

        > span {
          position: relative;
          bottom: 2px;
        }
      }

      .more {
        > .container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > button.add {
            width: 86px;
            height: 39px;
            border-radius: 100px;
            color: white;

            &::after {
              content: '';
              width: 12px;
              height: 12px;
              mask-image: url('/assets/images/icon/add-assessments-table.svg');
              mask-repeat: no-repeat;
              mask-position: center;
              background-color: white;
              margin-left: 11px;
              position: relative;
              top: 1px;
              color: black;
            }
          }

          > .delete-icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            cursor: pointer;

            &::after {
              content: '';
              width: 16px;
              height: 18px;
              mask-image: url('/assets/images/icon/delete.svg');
              mask-repeat: no-repeat;
              mask-position: center;
              background-color: $grey900;
            }
          }
        }
      }
    }
  }
}

.pages-protected-assessments-create-step-4 {
  .alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 8px;
    width: 600px;
    // height: 40px;
    background: $red50;
    border-radius: 8px;
    > .desc {
      width: 552px;
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: $black;
    }

    &.code-alert {
      width: 100%;

      > .desc {
        width: 100%;
        line-height: 1.3;
        word-break: keep-all;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }

  > .result {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    > .result-title {
      width: 100%;
      > .result-title-main {
        // font-size: 20px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      > .result-title-sub {
        margin-bottom: 24px;
      }
    }

    > .result-box {
      width: 100%;
      display: flex;
      gap: 24px;

      > .result-table-box {
        flex: 1;
        td {
          word-break: keep-all;
          word-wrap: break-word;
          &.title {
            > * {
              all: revert;
              img {
                max-width: 100%;
                height: auto;
              }
            }

            &.empty-test {
              // display: flex;
              // align-items: center;
              > .container {
                display: flex;
                align-items: center;
                font-weight: bold;

                &::before {
                  content: '';
                  margin-right: 10px;
                  position: relative;
                  mask-image: url('/assets/images/icon/info.svg');
                  width: 20px;
                  height: 20px;
                  mask-repeat: no-repeat;
                  mask-position: center;
                  background-color: $grey900;
                }
              }
            }
          }
        }
      }

      > .advanced-option {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        gap: 24px;
        background: $white;
        > .title {
          font-style: normal;
          font-weight: 700;
          display: flex;
          align-items: center;
          color: $grey900;
        }
        > .sub {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 16px;
          width: 600px;
          .sub-title {
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
          }
          .select {
            height: 56px;
            margin-top: 8px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            background: $white;
            border: 1px solid $blue500;
            border-radius: 4px;
            padding: 16px;
            cursor: pointer;
            > .radio-button {
              color: $grey900;
            }
            > p {
              width: 516px;
              font-style: normal;
              font-weight: 500;
              display: flex;
              align-items: center;
              color: $grey900;
            }
            &.selected {
              > .radio-button {
                color: $blue500;
              }
            }
          }
        }
      }

      > .result-dropdown {
        z-index: 10;
        position: absolute;
        right: calc(var(--dropdown-right) - 130px);
        top: calc(var(--dropdown-top) + 36px);
        width: unset;

        > ul.result-dropdown-list {
          display: flex;
          flex-direction: column;
          box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
            0px 1px 2px rgba(0, 0, 0, 0.3);
          border-radius: 4px;

          > li.result-dropdown-list-item {
            cursor: pointer;
            width: 140px;
            height: 48px;
            display: inline-flex;
            align-items: center;
            background-color: white;
            padding: 0 16px;

            &:hover {
              background-color: $grey100;
            }
          }
        }
      }
    }

    .duration {
      // display: flex;
      // align-items: center;

      > svg {
        margin-right: 7px;
        position: relative;
        top: 2px;
      }

      > span {
        position: relative;
        bottom: 4px;
      }
    }

    > .result-dropdown {
      position: absolute;
      right: 40px;
      top: calc(var(--dropdown-top) + 36px);
      width: unset;

      > ul.result-dropdown-list {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
          0px 1px 2px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        > li.result-dropdown-list-item {
          cursor: pointer;
          width: 140px;
          height: 48px;
          display: inline-flex;
          align-items: center;
          background-color: white;
          padding: 0 16px;

          &:hover {
            background-color: $grey100;
          }
        }
      }
    }

    .more {
      > .more-icon {
        width: 24px;
        height: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
        }

        &:active {
        }
      }
    }
  }
}

.pages-protected-assessments-create-check {
  > .check-area {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: 1260px;
    background: $white;
    border: 1px solid $grey100;
    border-radius: 4px;
    flex: none;
    order: 3;
    flex-grow: 0;
    > .category {
      width: 240px;
      height: 1260px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      border: 1px solid $grey100;
      > .list {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 17px 16px;
        gap: 4px;
        width: 100%;
        min-height: 58px;
        background: $white;
        font-weight: 700;
        color: $grey900;
        &.selected {
          background: $blue500;
          color: $white;
          pointer-events: none;
        }
        &:hover {
          background-color: $blue50;
          > .icon {
            background-color: $blue50;
          }
        }
        > .category-name {
          width: 190px;
          // word-break: break-all;
          word-break: keep-all;
          word-wrap: break-word;
        }
        > .icon {
          content: '';
          width: 14px;
          height: 14px;
          mask-image: url('/assets/images/icon/chevron-right.svg');
          mask-repeat: no-repeat;
          mask-position: center;
          mask-size: contain;
          background-color: $white;
          transition: transform 0.2s;
        }
      }
    }
    > .question {
      background: $grey100;
      width: 460px;
      height: 1260px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      // gap: 24px;
      border: 1px solid $grey100;
      word-break: keep-all;
      word-wrap: break-word;
      > .top-box {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        padding-bottom: 12px;
        gap: 24px;
        width: 460px;
        background: $white;
        border-bottom: 1px solid $grey100;
        > .time {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0px;
          gap: 16px;
          width: 412px;
          height: 40px;
          align-self: stretch;
          > .title {
            width: 231px;
            height: 23px;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $black;
          }
          > .time-box {
            display: flex;
            align-items: center;
            > .input-time {
              margin-right: 8px;
            }
          }
        }
        > .question-count {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 16px;
          width: 412px;
          height: 40px;
          align-self: stretch;
          > .title {
            width: 239px;
            height: 23px;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $black;
          }
          > button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 10px;
            gap: 8px;
            width: 52px;
            height: 40px;
            border-radius: 4px;
            color: $white;
            background-color: $blue200;

            &.active {
              background-color: $blue500;
            }
          }
          > .select-question {
            > .form-question-select {
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              align-items: center;
              // padding: 8px 4px 8px 12px;
              gap: 24px;
              width: 97px;
              height: 40px;
              background: $white;
              // border: 1px solid $grey300;
              border-radius: 4px;
              // background-image: url('/assets/images/icon/chevron-bottom.svg');
              // background-repeat: no-repeat;
              // background-position-x: 80%;
              // background-position-y: 50%;
              // -webkit-appearance: none;
              // -moz-appearance: none;
              // > option {
              //     background-color: $white;
              // }
            }
          }
        }
        > .question-rebuild {
          display: flex;
          flex-direction: row;
          padding: 0px;
          gap: 4px;
          width: 412px;
          height: 67px;
          align-self: stretch;
          align-items: center;
          > .left {
            width: 223px;
            > .title {
              width: 273px;
              height: 23px;
              font-weight: 700;
              display: flex;
              color: $black;
            }
            > .content {
              width: 273px;
              height: 40px;
              flex-direction: column;
              font-weight: 500;
              display: flex;
              color: $black;
              > div > span {
                color: $blue500;
              }
            }
          }
          > .right {
            width: 100%;
            text-align: -webkit-right;
            > button {
              justify-content: center;
              gap: 8px;
              display: flex;
              // width: 123px;
              height: 40px;
              padding: 8px 12px;
              background-color: #a0cbf3;
              align-items: center;
              border-radius: 4px;
              color: $white;
              margin-left: 12px;
              // float: right;

              &.active {
                background-color: $blue500;
              }
            }
          }
        }
        > .question-select-done {
          display: flex;
          flex-direction: row;
          padding: 0px;
          gap: 4px;
          width: 412px;
          align-self: stretch;
          align-items: center;
          > .left {
            width: 223px;
            > .title {
              width: 100%;
              font-weight: 700;
              display: flex;
              color: $black;
            }
            > .content {
              width: 273px;
              flex-direction: column;
              font-weight: 500;
              display: flex;
              color: $black;
              > div > span {
                color: $blue500;
              }
            }
          }
          > .right {
            width: 100%;
            text-align: -webkit-right;
            > button {
              justify-content: center;
              gap: 8px;
              display: flex;
              // width: 123px;
              padding: 8px 12px;
              background-color: #a0cbf3;
              align-items: center;
              border-radius: 4px;
              color: $white;
              margin-left: 12px;
              // float: right;

              &.active {
                background-color: $blue500;
              }
            }
          }
        }
        > .all-selected {
          display: flex;
          justify-content: flex-end;
          width: 100%;

          .MuiButtonBase-root {
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 4px;
          }

          button {
            font-size: 14px;
            font-weight: 500;
          }
        }
        > .question-selected {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 4px;
          width: 100%;
          height: 47px;
          > .title {
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $black;
          }
          > .discription {
            font-style: normal;
            font-weight: 500;
            display: flex;
            align-items: center;
            color: $black;
          }
        }
        > .question-language-select-box {
          width: 100%;
          > .question-language-select-caution {
            margin-bottom: 10px;

            > span {
              display: inline-block;
              font-weight: 700;
            }
          }
          > .question-language-selected {
            width: 100%;
            font-size: 14px;
          }
        }
      }
      > .question-box {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 18px;
      }
      > .settings-locked {
        margin-top: 24px;
        display: none;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        gap: 8px;
        width: 412px;
        height: 72px;
        background: $white;
        border: 1px solid $grey300;
        border-radius: 16px;
        > .discription {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 10px;
          width: 364px;
          height: 24px;
          font-style: normal;
          font-weight: 500;
          color: $grey900;
        }
      }
    }
    > .answer {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      flex-grow: 1;
      width: 100%;
      height: 1260px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 16px;
      background: $white;
      border: 1px solid $grey100;
      overflow-y: scroll;

      > .skill {
        height: 23px;
        font-weight: 500;
        color: $grey500;
      }

      > .question-num {
        height: 23px;
        font-style: normal;
        font-weight: 700;
        color: $grey900;
      }

      > .question {
        font-weight: 500;
        width: 100%;
        color: $grey900;
        > pre {
          line-height: 1.8 !important;
          // text-indent: 10px;
          word-break: keep-all;
          word-wrap: break-word;

          // 테이블 테그
          .tg {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;

            th {
              border-color: black;
              border-style: solid;
              border-width: 1px;
              font-size: 14px;
              font-weight: normal;
              overflow: hidden;
              padding: 10px 5px;
              word-break: normal;
            }

            td {
              border-color: black;
              border-style: solid;
              border-width: 1px;
              font-size: 14px;
              overflow: hidden;
              padding: 10px 5px;
              word-break: normal;
            }

            .tg-th {
              background-color: #6980c4;
              color: #ffffff;
              font-weight: bold;
              text-align: center;
              vertical-align: top;
            }

            .tg-td {
              text-align: center;
              vertical-align: top;
            }

            .tg-td-last {
              background-color: #ffcc67;
              text-align: center;
              vertical-align: top;
              text-decoration: underline;
              font-weight: bold;
            }
          }

          .rect-border {
            border: 1px solid $grey300;
            padding: 8px 10px;

            .center {
              padding: 20px 0;
            }
          }

          .center {
            text-align: center;
          }
        }
      }

      > ul.answer-list {
        width: 100%;
        > .tooltip {
          display: none;
          flex-direction: row;
          padding: 8px;
          gap: 10px;
          width: 200px;
          background: $grey100;
          border-radius: 4px;
          font-weight: 500;
          align-items: center;
          text-align: center;
          letter-spacing: -0.02em;
          color: $grey900;
          margin-left: -90px;
        }
        &:hover .tooltip {
          display: block;
          position: fixed;
        }
        > li.answer-list-item {
          &:not(&:last-child) {
            margin-bottom: 16px;
          }

          > .container {
            display: flex;
            width: 100%;
            align-items: center;
            box-sizing: border-box;
            padding: 16px;
            gap: 16px;
            background: $white;
            border: 1px solid $grey100;
            border-radius: 4px;
            > p {
              // word-break: break-all;
              word-break: keep-all;
              word-wrap: break-word;
            }
          }
        }
      }
      > .guide {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        margin-top: 16px;
        > .title {
          font-style: normal;
          font-weight: 700;
          color: $grey900;
        }
        > .discription {
          font-style: normal;
          font-weight: 500;
          color: $grey900;
          word-break: keep-all;
          word-wrap: break-word;
        }
        > .tooltip {
          display: none;
          flex-direction: row;
          padding: 8px;
          gap: 10px;
          width: 200px;
          background: $grey100;
          border-radius: 4px;
          font-weight: 500;
          align-items: center;
          text-align: center;
          letter-spacing: -0.02em;
          color: $grey900;
          margin-left: -90px;
        }
        &:hover .tooltip {
          display: block;
          position: fixed;
        }
        > .guide-editor-box {
          width: 100%;
          height: 50vh;
        }
      }
    }
  }
}

.pages-protected-assessments-create-check-test {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 8px;
  width: 412px;
  // height: 164px;
  background: $white;
  border: 2px solid $grey300;
  border-radius: 16px;
  margin-bottom: 24px;

  &.selected {
    border: 2px solid $blue500;
  }
  &:hover {
    background-color: $grey50;
  }

  > .title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 364px;
    height: 62px;

    > .title-left {
      width: 324px;
      > .skill {
        width: 324px;
        height: 23px;
        font-weight: 500;
        color: $grey500;
        margin-bottom: 8px;
      }
      > .qustion-num {
        width: 324px;
        height: 31px;
        font-weight: 700;
        color: $grey900;
      }
    }
    > .title-right {
      width: 32px;
      height: 32px;
    }
  }
  > .question {
    width: 364px;
    max-height: 46px;
    font-weight: 500;
    color: $grey900;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    align-items: center;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:first-child {
    margin-top: 24px;
  }
}

.pages-protected-assessments-detail {
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 40px;
    margin-top: 24px;

    > .header-right {
      display: flex;
      align-items: center;

      > .header-go-back {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-right: 16px;

        &::after {
          content: '';
          width: 12px;
          height: 12px;
          mask-image: url('/assets/images/icon/arrow-left.svg');
          mask-position: center;
          mask-size: contain;
          background-color: black;
        }
      }

      > .header-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;

        > .header-info-title {
          // font-size: 28px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 8px;
          height: 36px;

          > .title {
            font-weight: bold;
          }
          > .stiker {
            padding: 4px 6px;
            height: 24px;
            background: $blue500;
            border-radius: 16px;
            font-style: normal;
            font-weight: 700;
            text-align: center;
            color: $white;
            display: flex;
            gap: 2px;
            align-items: center;
            cursor: pointer;

            &.close {
              background-color: $grey700;
            }

            &.preparing {
              background-color: $orange500;
            }

            > i {
              padding: 6.33px 4.67px;
              path {
                fill: $white;
              }
            }
          }
        }

        > .header-info-description {
          > .header-info-description-count {
            margin-right: 19px;

            &::before {
              content: url('/assets/images/icon/count.svg');
              position: relative;
              top: 2px;
              margin-right: 6px;
            }
          }

          > .header-info-description-duration {
            margin-right: 19px;

            &::before {
              content: url('/assets/images/icon/timer.svg');
              position: relative;
              top: 4px;
              margin-right: 6px;
            }
          }

          > .header-info-description-language {
            margin-right: 19px;

            &::before {
              content: url('/assets/images/icon/flag.svg');
              position: relative;
              top: 4px;
              margin-right: 6px;
            }
          }
          > .header-info-description-timer {
            &::before {
              content: url('/assets/images/icon/calendar.svg');
              position: relative;
              top: 4px;
              margin-right: 6px;
            }
          }
        }
      }
    }

    > .header-left {
      display: flex;
      align-items: center;
      gap: 16px;

      > .header-invite-thumbnail {
        ul {
          display: flex;
          align-items: center;
          gap: 8px;

          li {
            position: relative;

            > .header-invite-thumbnail-round {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: $white;
              border: 1px solid $blue500;
              color: $blue500;
              position: relative;

              > .header-invite-thumbnail-round-initial {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                text-align: center;
                transform: translateY(-50%);
                font-weight: 700;
              }

              &.grey {
                border-color: $grey300;
                color: $grey300;
              }
              &.light-green {
                border-color: $green500;
                color: $green500;
              }
              &.orange {
                border-color: $red500;
                color: $red500;
              }
              &.indigo {
                border-color: $indigo600;
                color: $indigo600;
              }
              &.purple {
                border-color: $purple500;
                color: $purple500;
              }
              &.pink {
                border-color: $pink500;
                color: $pink500;
              }
              &.black {
                border-color: $grey900;
                color: $grey900;
              }
              &.green {
                border-color: $grey700;
                color: $grey700;
              }
              &.blue {
                border-color: $blue500;
                color: $blue500;
              }
            }

            > .header-invite-thumbnail-tooltip {
              position: absolute;
              top: 110%;
              left: 50%;
              transform: translateX(-50%);
              text-align: center;
              padding: 8px;
              border-radius: 4px;
              background-color: $grey100;
              opacity: 0;
              visibility: hidden;
              max-width: 200px;
              transition: all 0.2s;

              > span {
                color: $grey500;
              }
            }

            &:hover {
              > .header-invite-thumbnail-tooltip {
                top: 105%;
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }

      > .header-invite {
        > .header-invite-button {
          padding: 18.5px 24px;
          border-radius: 4px;
        }
      }
    }
    > .header-more {
      position: absolute;
      right: 0px;

      > .header-more-toggle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        height: 56px;
        background: $white;
        border: 1px solid $blue500;
        border-radius: 4px;
        color: $blue500;

        > .more-icon {
          width: 18px;
          height: 18px;
          display: inline-flex;
          justify-content: center;
          // align-items: center;
          position: relative;
          top: 2px;

          &::after {
            content: '';
            width: 3px;
            height: 12px;
            mask-image: url('/assets/images/icon/more.svg');
            mask-position: center;
            mask-size: contain;
            background-color: $blue500;
          }
        }
      }

      > .header-more-dropdown {
        position: absolute;
        top: 66px;
        right: 0;
        z-index: 10;

        > ul.header-more-dropdown-list {
          box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
            0px 1px 2px rgba(0, 0, 0, 0.3);
          border-radius: 4px;

          > li.header-more-dropdown-list-item {
            cursor: pointer;
            width: 140px;
            height: 48px;
            padding: 0 16px;
            display: inline-flex;
            align-items: center;
            background-color: white;

            &:hover {
              background-color: $grey100;
            }
          }
          > li.header-more-dropdown-list-item-inactive {
            cursor: default;
            width: 140px;
            height: 48px;
            padding: 0 16px;
            display: inline-flex;
            align-items: center;
            background-color: $white;
            color: $grey300;
          }
        }
      }
    }
  }

  > .tabs {
    background-color: white;
    padding: 0 24px;
    border-bottom: 1px solid $grey100;

    > ul.tabs-list {
      display: flex;

      > li.tabs-list-item {
        > .tabs-list-item-container {
          cursor: pointer;
          height: 64px;
          padding: 0 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          transition: background-color 0.2s;

          > span {
            font-weight: 700;
          }
          &:after {
            content: '';
            z-index: 2;
            position: absolute;
            bottom: 0;
            width: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 2px;
            background-color: $blue500;
          }

          &:hover {
            background-color: $grey100;
          }
        }

        &.selected {
          > .tabs-list-item-container {
            &:after {
              width: calc(100% - 48px);
            }

            span {
              color: $blue500;
            }
          }
        }
      }
    }
  }
}

.pages-protected-detail-content {
  padding: 40px;
  background-color: white;

  > .candidates {
    margin-bottom: 40px;

    > .candidates-title {
      // font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    > .candidates-table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      > .candidates-table-header-left {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        width: 616px;
        height: 56px;

        > .candidates-table-header-candidates-search {
          width: 360px;
        }

        > .candidates-table-header-hiring-stage {
          width: 240px;
        }
      }
      > .candidates-table-header-right {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 24px;
        gap: 8px;
        height: 56px;
        background: $white;
        border: 1px solid $grey300;
        border-radius: 4px;
        > button {
          height: 19px;
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          text-align: center;
          color: $grey900;
        }
      }
    }

    .candidates-table {
      position: relative;

      .thead {
        white-space: nowrap;
        &.sort {
          cursor: pointer;
          &:hover {
            > .content {
              > .icon {
                visibility: visible;
                width: 16px;
                height: 16px;
                > svg > path {
                  fill: $grey500;
                }
              }
            }
          }
          > .content {
            display: flex;
            align-items: center;
            > .icon {
              visibility: hidden;
              width: 16px;
              height: 16px;
              > svg > path {
                fill: $grey500;
              }
            }
          }
        }
      }

      .tbody {
        &.hiring-stage {
          position: relative;
          > .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 8px;
            gap: 8px;
            height: 32px;
            background: $white;
            border: 1px solid $grey300;
            border-radius: 8px;

            > .status-left {
              display: flex;
              align-items: center;
              gap: 12px;

              > .status-icon {
                width: 10px;
                height: 10px;
                border-radius: 50%;
              }
            }

            > .status-right {
              font-size: 0;
            }
          }

          &.step--1 {
            .status-icon {
              background-color: $grey300;
            }

            > .container {
              border-color: $grey100;
            }
          }

          &.step-1 {
            .status-icon {
              background-color: $green500;
            }
          }

          &.step-2 {
            .status-icon {
              background-color: $orange500;
            }
          }

          &.step-3 {
            .status-icon {
              background-color: $red500;
            }
          }

          &.step-4 {
            .status-icon {
              background-color: $blue500;
            }
          }

          &.step-0 {
            .status-icon {
              background-color: $grey300;
            }

            &.step-leave-true {
              .status-icon {
                background-color: $grey500;
              }
            }
            > .container {
              border-color: $grey100;
            }
          }
        }

        &.more {
          .container {
            display: flex;
            justify-content: flex-end;

            .more-icon {
              cursor: pointer;
              width: 24px;
              height: 24px;
              display: inline-flex;
              justify-content: center;
              align-items: center;

              &::before {
                content: url('/assets/images/icon/more.svg');
              }
            }
          }
        }

        &.objective-score {
          > .container {
            > span {
            }

            > .progress-bar {
              position: relative;
              width: 100%;
              height: 4px;
              background-color: $grey100;
              border-radius: 4px;

              > .fill {
                height: 4px;
                background-color: $blue500;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 4px;
              }
            }
          }
        }

        &.khai-score {
          > .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            gap: 10px;
            > .grade {
              width: 39px;
              height: 36px;
            }
            > .score {
              width: 100%;

              > .progress-bar {
                position: relative;
                width: 100%;
                height: 4px;
                background-color: $grey100;
                border-radius: 4px;

                > .fill {
                  height: 4px;
                  background-color: $blue500;
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-radius: 4px;
                  &.blue600 {
                    background-color: $blue600;
                  }
                  &.blue500 {
                    background-color: $blue500;
                  }
                  &.orange500 {
                    background-color: $orange500;
                  }
                  &.red500 {
                    background-color: $red500;
                  }
                  &.red700 {
                    background-color: $red700;
                  }
                }
              }
            }
          }
          > .khai-o-score {
            font-style: normal;
            display: flex;
            align-items: center;
            &.blue500 {
              color: $blue500;
            }
            &.orange500 {
              color: $orange500;
            }
            &.red500 {
              color: $red500;
            }
          }
        }

        &.language {
          .content {
            max-width: 24px;
            max-height: 36px;

            &.text {
              max-width: none;
            }

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }

        // &.subjective-score {
        //     .container {
        //         display: inline-flex;
        //         position: relative;

        //         > .stars {
        //             display: inline-flex;
        //             position: relative;
        //             z-index: 1;

        //             &.preset-hide {
        //                 >.star {
        //                     &::after {
        //                         display: none;
        //                     }
        //                 }
        //             }

        //             > .star {
        //                 width: 24px;
        //                 height: 24px;
        //                 display: inline-flex;
        //                 justify-content: center;
        //                 align-items: center;
        //                 position: relative;

        //                 &.fill {
        //                     &::before {
        //                         content: url('/assets/images/icon/star-fill.svg');
        //                         font-size: 0;
        //                     }
        //                 }

        //                 &.empty {
        //                     &::before {
        //                         content: url('/assets/images/icon/star.svg');
        //                         font-size: 0;
        //                     }

        //                     &.preset-fill {
        //                         &::after {
        //                             z-index: 3 !important;
        //                         }
        //                     }
        //                 }

        //                 &.preset-fill {
        //                     &::after {
        //                         position: absolute;
        //                         top: 0;
        //                         left: 0;
        //                         z-index: -1;
        //                         content: url('/assets/images/icon/star-preset.svg');
        //                         font-size: 0;
        //                     }
        //                 }

        //                 &.preset-empty {
        //                     &::after {
        //                         position: absolute;
        //                         top: 0;
        //                         left: 0;
        //                         z-index: -1;
        //                         // content: url('/assets/images/icon/star-white-fill.svg');
        //                         font-size: 0;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
      }

      .pending-row {
        .container {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      > .candidates-table-dropdown {
        position: absolute;
        right: 40px;
        top: calc(var(--dropdown-top) + 36px);
        background-color: white;

        > ul.candidates-table-dropdown-list {
          display: flex;
          flex-direction: column;
          box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
            0px 1px 2px rgba(0, 0, 0, 0.3);
          border-radius: 4px;
          padding: 6px 0;

          > li.candidates-table-dropdown-list-item {
            cursor: pointer;
            width: 237px;
            height: 48px;
            display: inline-flex;
            align-items: center;
            padding: 0 16px;

            &:hover {
              background-color: $grey100;
            }
          }
        }
      }
      > .candidates-stage-dropdown {
        position: absolute;
        z-index: 100;
        left: 0px;
        top: var(--dropdown-top);
        background-color: white;

        > ul.candidates-stage-dropdown-list {
          display: flex;
          flex-direction: column;
          box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
            0px 1px 2px rgba(0, 0, 0, 0.3);
          border-radius: 4px;
          padding: 6px 0;

          > li.candidates-stage-dropdown-list-item {
            cursor: pointer;
            width: 100%;
            height: 48px;
            display: inline-flex;
            gap: 12px;
            align-items: center;
            padding: 0 16px;
            position: relative;

            &:hover {
              background-color: $grey100;
            }

            .candidates-stage-dropdown-status-icon {
              width: 10px;
              height: 10px;
              border-radius: 50%;

              &.under-investigation {
                background-color: $orange500;
              }
              &.accept {
                background-color: $blue500;
              }
              &.reject {
                background-color: $red500;
              }
            }
          }
        }
      }
      .empty-candidate {
        > .container {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: $grey900;

          &::before {
            content: url('/assets/images/icon/info.svg');
            margin-right: 10px;
            position: relative;
            top: 2px;
          }
        }
      }
    }
  }

  > .included-tests {
    margin-bottom: 40px;

    > .included-tests-title {
      // font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .included-tests-table {
      .skillsets {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 8px;
        width: 100%;
        height: 34px;
        min-width: 0;
        flex: 1 1 auto;
        > .skillset {
          max-width: 120px;
          height: 32px;
          background: $blue50;
          padding: 0px 8px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          text-align: center;
          > span {
            font-style: normal;
            font-weight: 500;
            color: $grey900;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .types {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    }
  }

  > .included-questions {
    > .included-questions-title {
      // font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    > .included-questions-table {
      td {
        word-break: keep-all;
        word-wrap: break-word;
        &.title {
          > * {
            all: revert;
            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

.pages-protected-detail-styling {
  padding: 32px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  > .candidates {
    margin-bottom: 40px;
    width: 100%;

    > .candidates-title {
      // font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .revert-paper {
      box-shadow: none;
    }
    .style-table {
      // display: flex;
      // gap: 8px;
      // flex-direction: column;
      .styling-row {
        width: 100%;
      }
      .styling-cell {
        padding: 4px;
        border: none;
        > img {
          cursor: pointer;
          width: 100%;
          height: auto;
          border-radius: 8px;
          border: 2px solid transparent;
          &:hover {
            border: 2px solid $grey300;
          }
        }
      }
    }
  }
}

.pages-protected-detail-invite {
  display: flex;
  padding: 40px;
  background-color: white;
  gap: 40px;

  > .email {
    flex: 1;

    > .email-title {
      // font-size: 20px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    > .email-description {
      // font-size: 16px;
      // line-height: 23.2px;
      margin-bottom: 24px;
    }

    > .email-input,
    > .firstname-input,
    > .lastname-input {
      margin-bottom: 24px;
    }

    > button.email-invite {
      padding: 0 24px;
      height: 56px;
      border-radius: 36px;
      margin-bottom: 32px;
    }

    > .email-bulk-description {
      // font-size: 16px;
      // line-height: 23.2px;
      margin-bottom: 16px;
    }

    > button.email-invite-bulk {
      padding: 0 24px;
      height: 56px;
      border-radius: 36px;
    }
  }

  > .link {
    flex: 1;

    > .link-title {
      // font-size: 20px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    > .link-description {
      // font-size: 16px;
      // line-height: 23.2px;
      margin-bottom: 32px;
    }

    > button.link-copy {
      padding: 0 24px;
      height: 56px;
      border-radius: 36px;
    }
  }
}

.pages-protected-assessments-detail-edit {
  background-color: white;

  > .section {
    padding: 40px 0;
    margin: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey100;

    > .title {
      h3 {
        // font-size: 20px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      // p {
      //     font-size: 16px;
      //     line-height: 23.2px;
      // }
    }

    button {
      padding: 0 16px;
      height: 56px;
      border-radius: 36px;

      &::before {
        content: url('/assets/images/icon/pen.svg');
        margin-right: 10px;
        position: relative;
        top: 2px;
      }
    }
  }
}

// tooltip css
.stars {
  + .tooltip {
    display: none;
    flex-direction: row;
    padding: 8px;
    gap: 10px;
    width: 200px;
    background: $grey100;
    border-radius: 4px;
    font-weight: 500;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: $grey900;
    margin-left: -90px;
  }

  &.preset-hide {
    &:hover + .tooltip {
      display: none;
    }
  }

  &:hover + .tooltip {
    display: block;
    position: fixed;
  }
}

.container {
  > span {
    font-weight: 500;
    color: $grey900;

    &.grey600 {
      color: $grey600;
    }
  }

  > .progress-bar {
    position: relative;
    max-width: 160px;
    min-width: 120px;
    height: 4px;
    border-radius: 4px;
    background-color: $grey100;

    > .fill {
      height: 4px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;

      &.background-blue {
        background-color: $grey400 !important;
      }

      &.background-yellow {
        background-color: $grey400 !important;
      }

      &.background-red {
        background-color: $grey400 !important;
      }
    }
    > .preset-fill {
      height: 4px;
      background-color: $grey400;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
    }

    + .tooltip {
      display: none;
      flex-direction: row;
      padding: 8px;
      gap: 10px;
      width: 200px;
      background: $grey100;
      border-radius: 4px;
      font-weight: 500;
      align-items: center;
      text-align: center;
      letter-spacing: -0.02em;
      color: $grey900;
      margin-left: -90px;
    }

    &.preset-hide {
      &:hover + .tooltip {
        display: none;
      }
    }

    &:hover + .tooltip {
      display: block;
      position: fixed;
    }
  }
}

// renewal assessment result
.results-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 !important;
  position: relative;

  // gucci survey
  .gucci {
    padding: 40px !important;
    background-color: $grey50 !important;
    border-radius: 8px;
    margin-bottom: 0 !important;
  }

  // common
  .results-main-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 28px */
    letter-spacing: -0.28px;
    color: $grey900;
  }
  .tests-no-lang-result {
    width: 220px;
    margin-top: 20px;
    border-radius: 5px;
    padding: 6px 12px;
    background: $blue50;
    color: $grey900;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.14px;
  }

  .results-semi-main-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.24px;
    color: $grey900;
  }

  .results-sub-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    letter-spacing: -0.2px;
    color: $grey700;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .results-semi-sub-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 26.1px */
    letter-spacing: -0.18px;
    color: $grey700;
  }

  .results-desc {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 20.3px */
    letter-spacing: -0.14px;
    color: $grey800;
  }

  .results-desc-v2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 23.2px */
    letter-spacing: -0.16px;
    color: $grey900;
    word-break: break-all;
  }

  // contents
  article.button-container {
    position: sticky;
    top: 106px;
    left: 0;
    z-index: 10;

    .tab-container {
      display: flex;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid $grey200;
      background-color: $grey100;
      justify-content: center;
      align-items: stretch;
      cursor: pointer;
      padding: 10px;
      height: auto;
      height: 80px;
      width: 100%;

      & > * {
        &:last-child {
          &.active {
            background-color: $red500;
            color: $white;
          }

          &:hover {
            background-color: $red500;
            color: white;
          }
        }
      }

      > .active {
        font-weight: 700;
        background-color: #3193ee;
        color: white;
      }

      > div {
        flex: 1;
        white-space: pre-wrap;
        display: flex;
        padding: 8px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $grey700;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 8px;
        transition: background 0.2s ease-in-out;

        &:hover {
          background-color: #3193ee;
          color: white;
        }
      }
    }
  }

  article.summary-container {
    padding: 40px;
    background-color: $grey50;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;

    > aside.summary-contents {
      display: flex;
      gap: 70px;
      align-items: stretch;
      flex-wrap: wrap;

      > aside {
        display: flex;
        flex-direction: column;
        gap: 56px;
      }

      > aside.summary-average {
        width: 260px;

        .summary-total-chart {
          position: relative;

          .single-chart {
            svg {
              margin: 0 auto;

              .circle {
                stroke: $blue500;
              }
            }
          }

          .percentage {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            > * {
              font-weight: 700;
            }
          }
        }
      }

      > aside.summary-detail-score {
        width: calc(100% - 260px - 70px);
        gap: 24px;

        .summary-bar-chart {
          display: flex;
          flex-direction: column;
          gap: 12px;
          max-width: 560px;
        }
      }

      // > aside.summary-proctoring {
      //   width: 100%;

      //   ul {
      //     display: flex;
      //     flex-direction: column;
      //     gap: 20px;

      //     li {
      //       padding: 15px;
      //       display: flex;
      //       align-items: center;
      //       gap: 8px;
      //       border-radius: 8px;
      //       border: 1px solid $grey200;
      //       background-color: $white;
      //       font-size: 14px;
      //       font-style: normal;
      //       font-weight: 500;
      //       line-height: 140%; /* 19.6px */
      //       letter-spacing: -0.14px;
      //       color: $grey500;
      //     }
      //   }
      // }
      > aside.summary-proctoring {
        position: absolute;
        top: 40px;
        right: 40px;
        gap: 12px;
        flex-direction: row;

        .results-sub-title {
          color: $grey700;
          font-size: 14px;
          font-weight: 600;
          line-height: 19.6px;
        }

        ul {
          display: flex;
          flex-direction: row;
          gap: 12px;

          li {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: -0.14px;
            color: $grey500;

            .tooltip {
              display: none;
              flex-direction: row;
              padding: 8px;
              gap: 10px;
              width: 200px;
              background: $grey100;
              border-radius: 4px;
              font-weight: 500;
              align-items: center;
              text-align: center;
              letter-spacing: -0.02em;
              color: $grey900;
              margin-left: -90px;
            }

            &:hover {
              .tooltip {
                display: block;
                position: fixed;
              }
            }
          }
        }
      }
    }
  }

  article.tests-container {
    padding: 40px;
    background-color: $grey50;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    > aside.tests-contents {
      & > * {
        &:not(:last-child) {
          border-bottom: 1px solid $grey200;
          padding-bottom: 32px;
          margin-bottom: 40px;
        }
      }
    }
  }

  &.print {
    gap: 28px;
    * {
      page-break-after: auto;
      break-after: auto;
    }

    // import component
    .bar-chart-box {
      .number {
        font-size: 20px !important;

        > i {
          font-size: 12px;
        }
      }

      .bar-chart-graph {
        width: 100%;
      }

      .chart-title {
        font-size: 12px !important;
      }
    }

    .total-score-container {
      .score-box {
        width: calc(100% - 30px - 90px);
      }

      .score-percent {
        width: 90px;

        > span {
          font-size: 30px;

          > i {
            font-size: 14px;
          }
        }
      }
    }

    .result-score-box {
      p {
        font-size: 14px;
      }
    }

    .test-toggle-box {
      gap: 24px;

      &.print {
        .toggle-body-title-box {
          justify-content: flex-start !important;
        }

        .toggle-body-answer-box {
          .video-box {
            video {
              width: 100% !important;
            }
          }
        }
      }

      .toggle-header {
        padding-right: 0px;
        .toggle-header-score-box {
          .toggle-header-feedback {
            flex-direction: column;
            padding-bottom: 0;

            .toggle-header-feedback-title {
              font-size: 16px;
              text-align: left;
            }

            .feedback-container {
              gap: 24px;
              width: 100%;

              .feedback-wrapper {
                .feedback-text {
                  .toggle-header-feedback-desc {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }

      .toggle-body-wrapper {
        gap: 24px;

        .toggle-body-content {
          &.styling {
            padding: 28px 40px;

            .styling-title {
              font-size: 14px;
            }

            .toggle-body-answer-box {
              gap: 32px;

              .img-box {
                flex: 4;

                .img-bg {
                  img {
                    height: auto;
                    width: 100%;
                  }
                }
              }

              .answer-box {
                flex: 7;

                .answer-wrapper {
                  .result-stt-box {
                    padding: 0;
                    padding-top: 20px;

                    p {
                      max-height: none;
                    }
                  }
                }
              }
            }
          }

          &.language {
            .category-section {
              gap: 22px;

              .print-language-flex {
                display: flex;
                align-items: center;
                gap: 20px;
              }
            }

            .toggle-body-answer-box {
              &.print {
                margin-top: 0;
              }

              .speaking-video-score {
                display: flex;
                gap: 16px;
                align-items: center;
                margin-bottom: 16px;

                h6 {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 130%; /* 18.2px */
                  letter-spacing: -0.14px;
                  color: $grey700;
                }
              }
            }
          }
        }

        .toggle-body-title-box {
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 16px;
          gap: 24px;

          &.print {
            justify-content: flex-start;
          }

          &.essay {
            justify-content: flex-start;
          }

          .print-toggle-body-question {
            flex: 9;
            display: flex;
            gap: 6px;

            .question-number {
              font-size: 20px;
            }

            .question {
              font-size: 14px !important;
              margin-bottom: 0;
            }
          }

          .print-toggle-body-score {
            flex: 2;
            display: flex;
            justify-content: flex-end;
          }
        }

        .print-toggle-body-answer-box {
          .video-link-box {
            display: flex;
            flex-direction: column;
            gap: 16px;

            a.video-link {
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: $blue500;
              text-decoration: underline;
            }
          }
        }
      }
    }

    //common
    .results-sub-title {
      font-size: 18px;
    }

    .results-semi-main-title {
      font-size: 18px;
    }

    .results-desc {
      font-size: 12px;
      max-height: fit-content !important;
    }

    .results-desc-v2 {
      font-size: 12px;
    }

    > article.summary-container {
      background-color: $white;
      padding: 0;
      gap: 28px;

      > aside.summary-proctoring {
        display: flex;
        flex-direction: column;
        gap: 20px;

        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          li {
            width: 280px;
            padding: 8px 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid $grey200;
            background-color: $white;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
            letter-spacing: -0.14px;
            color: $grey500;
          }
        }
      }

      // > aside.summary-contents {
      //   display: flex;
      //   gap: 40px;
      //   padding: 40px;
      //   background-color: $grey50;
      //   border-radius: 8px;

      //   > aside {
      //     gap: 20px;
      //   }

      //   aside.summary-average {
      //     gap: 28px;
      //     flex: 4;

      //     .summary-total-chart {
      //       max-width: 200px;

      //       .percentage {
      //         .number {
      //           font-size: 40px !important;
      //         }

      //         .unit {
      //           font-size: 36px !important;
      //         }
      //       }
      //     }
      //   }
      //   aside.summary-detail-score {
      //     overflow: visible;
      //     flex: 6;
      //   }
      // }
      > aside.summary-contents {
        display: flex;
        gap: 70px;
        align-items: stretch;
        flex-wrap: wrap;

        > aside {
          display: flex;
          flex-direction: column;
          gap: 56px;
        }

        > aside.summary-average {
          width: 260px;

          .summary-total-chart {
            position: relative;

            .single-chart {
              svg {
                margin: 0 auto;

                .circle {
                  stroke: $blue500;
                }
              }
            }

            .percentage {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              > * {
                font-weight: 700;
              }
            }
          }
        }

        > aside.summary-detail-score {
          width: calc(100% - 260px - 70px);
          gap: 24px;

          .summary-bar-chart {
            display: flex;
            flex-direction: column;
            gap: 12px;
            max-width: 560px;
          }
        }

        // > aside.summary-proctoring {
        //   width: 100%;

        //   ul {
        //     display: flex;
        //     flex-direction: column;
        //     gap: 20px;

        //     li {
        //       padding: 15px;
        //       display: flex;
        //       align-items: center;
        //       gap: 8px;
        //       border-radius: 8px;
        //       border: 1px solid $grey200;
        //       background-color: $white;
        //       font-size: 14px;
        //       font-style: normal;
        //       font-weight: 500;
        //       line-height: 140%; /* 19.6px */
        //       letter-spacing: -0.14px;
        //       color: $grey500;
        //     }
        //   }
        // }
        > aside.summary-proctoring {
          position: absolute;
          top: 40px;
          right: 40px;
          gap: 12px;
          flex-direction: row;

          .results-sub-title {
            color: $grey700;
            font-size: 14px;
            font-weight: 600;
            line-height: 19.6px;
          }

          ul {
            display: flex;
            flex-direction: row;
            gap: 12px;

            li {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
              letter-spacing: -0.14px;
              color: $grey500;
            }
          }
        }
      }
    }

    > article.tests-container {
      gap: 24px;

      > aside.tests-contents {
        > *:not(:last-child) {
          margin-bottom: 24px;
          padding-bottom: 24px;
        }
      }
    }
  }
}
