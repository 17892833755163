@import '../include.scss';

.pages-protected-profile {
  > .container {
    padding: 40px;
    background-color: white;

    > .title {
      // font-size: 28px;
      font-weight: bold;
      margin-bottom: 14px;
    }

    > .tabs {
      border-bottom: 1px solid $grey100;
      margin-bottom: 40px;

      > .tabs-list {
        display: flex;
        border-radius: 4px;

        > .tabs-list-item {
          > .container {
            cursor: pointer;
            padding: 26px 24px;
            transition: background-color 0.2s;

            > span {
              // font-size: 18px;
              color: $grey900;
            }

            &:hover {
              background-color: $grey100;
            }
          }

          &.selected {
            > .container {
              border-bottom: 2px solid $blue500;

              > span {
                color: $blue500;
              }
            }
          }
        }
      }
    }
  }
}

.pages-protected-profile-account {
  > .row {
    display: flex;
    gap: 40px;
    margin-bottom: 32px;
  }

  > .change-password {
    padding: 0 24px;
    height: 56px;
    border-radius: 32px;
    margin-bottom: 40px;

    &::before {
      content: url('/assets/images/icon/lock.svg');
      margin-right: 12px;
      position: relative;
      top: 1px;
    }
  }

  > .submit {
    width: 400px;
    height: 56px;
    border-radius: 32px;
    margin: 0 auto;
  }
}

.pages-protected-profile-company {
  > .separater {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;

    > .separater-left {
      flex: 1;
      width: calc(50% - 20px);

      > .company-logo {
        width: 100%;
        height: 200px;
        background-color: $grey100;
        border-radius: 16px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        > img {
          max-width: 90%;
          max-height: 90%;
        }

        > .company-logo-empty {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;

          > .company-logo-empty-thumbnail-icon {
            width: 40px;
            height: 40px;
            background-image: url('/assets/images/icon/thumbnail.svg');
          }
        }

        &:hover {
          background-color: rgba($grey100, 0.6);
        }

        &:active {
          background-color: rgba($grey100, 0.8);
        }
      }
    }

    > .separater-right {
      flex: 1;
      width: calc(50% - 20px);
    }
  }

  > .submit {
    width: 400px;
    height: 56px;
    border-radius: 32px;
    margin: 0 auto;
  }
}

.pages-protected-profile-members {
  display: flex;
  flex-direction: column;
  gap: 24px;

  > h3.members-title {
    font-weight: 700;
  }

  > .members-form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    aside.filter-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;

      .reset-button {
        padding: 6px 8px;
        border: 1px solid $grey300;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 145%; /* 20.3px */
        letter-spacing: -0.14px;

        &:hover {
          background-color: $grey50;
        }
      }

      .hr {
        color: $grey300;
      }

      .members-filter {
        display: flex;
        gap: 8px;
        align-items: center;

        > .dropdown-container {
          min-width: 155px;

          &:not(:first-child) {
            min-width: 120px;
          }

          .dropdown-list-box {
            > .dropdown-list {
              > .dropdown-list-item {
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }

    aside.form-wrapper {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }

  .members-list {
    .table-border {
      &.member-list-contents {
        .recruitment-table {
          tbody {
            tr {
              cursor: default;

              &.disabled {
                opacity: 0.4;
                background-color: $grey100;
              }

              &:first-child {
                width: 164px;
              }

              td {
                .flex {
                  display: flex;
                  gap: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
}
