@import '../include.scss';

.components-navigator-app-navigator {
  height: 100%;

  > ul.tabs-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    height: 100%;

    > li.tabs-list-item {
      height: 100%;

      > .tabs-list-item-container {
        padding: 0 24px;
        height: 100%;
        cursor: pointer;
        color: $grey500;
        transition: background-color 0.2s;

        > span {
          display: inline-flex;
          height: 100%;
          font-weight: 700;
          color: $grey900;
          align-items: center;
          justify-content: center;
          border-bottom: 2px solid transparent;
        }

        &:hover {
          background-color: $grey100;
        }

        &.selected {
          > span {
            color: $blue500;
            border-bottom: 2px solid $blue500;
          }
        }

        &.mobile {
          display: none;
        }
      }
    }

    li:nth-child(1).selected ~ .indicator {
      transform: translateX(100px * 0);
    }

    li:nth-child(2).selected ~ .indicator {
      transform: translateX(100px * 1);
    }

    li:nth-child(3).selected ~ .indicator {
      transform: translateX(100px * 2);
    }

    > .indicator {
      // position: absolute;
      // left: 0;
      // bottom: 0;
      // height: 2px;
      // margin: 0 24px;
      // background-color: $blue500;
      // transition: transform 0.3s, width 0.3s;
      // transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }
}
