#pages-selected-interview-date {
  max-width: none !important;
  width: 100% !important;
  background-color: $white !important;

  .container {
    position: relative;
    max-width: 958px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;

    > section.title-section {
      position: relative;

      h2 {
        font-weight: 700;
        text-align: center;
      }

      > .language-section {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        > .language-selector {
          position: relative;

          > .language-selector-toggle {
            position: relative;
            width: 115px;
            height: 32px;
            border-radius: 8px;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 10px;
            border: 1px solid $grey300;
            gap: 5px;
            cursor: pointer;

            &::after {
              content: url('/assets/images/icon/chevron-bottom.svg');
              // transform: rotate(180deg);
              position: absolute;
              top: 4px;
              right: 10px;
            }

            &:hover,
            &.show {
              border: 1px solid $grey500;
            }

            &:active {
              border: 1px solid rgba($grey500, 0.6);
            }
          }

          > .language-selector-dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: white;
            z-index: 1;
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
              rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            border-radius: 8px;

            > ul.language-selector-dropdown-list {
              display: flex;
              flex-direction: column-reverse;
              border-radius: 8px;
              overflow: hidden;
              text-align: center;

              > li.language-selector-dropdown-list-item {
                cursor: pointer;
                padding: 10px;

                &:hover {
                  background-color: $grey100;
                }
              }
            }
          }
        }
      }
    }

    > section.contents-section {
      article.schedule-list {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-bottom: 40px;

        > aside {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 76px;
          padding: 24px;
          border: 1px solid $grey300;
          border-radius: 16px;
          transition: all 0.2s ease-in-out;
          cursor: pointer;
          max-width: 306px;

          &:hover {
            border-color: $blue300;
          }

          &.selected {
            border-color: $blue500;
            box-shadow: 0px 6.65724px 16.64311px 0px rgba(50, 148, 238, 0.1);
          }

          .information {
            > i {
              margin-bottom: 8px;
            }

            > .date {
              margin-bottom: 24px;

              h5 {
                font-weight: 700;
              }
            }

            > .time {
              display: flex;
              gap: 20px;

              aside.time-box {
                &.start {
                  .time-box {
                    position: relative;

                    &::after {
                      content: '-';
                      font-size: 20px;
                      font-weight: 600;
                      position: absolute;
                      top: 50%;
                      right: -14px;
                      transform: translateY(-50%);
                    }
                  }
                }

                .sub-title {
                  color: $grey500;
                  margin-bottom: 4px;
                }

                .time-box {
                  font-weight: 600;
                  padding: 10px 20px;
                  background-color: $grey50;
                  border-radius: 4px;
                }
              }
            }
          }

          .taken-time {
            text-align: right;

            p {
              color: $grey700;
            }
          }
        }
      }

      article.caution {
        margin-bottom: 20px;

        .blue-box {
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 14px 10px;
          background-color: $blue50;
          border-radius: 8px;

          p {
            color: $blue600;
            font-weight: 600;

            a {
              color: $blue600;
            }
          }
        }
      }

      article.helper {
        padding: 20px;
        background-color: $grey100;
        border-radius: 8px;

        pre {
          font-size: 14px !important;
          color: $grey800;
        }
      }
    }

    > section.button-section {
      display: flex;
      justify-content: center;

      button {
        font-weight: 500;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }
}
