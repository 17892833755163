@import '../include.scss';

.components-header-app-header {
  > .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
    width: 100%;
    height: 64px;
    background: $white;
    border-bottom: 1px solid $grey100;

    > .header-container-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 48px;
      height: 100%;

      > .header-logo {
        cursor: pointer;
        transition: transform 0.1s;

        // &:hover {
        //     transform: scale(1.05);
        // }

        &:active {
          transform: scale(1.02);
        }
      }
    }

    > .header-container-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 12px;
      height: 100%;

      > .header-credit {
        > ul {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: $grey50;
          border-radius: 4px;
          > * {
            font-weight: 700;
          }

          li.header-credit-list {
            text-align: center;
            padding: 4px 12px;
            position: relative;

            &:not(:last-child) {
              &::after {
                content: '';
                display: block;
                height: 20px;
                width: 1px;
                background-color: $grey300;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }
            }

            .header-credit-count {
              .blue {
                color: $blue500;
              }
            }
          }
        }
      }

      > .header-more {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 12px;
        height: 40px;
        height: 100%;

        > button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          gap: 8px;
          height: 40px;
          border-radius: 4px;
          max-width: 150px;

          &::before {
            content: url('/assets/images/icon/plus.svg');
            position: relative;
          }
        }

        > .header-more-toggle {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2px 12px;
          gap: 4px;
          height: 100%;
          > .wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            height: 36px;
            > .name {
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              text-align: center;
              color: $grey900;
            }
            > .email {
              font-style: normal;
              font-weight: 500;
              display: flex;
              align-items: center;
              text-align: center;
              color: $grey500;
            }
          }

          > svg {
            position: relative;
            top: 3px;
            margin: 0 7px;
          }

          &:hover {
            background-color: $grey100;
          }
        }

        > .header-more-dropdown {
          position: absolute;
          width: 140px;
          padding: 10px;
          right: 16px;
          box-sizing: content-box;
          z-index: 2;
          overflow: hidden;
          top: 48px;

          > ul.header-more-dropdown-list {
            width: 100%;
            background-color: white;
            box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
              0px 1px 2px rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            overflow: hidden;
            padding: 6px 0;

            > li.header-more-dropdown-list-item {
              cursor: pointer;
              padding: 12px 16px;

              &:hover {
                background-color: $grey100;
              }
            }
          }
        }
      }

      > .header-navigator {
        &.mobile {
          display: none;
        }
      }
    }
  }
}

.components-header-apply-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  height: 80px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  left: 0;
  margin-bottom: 40px;
  padding: 28px 30px;
  z-index: 10;
  position: relative;

  > .language-selector {
    position: relative;

    > .language-selector-toggle {
      position: relative;
      width: 115px;
      height: 32px;
      border-radius: 8px;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10px;
      border: 1px solid $grey300;
      gap: 5px;
      cursor: pointer;

      &::after {
        content: url('/assets/images/icon/chevron-bottom.svg');
        // transform: rotate(180deg);
        position: absolute;
        top: 4px;
        right: 10px;
      }

      &:hover,
      &.show {
        border: 1px solid $grey500;
      }

      &:active {
        border: 1px solid rgba($grey500, 0.6);
      }
    }

    > .language-selector-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: white;
      z-index: 1;
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      border-radius: 8px;

      > ul.language-selector-dropdown-list {
        display: flex;
        flex-direction: column-reverse;
        border-radius: 8px;
        overflow: hidden;
        text-align: center;

        > li.language-selector-dropdown-list-item {
          cursor: pointer;
          padding: 10px;

          &:hover {
            background-color: $grey100;
          }
        }
      }
    }
  }
}
