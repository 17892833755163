@import '../include.scss';

.pages-auth-signin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // >.logo {
  //     padding: 25px 36px;
  //     border: 1px solid $blue500;
  //     border-radius: 20px;
  //     margin-bottom: 50px;

  //     >.logo-title {
  //         font-weight: bold;
  //         color: $blue500;
  //         font-size: 24px;
  //     }
  // }
  > .logo {
    margin-bottom: 80px;
  }

  > .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 460px;
    margin-bottom: 32px;

    // >.form-input {
    //     display: inline-flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     width: 100%;
    //     height: 56px;
    //     border: 1px solid $grey300;
    //     border-radius: 4px;
    //     padding: 0 15px;
    //     gap: 15px;

    //     >input {
    //         flex: 1;
    //         font-size: 16px;
    //     }
    // }

    > .form-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      // >label {
      //     font-size: 16px;
      // }

      // >button {
      //     font-size: 16px;
      // }
    }

    > .form-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 56px;
      border-radius: 16px;
      // font-size: 16px;
    }

    > .signup {
      > p {
        // font-size: 16px;
        font-weight: 500;
        color: $grey900;

        > button {
          color: $blue500;
          // font-size: 16px;
          margin-left: 8px;

          &:hover {
            color: $primary_color_hover;
          }

          &:active {
            color: $primary_color_active;
          }
        }
      }
    }
  }
}

.pages-auth-signup-process-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .logo-container {
    width: 460px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 32px;

    // >.logo {
    //     padding: 25px 36px;
    //     border: 1px solid $blue500;
    //     border-radius: 20px;
    //     margin-bottom: 50px;

    //     >.logo-title {
    //         font-weight: bold;
    //         color: $blue500;
    //         font-size: 24px;
    //     }
    // }
  }

  > .explain {
    width: 460px;
    // font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  > .form {
    display: flex;
    flex-direction: column;
    width: 460px;
    gap: 24px;
    margin-bottom: 24px;

    // >.form-input {
    //     display: inline-flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     width: 100%;
    //     height: 56px;
    //     border: 1px solid $grey300;
    //     border-radius: 4px;
    //     padding: 0 15px;
    //     gap: 15px;

    //     >input {
    //         flex: 1;
    //         font-size: 16px;
    //     }
    // }

    > .form-checkboxes {
      > .label-container {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        > label {
          // font-size: 16px;

          > span {
            cursor: pointer;
            color: $blue500;

            &:hover {
              color: $primary_color_hover;
            }

            &:active {
              color: $primary_color_active;
            }
          }
        }
      }
    }

    > button.form-submit {
      height: 56px;
      border-radius: 56px;
      // font-size: 16px;
    }
  }

  > p {
    // font-size: 16px;
    color: $grey900;

    > button {
      margin-left: 8px;
      // font-size: 16px;
    }
  }
}

.pages-auth-signup-process-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .logo-container {
    display: flex;
    justify-content: flex-start;
    width: 460px;
    margin-bottom: 32px;

    // >.logo {
    //     padding: 25px 36px;
    //     border: 1px solid $blue500;
    //     border-radius: 20px;
    //     margin-bottom: 80px;

    //     >.logo-title {
    //         font-weight: bold;
    //         color: $blue500;
    //         font-size: 24px;
    //     }
    // }
    > .logo {
    }
  }

  > .explain {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 460px;
    margin-bottom: 32px;

    > .explain-main {
      // font-size: 24px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    > .explain-sub {
      // font-size: 16px;
      font-weight: 400;
    }
  }

  > .form {
    width: 460px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    // >.form-input {
    //     display: inline-flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     width: 100%;
    //     height: 56px;
    //     border: 1px solid $grey300;
    //     border-radius: 4px;
    //     padding: 0 15px;
    //     gap: 15px;

    //     >input {
    //         flex: 1;
    //         font-size: 16px;
    //     }
    // }

    > .form-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 460px;

      > button {
        border-radius: 16px;
      }

      > .form-buttons-skip {
        width: 132px;
        height: 56px;
        // font-size: 16px;
      }

      > .form-buttons-go-next {
        width: 100%;
        height: 56px;
        // font-size: 16px;

        > svg {
          margin-left: 11px;
        }
      }
    }
  }
}

.pages-auth-signup-process-thumbnail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .logo-container {
    width: 460px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 32px;

    > .logo {
    }

    // >.logo {
    //     padding: 25px 36px;
    //     border: 1px solid $blue500;
    //     border-radius: 20px;
    //     margin-bottom: 80px;

    //     >.logo-title {
    //         font-weight: bold;
    //         color: $blue500;
    //         font-size: 24px;
    //     }
    // }
  }

  > .explain {
    width: 460px;
    margin-bottom: 32px;

    > .explain-main {
      // font-size: 24px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    > .explain-sub {
      // font-size: 16px;
      font-weight: 400;
      color: $grey900;
    }
  }

  > .form {
    > .form-upload {
      cursor: pointer;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 460px;
      height: 200px;
      border-radius: 16px;
      background-color: $grey100;
      gap: 12px;
      margin-bottom: 32px;
      border: 2px solid transparent;

      > img {
        max-width: 80%;
        max-height: 80%;
      }

      &:hover {
        background-color: rgba($grey100, 0.6);
      }

      &:active {
        background-color: rgba($grey100, 0.8);
      }

      &.selected {
        background-color: white;
        border: 2px solid $grey100;
      }
    }

    > .form-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 460px;

      > button {
        border-radius: 16px;
        // font-size: 16px;
      }

      > .form-buttons-skip {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 132px;
        height: 56px;
      }

      > .form-buttons-done {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 312px;
        height: 56px;
      }
    }
  }
}

.pages-auth-set-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // >.logo {
  //     padding: 25px 36px;
  //     border: 1px solid $blue500;
  //     border-radius: 20px;
  //     margin-bottom: 80px;

  //     >.logo-title {
  //         font-weight: bold;
  //         color: $blue500;
  //         font-size: 24px;
  //     }
  // }
  .logo {
    margin-bottom: 80px;
  }

  > .explain {
    width: 460px;
    margin-bottom: 32px;

    > .explain-main {
      // font-size: 24px;
      font-weight: bold;
    }
  }

  > .form {
    width: 460px;
    margin-bottom: 32px;

    // >.form-input {
    //     display: inline-flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     width: 100%;
    //     height: 56px;
    //     border: 1px solid $grey300;
    //     border-radius: 4px;
    //     padding: 0 15px;
    //     gap: 15px;
    //     margin-bottom: 8px;

    //     >input {
    //         flex: 1;
    //         font-size: 16px;
    //     }
    // }

    > .form-input {
      margin-bottom: 8px;
    }

    > .form-caution {
      // font-size: 12px;
      color: $grey900;
      margin-bottom: 8px;
    }

    > .form-submit {
      width: 100%;
      height: 56px;
      border-radius: 16px;
      // font-size: 16px;
      margin-top: 24px;
    }
  }

  // >.go-back {
  //     font-size: 16px;
  // }
}

.pages-auth-expire-auth-token {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    margin-bottom: 80px;
  }

  > .explain {
    width: 460px;
    margin-bottom: 32px;

    > .explain-main {
      font-weight: bold;
      // font-size: 24px;
      margin-bottom: 16px;
    }

    > .explain-sub {
      font-weight: 400;
      // font-size: 16px;
      // line-height: 23.2px;
      color: $grey900;
    }
  }

  > .form {
    width: 460px;
    margin-bottom: 32px;

    > .form-submit {
      width: 100%;
      height: 56px;
      border-radius: 16px;
      // font-size: 16px;
    }
  }

  // >.go-back {
  //     font-size: 16px;
  // }
}

.pages-auth-agree {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 460px !important;
  margin: 0 auto;

  > .header-logo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 48px;
  }

  > .check-form {
    width: 100%;

    .check-form-title {
      margin-bottom: 32px;
      h2 {
        font-weight: 700;
      }
    }

    .check-form-wrap {
      margin-bottom: 18px;

      &.privacy {
        margin-bottom: 32px;
      }

      > .control {
        flex-direction: row;
        align-items: center;

        .check-label {
          margin-right: 4px;
          > span {
            padding-top: 0;
            padding-bottom: 0;
          }
          > span > span {
            color: $blue500;
          }

          span {
            font-weight: 500;
            font-size: 16px !important;
          }
        }

        .check-required-tag {
          color: $red600;
          font-weight: 500;
        }
      }
    }

    .submit {
      display: inline-block;
      width: 100%;
      padding: 18.5px 24px;
      font-weight: 500;
      border-radius: 4px;
    }
  }
}
