$mobile: 'only screen and (max-width: 1023px)';
$pc: 'screen and (min-width: 1024px)';

@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Black.subset.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-ExtraBold.subset.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Bold.subset.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-SemiBold.subset.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Medium.subset.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Regular.subset.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/assets/fonts/Pretendard-Light.subset.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

$mobile_max_width: 700px;
$tablet_min_width: 701px;
$tablet_max_width: 1024px;
$pc_min_width: 1025px;
$pc_max_width: 1700px;

/* colors */
$white: #ffffff;
$black: #000000;
$grey50: #f8f9fa;
$grey100: #f1f3f5;
$grey200: #e2e5e9;
$grey300: #d6dbe0;
$grey400: #b5bdc5;
$grey500: #89949f;
$grey600: #6e7a87;
$grey700: #57616b;
$grey800: #454d54;
$grey900: #2e3338;
$red50: #fdeae9;
$red100: #fbcfcd;
$red200: #fab4b0;
$red300: #f99892;
$red400: #fa7b73;
$red500: #fb5e54;
$red600: #d7554c;
$red700: #b44b44;
$red800: #923f3a;
$red900: #713430;
$orange50: #fdf0e3;
$orange100: #fbddc1;
$orange200: #fbca9d;
$orange300: #fbb778;
$orange400: #fda452;
$orange500: #ff912b;
$orange600: #da7e2a;
$orange700: #b66c27;
$orange800: #935923;
$orange900: #71461e;
$green50: #c6fde8;
$green100: #92f1cd;
$green200: #6de0b4;
$green300: #49e0a6;
$green400: #29d292;
$green500: #24c185;
$green600: #38a67c;
$green700: #3a876a;
$green800: #0e6644;
$green900: #095236;
$blue50: #e4f0fb;
$blue100: #c2def6;
$blue200: #a0cbf3;
$blue300: #7cb9f0;
$blue400: #57a6ef;
$blue500: #3294ee;
// $blue600:#2F81CB;
// $blue700:#2B6DAA;
$blue600: #096dd9;
$blue700: #0050b3;
$blue800: #265a89;
$blue900: #20476a;
$indigo50: #e6eaf8;
$indigo100: #c6d0f0;
$indigo200: #a6b5e9;
$indigo300: #859ae2;
$indigo400: #637edc;
$indigo500: #4162d7;
$indigo600: #3c57b8;
$indigo700: #354b9a;
$indigo800: #2e3f7d;
$indigo900: #263361;
$purple50: #f3e9f9;
$purple100: #e5cef3;
$purple200: #d7b2ed;
$purple300: #c995e8;
$purple400: #bb78e4;
$purple500: #ad5ae0;
$purple600: #9651c0;
$purple700: #7f47a1;
$purple800: #683c83;
$purple900: #523166;
$pink50: #ffe8f6;
$pink100: #ffceeb;
$pink200: #ffaadd;
$pink300: #ff84cd;
$pink400: #f25cb6;
$pink500: #ef33a3;
$pink600: #d0288c;
$pink700: #b7297e;
$pink800: #a62271;
$pink900: #8c165d;

/* font styles */
.fs70d,
.fs70m,
.fs70 {
  font-size: 70px;
  line-height: 120%;
  letter-spacing: -0.01em;
}
.fs58d,
.fs58m,
.fs58 {
  font-size: 58px;
  line-height: 120%;
  letter-spacing: -0.01em;
}
.fs56d,
.fs56m,
.fs56 {
  font-size: 56px;
  line-height: 120%;
  letter-spacing: -0.01em;
}
.fs48d,
.fs48m,
.fs48 {
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.01em;
}
.fs46d,
.fs46m,
.fs46 {
  font-size: 46px;
  line-height: 120%;
  letter-spacing: -0.01em;
}
.fs36d,
.fs36m,
.fs36 {
  font-size: 36px;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.fs32d,
.fs32m,
.fs32 {
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.fs30d,
.fs30m,
.fs30 {
  font-size: 30px;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.fs28d,
.fs28m,
.fs28 {
  font-size: 28px;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.fs24d,
.fs24m,
.fs24 {
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.fs20d,
.fs20m,
.fs20 {
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.01em;
}
.fs18d,
.fs18m,
.fs18 {
  font-size: 18px !important;
  line-height: 145% !important;
  letter-spacing: -0.01em !important;
}
.fs16d,
.fs16m,
.fs16 {
  font-size: 16px !important;
  line-height: 145% !important;
  letter-spacing: -0.01em !important;
}
.fsbtn16d,
.fsbtn16m,
.fsbtn16 {
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.01em;
}
.fs14d,
.fs14m,
.fs14 {
  font-size: 14px !important;
  line-height: 145% !important;
  letter-spacing: -0.01em !important;
}
.fsbtn14d,
.fsbtn14m,
.fsbtn14 {
  font-size: 14px !important;
  line-height: 120% !important;
  letter-spacing: -0.01em !important;
}
.fs12d,
.fs12m,
.fs12 {
  font-size: 12px !important;
  line-height: 145% !important;
  letter-spacing: -0.01em !important;
}
.fsbtn12d,
.fsbtn12m,
.fsbtn12 {
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.01em;
}
.fs11d,
.fs11m,
.fs11 {
  font-size: 11px !important;
  line-height: 145%;
  letter-spacing: -0.01em;
}
.fs9d,
.fs9m,
.fs9 {
  font-size: 9px !important;
  line-height: 145% !important;
  letter-spacing: -0.01em !important;
}
.fs8d,
.fs8m,
.fs8 {
  font-size: 8px !important;
  line-height: 145%;
  letter-spacing: -0.01em;
}
.fs7d,
.fs7m,
.fs7 {
  font-size: 7px !important;
  line-height: 145%;
  letter-spacing: -0.01em;
}

.fcgrey700 {
  color: $grey700;
}

.bold {
  font-weight: bold;
}

@media #{$pc} {
  .fs28to24 {
    font-size: 28px !important;
    line-height: 130% !important;
    letter-spacing: -0.01em !important;
  }
  .fs16to14 {
    font-size: 16px !important;
    line-height: 145% !important;
    letter-spacing: -0.01em !important;
  }
}
@media #{$mobile} {
  .fs28to24 {
    font-size: 24px !important;
  }

  .fs16to14 {
    font-size: 14px !important;
  }
}

.width100 {
  width: 100%;
}

$app_background_color: var(--app-background-color);
$primary_color: $blue500;
$primary_color_hover: rgba($blue500, 0.6);
$primary_color_active: rgba($blue500, 0.8);
$secondary_color_hover: rgba($pink600, 0.6);
$secondary_color_active: rgba($pink600, 0.8);
$tertiary_color: rgb(255, 248, 243);
$tertiary_color_hover: rgba(255, 248, 243, 0.6);
$tertiary_color_active: rgba(255, 248, 243, 0.8);
$grey_disabled_color: rgba(28, 27, 31, 0.12);
$default_color_hover: rgba($black, 0.6);
$default_color_active: rgba($black, 0.8);

:root {
  --app-background-color: #f8f9fa;
}

:export {
  mobileMaxWidth: $mobile_max_width;
  tabletMinWidth: $tablet_min_width;
  tabletMaxWidth: $tablet_max_width;
  pcMinWidth: $pc_min_width;
  pcMaxWidth: $pc_max_width;
  appBackgroundColor: $grey50;
  primaryColor: $primary_color;
}
