@import '../include.scss';

.components-footer-auth-footer,
.components-footer-app-footer {
  > .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    gap: 20px;

    > .corporation-title {
      color: $grey500;
    }

    > .language-selector {
      position: relative;

      > .language-selector-toggle {
        position: relative;
        width: 115px;
        height: 32px;
        border-radius: 8px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        border: 1px solid $grey300;
        gap: 5px;
        cursor: pointer;

        &::after {
          content: url('/assets/images/icon/chevron-bottom.svg');
          // transform: rotate(180deg);
          position: absolute;
          top: 4px;
          right: 10px;
        }

        &:hover,
        &.show {
          border: 1px solid $grey500;
        }

        &:active {
          border: 1px solid rgba($grey500, 0.6);
        }
      }

      > .language-selector-dropdown {
        position: absolute;
        top: -30px;
        right: -80px;
        background-color: white;
        z-index: 1;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
          rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        border-radius: 8px;

        > ul.language-selector-dropdown-list {
          display: flex;
          flex-direction: column-reverse;
          > li.language-selector-dropdown-list-item {
            cursor: pointer;
            padding: 10px;

            &:hover {
              background-color: $grey100;
            }
          }
        }
      }
    }
  }
}

.components-footer-apply-footer {
  .container {
    display: flex;
    justify-content: center;
    padding: 40px 0 20px;
  }
}
