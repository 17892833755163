@import './include.scss';
@import './reset.scss';

@media (max-width: $mobile_max_width) {
}

@media (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
}

@media (max-width: $tablet_max_width) {
  #root {
    > header {
    }

    > main {
      margin: 0 auto;
      width: 90vw;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      padding: 40px 0;
    }

    > footer {
    }

    .enter {
      opacity: 0;
      transform: translateY(-100%);
    }

    .enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.175);
    }
  }

  .Toastify__toast-container {
    > .Toastify__toast {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 25px;
    }
  }

  @import './pc/header.scss';
  @import './pc/footer.scss';
  @import './pc/navigator.scss';
  @import './pc/modal.scss';
  @import './pc/auth.scss';
  @import './pc/component.scss';
  @import './pc/assessments.scss';
  @import './pc/candidates.scss';
  @import './pc/tests.scss';
  @import './pc/profile.scss';
  @import './pc/admin.scss';
  @import './pc/recruitments.scss';
  @import './pc/apply.scss';
  @import './pc/interview.scss';
  @import './pc/offer.scss';
}

@media (min-width: $pc_min_width) {
  #root {
    > header {
    }

    > main {
      margin: 0 auto;
      padding: 0;
      width: calc(100% - 80px);
      max-width: 1360px;
    }

    > footer {
    }

    .enter {
      opacity: 0;
      transform: translateY(-100%);
    }

    .enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.175);
    }
  }

  @import './pc/header.scss';
  @import './pc/footer.scss';
  @import './pc/navigator.scss';
  @import './pc/modal.scss';
  @import './pc/auth.scss';
  @import './pc/component.scss';
  @import './pc/assessments.scss';
  @import './pc/candidates.scss';
  @import './pc/tests.scss';
  @import './pc/profile.scss';
  @import './pc/admin.scss';
  @import './pc/recruitments.scss';
  @import './pc/apply.scss';
  @import './pc/interview.scss';
  @import './pc/offer.scss';
}

.button {
  cursor: pointer;
}

.text-button {
  &.primary {
    color: $blue500;

    &:hover {
      color: $primary_color_hover;
    }

    &:active {
      color: $primary_color_active;
    }
  }

  &.secondary {
    color: $pink600;

    &:hover {
      color: $secondary_color_hover;
    }

    &:active {
      color: $secondary_color_active;
    }
  }

  &.tertiary {
    color: $tertiary_color;

    &:hover {
      color: $tertiary_color_hover;
    }

    &:active {
      color: $tertiary_color_active;
    }
  }
}

.common-button {
  cursor: default;
  background-color: $grey100;
  display: flex;
  justify-content: center;
  align-items: center;
  // font-size: 16px;

  &.active {
    cursor: pointer;
    background-color: $black;
    color: $white;

    &:hover {
      background-color: $default_color_hover;
    }

    &:active {
      background-color: $default_color_active;
    }
  }

  &.pending {
    justify-content: space-between !important;
    padding: 0 20px;
  }

  &.primary {
    &.active {
      cursor: pointer;
      background-color: $blue500;
      color: $white;

      &:hover {
        // background-color: $primary_color_hover;
        background-color: $blue600;
      }

      &:active {
        // background-color: $primary_color_active;
        background-color: $blue700;
      }
    }
  }

  &.cancel {
    &.active {
      cursor: pointer;
      background-color: $grey100;
      color: black;
    }

    &:hover {
      background-color: rgba($grey100, 0.6);
    }

    &:active {
      background-color: rgba($grey100, 0.8);
    }
  }

  &.gray {
    &.active {
      cursor: pointer;
      // background-color: $grey300;
      background-color: $white;
      color: black;
      border: 1px solid $grey300;

      &:hover {
        // background-color: rgba($grey300, 0.6);
        background-color: $grey100;
      }

      &:active {
        // background-color: rgba($grey300, 0.8);
        background-color: $grey200;
      }
    }
  }

  &.gray-light {
    &.active {
      cursor: pointer;
      background-color: $grey100;
      color: black;

      &:hover {
        background-color: $grey300;
      }

      &:active {
        background-color: rgba($grey300, 0.8);
      }
    }
  }

  &.white {
    &.active {
      cursor: pointer;
      background-color: white;
      color: black;
    }

    &:hover {
      background-color: $grey100;
    }

    &:active {
      background-color: rgba($grey100, 0.6);
    }
  }
}

.common-checkbox {
  display: inline-flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 2px solid $black;
    margin-right: 6px;
  }

  &.checked::before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url('/assets/images/icon/checkbox.svg');
    background-size: contain;
    border: none;
  }
  &::before {
  }
}

.Toastify__toast-container {
  bottom: 40px !important;
  width: unset !important;
  > .Toastify__toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 24px;
    position: relative;
    width: 400px;
    min-height: 72px;
    background: $white;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    > .Toastify__toast-body {
      padding: 0px;
      > div {
        max-width: 280px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 145%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: $grey900;
        word-break: keep-all;
        word-wrap: break-word;
      }
    }
  }

  .toast-close-button {
    width: 24px;
    height: 24px;
    border: none;
    min-width: 24px;
    > svg > path {
      fill: $blue500;
    }
    &:hover {
      > svg > path {
        fill: $blue700;
      }
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slide-in-dropdown-animation {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.slide-in-dropdown > .container > ul {
  animation: slide-in-dropdown-animation 0.4s ease;
}

@keyframes slide-out-dropdown-animation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.slide-out-dropdown > .container > ul {
  animation: slide-out-dropdown-animation 0.4s ease;
  animation-fill-mode: forwards;
}

.skill-type {
  padding: 4px 8px;
  height: 24px;
  background: $purple500;
  border-radius: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: $white;

  &.tag_multiple-choice {
    background: $purple500;
  }
  &.tag_essay {
    background: $orange700;
  }
  &.tag_subjective {
    background: $indigo500;
  }
  &.tag_video {
    background: $pink500;
  }
  &.tag_file-upload {
    background: $orange500;
  }
  &.tag_spreadseat {
    background: $green700;
  }
  &.tag_presentation {
    background: $red500;
  }
  &.tag_code {
    background: $indigo900;
  }
  &.tag_styling {
    background: $green900;
  }
}
