.modal-overlay {
  > .modal {
    > .modal-close {
    }
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  .candidate-delete-modal {
    width: 312px;
    height: 188px;
    display: flex;
    background: white;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .title {
      padding: 16px;
      // word-break: break-all;
      word-break: keep-all;
      word-wrap: break-word;

      > .title-main {
        text-align: center;
        // font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
      }
    }

    > .buttons {
      width: 100%;
      display: flex;
      padding: 0 8px;
      gap: 8px;
      >button {
        flex:1;
        width: 100%;
        height: 56px;
        border-radius: 36px;
        margin-top: 16px;
      }
    }
  }
}

.part-modal {
  animation: show-modal-animation 0.28s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  > article {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: $app_background_color;

    > .buttons {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      width: 100%;
      height: 56px;

      > button {
        flex: 1;
        // font-size: 16px;
      }
    }
  }

  &.hasButton {
    > article {
      padding: 0 0 56px 0;
    }
  }

  @keyframes show-modal-animation {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }
}

.full-modal {
  animation: show-modal-fade-animation 0.28s
    cubic-bezier(0.175, 0.885, 0.32, 1.275);

  > svg.modal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 2;
  }

  > article {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: $app_background_color;
    width: 90vw;
    height: 90vh;
  }

  @keyframes show-modal-fade-animation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.integration-modal {
  z-index: 100;
  animation: show-modal-fade-animation 0.4s ease;

  > article {
    height: 100%;
    overflow: auto;
  }

  @keyframes show-modal-fade-animation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.modals-assessments-confirm-cancel {
  width: 312px;
  height: 188px;
  display: flex;
  justify-content: center;
  align-items: center;

  > .title {
    padding: 16px;
    // word-break: break-all;
    word-break: keep-all;
    word-wrap: break-word;

    > .title-main {
      text-align: center;
      // font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    > .title-sub {
      // font-size: 14px;
      color: $grey500;
      // line-height: 18.2px;
    }

    > .title-sub {
    }
  }

  > .buttons {
  }
}

.modals-assessments-position-detail {
  width: 90vw;
  max-width: 1312px;
  max-height: 952px;
  padding: 120px 40px;

  > .header {
    margin-bottom: 40px;

    > .header-title {
      // font-size: 28px;
      font-weight: bold;
    }
  }

  > .split {
    display: flex;
    gap: 40px;

    > .split-left {
      flex: 1;

      > .split-left-description {
        // font-size: 16px;
        // line-height: 23.2px;
        margin-bottom: 40px;
      }

      > ul.split-left-recommend-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        > li.split-left-recommend-list-item {
          padding: 6px 12px;
          background-color: rgba($blue500, 0.15);
          border-radius: 8px;
        }
      }
    }

    > .split-right {
      flex: 1;

      > .split-right-tbd {
        > .split-right-tbd-title {
          // font-size: 20px;
          font-weight: bold;
          margin-bottom: 16px;
        }

        // >.split-right-tbd-description {
        //     font-size: 16px;
        //     line-height: 23.2px;
        // }
      }
    }
  }
}

.modals-assessments-objective-test-detail {
  display: flex;
  flex-direction: column;
  padding: 0 0 24px;
  gap: 12px;
  position: relative;
  width: 560px !important;
  height: auto !important;
  max-height: 90vh !important;
  background: $white;
  overflow: auto !important;

  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    width: 100%;
    height: 56px;

    > .header-title {
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      color: $grey900;
    }
  }

  > .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px;
    gap: 24px;

    width: 560px;
    // height: 338px;
    > .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      gap: 16px;
      width: 512px;
      // height: 116px;
      > .title {
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        color: $black;
        height: 31px;
      }
      > .desc {
        font-style: normal;
        font-weight: 500;
        display: flex;
        color: $grey900;
        // height: 69px;
        // overflow: auto;
      }
    }
    > .desc {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      width: 512px;
      height: 55px;
      > .type {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 8px;
        width: 170.67px;
        height: 55px;
        > .title {
          font-style: normal;
          font-weight: 700;
          align-items: center;
          color: $grey900;
        }
        > .types {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          height: 24px;
          > .type {
            flex-direction: row;
            align-items: center;
            padding: 4px 8px;
            height: 24px;
            border-radius: 16px;
            font-style: normal;
            font-weight: 700;
            text-align: center;
            color: $white;
            &.purple500 {
              background: $purple500;
            }
            &.indigo500 {
              background: $indigo500;
            }
          }
        }
      }
      > .category {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 8px;
        width: 170.67px;
        height: 55px;
        > .title {
          font-style: normal;
          font-weight: 700;
          align-items: center;
          color: $grey900;
        }
        > .name {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          align-items: center;
          color: $grey900;
        }
      }
      > .duration {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 8px;
        width: 170.67px;
        height: 55px;
        > .title {
          font-style: normal;
          font-weight: 700;
          align-items: center;
          color: $grey900;
        }
        > .duration {
          font-style: normal;
          font-weight: 500;
          align-items: center;
          color: $grey900;
        }
      }
    }
    > .skillset {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      gap: 16px;
      width: 100%;
      // height: 119px;

      > .title {
        font-style: normal;
        font-weight: 700;
        align-items: center;
        color: $black;
      }
      > ul {
        width: 100%;
        display: flex;
        gap: 16px;
        flex-wrap: wrap;

        > li {
          padding: 6px 12px;
          background: $blue50;
          border-radius: 8px;
          color: $grey900;
          max-width: 100%;
          height: 32px;
        }
      }
    }
  }
}

.modals-assessments-invite-detail {
  display: flex;
  flex-direction: column;
  padding: 0 0 24px;
  gap: 16px;
  position: relative;
  width: 560px !important;
  height: auto !important;
  max-height: 90vh !important;
  background: $white;
  overflow: auto !important;
  padding: 16px 16px 0;

  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 16px;
    width: 100%;
    position: sticky;

    > .header-title {
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      color: $grey900;
    }
  }

  .invite-email-form {
    display: flex;
    align-items: center;
    gap: 14px;

    > button {
      min-width: 103px;
      padding: 18.5px 24px;
      border-radius: 4px;

      &.disabled {
        background-color: $blue200;
        color: $white;
      }
    }
  }

  .invite-alert {
    .invite-alert-gray {
      padding: 16px;
      background-color: $grey100;
      border-radius: 4px;

      > h6 {
        font-weight: 700;
      }
    }
  }

  .invite-list {
    position: relative;
    .invite-list-wrapper {
      .invite-list-item {
        padding: 16px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $grey100;

        .assessor-info {
          width: 44%;
          display: flex;
          gap: 10px;
          align-items: center;

          > .assessor-round {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $white;
            border: 1px solid $blue500;
            color: $blue500;

            .assessor-init {
              position: absolute;
              top: 50%;
              width: 100%;
              transform: translateY(-50%);
              text-align: center;
              font-weight: 700;
            }

            &.grey {
              border-color: $grey300;
              color: $grey300;
            }
            &.light-green {
              border-color: $green500;
              color: $green500;
            }
            &.orange {
              border-color: $red500;
              color: $red500;
            }
            &.indigo {
              border-color: $indigo600;
              color: $indigo600;
            }
            &.purple {
              border-color: $purple500;
              color: $purple500;
            }
            &.pink {
              border-color: $pink500;
              color: $pink500;
            }
            &.black {
              border-color: $grey900;
              color: $grey900;
            }
            &.green {
              border-color: $grey700;
              color: $grey700;
            }
            &.blue {
              border-color: $blue500;
              color: $blue500;
            }
          }

          > .assessor-name {
            .assessor {
              font-weight: 700;
            }

            .assessor-email {
              color: $grey500;
            }
          }
        }

        .grading-status {
          width: 28%;
        }

        .assessor-position {
          width: 28%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 4px;
          cursor: pointer;

          &.owner {
            cursor: default;
          }

          i {
            padding: 0 5.25px;
            font-size: 0;
          }
        }
      }
    }
  }
}

.evaluator-table-dropdown {
  position: absolute;
  right: 50px;
  top: calc(var(--dropdown-top) + 36px);
  background-color: white;

  > ul.evaluator-table-dropdown-list {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 6px 0;

    > li.evaluator-table-dropdown-list-item {
      cursor: pointer;
      width: 167px;
      height: 48px;
      display: inline-flex;
      align-items: center;
      padding: 0 16px;

      &:hover {
        background-color: $grey100;
      }

      > svg {
        margin-right: 4px;
      }
    }
  }
}

.modals-assessments-create-assessment-done,
.modals-assessments-confirm-delete {
  width: 90vw;
  height: 90vh;
  max-width: 312px;
  max-height: 188px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .title {
    > .title-main {
      font-weight: bold;
      margin-bottom: 8px;
    }

    > .title-sub {
      // font-size: 14px;
      // line-height: 20.3px;
      color: $grey500;
    }
  }
}

.modals-common-loading {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
  }
}

.modals-excel-loading {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px;
}

.modals-assessments-video-test,
.modals-assessments-essay-test,
.modals-assessments-multiple-choice-test,
.modals-assessments-file-upload-test,
.modals-assessments-subjective-tests-spreadsheet {
  > .header {
    background-color: $black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 40px;

    > .header-title {
      // font-size: 20px;
      font-weight: bold;
      color: white;
    }

    > .header-close {
      cursor: pointer;
    }
  }

  > .form {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    width: 720px;

    &.spreadsheet {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 40px;
    }

    > .form-title {
      // font-size: 28px;
      font-weight: bold;
      margin-bottom: 40px;
    }

    > .form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      > .form-header-title {
        // font-size: 28px;
        font-weight: bold;
      }

      > .form-header-submit {
        height: 56px;
        padding: 0 40px;
        border-radius: 36px;
      }
    }

    > .form-setting {
      display: flex;
      gap: 40px;
      // margin-bottom: 40px;
    }

    > .form-video-length,
    > .form-limit-time {
      margin-bottom: 40px;
    }

    > .tox-tinymce {
      margin-bottom: 40px;
    }

    > .form-guide-contents {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      gap: 16px;
      width: 100%;
      margin-bottom: 40px;

      > .form-guide-title {
        font-weight: 700;
      }

      > .tox-tinymce {
        width: 100%;
      }
    }

    > .form-submit {
      margin: 0 auto;
      width: 460px;
      height: 56px;
      border-radius: 36px;
    }

    > .form-answers {
      margin-bottom: 40px;

      > .form-answers-title {
        // font-size: 18px;
        font-weight: bold;
        margin-bottom: 24px;
      }

      > ul.form-answers-list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;

        > li.form-answers-list-item {
          > .form-answers-list-item-container {
            display: flex;
            align-items: center;
            width: 100%;

            > .MuiRadio-root {
            }

            > .form-answers-list-item-container-input {
              flex: 1;
              display: inline-flex;
              // height: 56px;
              margin-left: 6px;
              margin-right: 16px;
            }

            > .form-answers-list-item-container-close {
              width: 36px;
              height: 36px;
              display: inline-flex;
              justify-content: center;
              align-items: center;

              &::after {
                content: '';
                cursor: pointer;
                width: 21px;
                height: 21px;
                background-color: black;
                mask-image: url('/assets/images/icon/close-assessments-test.svg');
                mask-repeat: no-repeat;
              }

              &.disabled {
                &::after {
                  cursor: default;
                  background-color: $grey300;
                }
              }
            }
          }
        }
      }

      > .form-answers-add {
        padding: 0 19px;
        height: 39px;
        border-radius: 100px;
        color: white;

        &::before {
          content: '';
          margin-right: 11px;
          width: 12px;
          height: 12px;
          mask-image: url('/assets/images/icon/add-assessments.svg');
          background-color: white;
          mask-repeat: no-repeat;
        }
      }
    }

    .modal-video-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      margin-bottom: 40px;
      > .sub {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 16px;
        width: 100%;
        .sub-title {
          > h6 {
            font-weight: 700;
            color: $grey900;
          }
        }
        .intro-input {
          width: 100%;
          display: flex;
          gap: 24px;
          flex: 1 0 0;

          .input-box {
            width: 50%;
            position: relative;
            height: 200px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            .input-video-label {
              display: block;
              width: 100%;
              height: 200px;
              margin: 0 auto;
              cursor: pointer;
              background-color: $grey100;
              border-radius: 4px;

              &:hover {
                background-color: $grey50;
              }

              &.disable {
                cursor: default;
                background-color: $grey50;
                color: $grey500;
                svg > path {
                  fill: $grey500;
                }
              }

              .label-inner {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                > svg {
                  margin-bottom: 16px;
                }

                > p {
                  text-align: center;
                  white-space: pre-wrap;
                }
                > span {
                  margin-top: 20px;
                }
              }
            }
            .input-video {
              appearance: none;
              background-color: initial;
              cursor: default;
              align-items: baseline;
              color: inherit;
              text-overflow: ellipsis;
              white-space: pre;
              text-align: start !important;
              padding: initial;
              border: initial;
              overflow: hidden !important;
            }

            .delete-box {
              display: flex;
              width: 100%;
              padding: 8px 12px;
              align-items: center;
              justify-content: center;
              border: 1px solid $grey300;
              background-color: $white;
              border-radius: 4px;
              transition: all 0.2s;
              cursor: pointer;

              &:hover {
                background-color: $grey100;
              }

              &.disable {
                cursor: default;
                svg > path {
                  fill: $grey300;
                }
                .delete-btn {
                  font-weight: 500;
                  color: $grey300;
                  margin-left: 8px;
                }
                &:hover {
                  background-color: $white;
                }
              }

              .delete-btn {
                font-weight: 500;
                color: $grey900;
                margin-left: 8px;
              }
            }
          }

          .video-preview-box {
            width: 50%;
            max-height: 200px;
            min-height: 200px;

            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;

            .background-grey100 {
              background-color: $grey100;
            }

            video {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .form-spreadsheet {
    position: relative;

    > .cover {
      position: absolute;
      top: 0;
      left: 0;
      height: 64px;
      width: 100%;
      background-color: white;
      z-index: 10;
    }

    #docs-header-container {
      visibility: hidden;
    }

    #docs-menubars {
      visibility: hidden;
    }
  }
}

// .modals-assessments-essay-test {
//     >.header {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         background-color: black;
//         height: 80px;
//         padding: 0 40px;

//         >.header-title {
//             font-size: 20px;
//             font-weight: bold;
//             color: white;
//         }

//         >.header-close {
//             cursor: pointer;
//         }
//     }

//     >.form {
//         width: 720px;
//         margin: 40px auto;

//         >.form-title {
//             font-size: 28px;
//             font-weight: bold;
//             margin-bottom: 40px;
//         }

//         >.form-limit-time {
//             margin-bottom: 40px;
//         }

//         >.tox-tinymce {
//             margin-bottom: 40px;
//         }

//         >.form-submit {
//             width: 460px;
//             height: 56px;
//             border-radius: 36px;
//             margin: 0 auto;
//         }
//     }
// }

.modals-assessments-invite-email-bulk {
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    height: 80px;
    padding: 0 40px;

    > .header-title {
      // font-size: 20px;
      font-weight: bold;
      color: white;
    }

    > .header-close {
      width: 36px;
      height: 36px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: '';
        width: 21px;
        height: 21px;
        mask-image: url('/assets/images/icon/close-modal.svg');
        mask-position: center;
        mask-size: contain;
        background-color: white;
      }
    }
  }

  > .form {
    display: flex;
    justify-content: space-between;
    padding: 40px;

    > .form-guide {
      > .form-guide-title {
        // font-size: 28px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      > .form-guide-description {
        // font-size: 16px;
        color: $grey900;
        margin-bottom: 24px;
      }

      > .form-guide-buttons {
        display: flex;

        > button {
          padding: 0 16px;
          height: 39px;
          border-radius: 100px;

          &:first-child {
            margin-right: 16px;
          }

          &::before {
            content: url('/assets/images/icon/download.svg');
            margin-right: 11px;
            position: relative;
            top: 2px;
          }
        }
      }
    }

    > .form-upload {
      cursor: pointer;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 660px;
      height: 200px;
      border-radius: 16px;
      background-color: $grey100;
      gap: 16px;

      &:hover {
        background-color: rgba($grey100, 0.6);
      }

      &:active {
        background-color: rgba($grey100, 0.8);
      }
    }
  }

  > .candidates {
    padding: 0 40px 40px 40px;

    > .candidates-title {
      // font-size: 28px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    > .error-msg {
      display: flex;
      gap: 4px;
      margin-bottom: 8px;
      align-items: center;
    }

    .table {
      margin-bottom: 40px;

      .MuiTableCell-root {
        &.warning {
          color: $red500;
          font-weight: bold;
        }
      }
      .empty-table {
        // font-size: 16px;
        font-weight: bold;

        &::before {
          content: url('/assets/images/icon/info.svg');
          margin-right: 10px;
          position: relative;
          top: 5px;
        }
      }
    }

    button.submit {
      width: 460px;
      height: 56px;
      border-radius: 16px;
      margin: 0 auto;
      // font-size: 16px;
    }
  }
}

.modals-assessments-edit-invite-candidate,
.modals-assessments-edit-notice-pass-assessment,
.modals-assessments-edit-notice-recruitment-rejection,
.modals-assessments-edit-notice-under-review {
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    height: 80px;
    padding: 0 40px;

    > .header-title {
      // font-size: 20px;
      height: bold;
      color: white;
    }

    > .header-close {
      width: 36px;
      height: 36px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: '';
        width: 21px;
        height: 21px;
        mask-image: url('/assets/images/icon/close-modal.svg');
        mask-position: center;
        mask-size: contain;
        background-color: white;
      }
    }
  }

  > .title {
    // font-size: 28px;
    font-weight: bold;
    margin: 40px;
  }

  > .form {
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;
    gap: 40px;

    > .form-editor {
      flex: 1;

      > .form-editor-title {
        // font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      > .form-editor-section {
      }
    }

    > .form-preview {
      flex: 1;

      > .form-preview-title {
        // font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      > .form-preview-section {
        border: 1px solid $grey300;
        border-radius: 8px;
        min-height: 400px;
        padding: 40px;

        > img {
          max-width: calc(100% - 80px);
          max-height: 60px;
          margin-bottom: 16px;
        }

        > div {
          line-height: 23.2px;

          &.edit-text {
            margin-bottom: 32px;
          }

          &.small {
            // font-size: 12px;
            color: $grey900;

            &:not(&:last-child) {
              margin-top: 16px;
              margin-bottom: 32px;
            }

            > span {
              text-decoration: underline;
            }
          }
        }

        p {
          // font-size: 16px;
          margin-bottom: 16px;
          // line-height: 23.2px;
        }

        .go-to-assessment {
          background-color: $blue500;
          padding: 0 24px;
          height: 56px;
          border-radius: 36px;
          // font-size: 16px;
          color: white;
          margin-bottom: 32px;
          cursor: default;
        }

        a {
          cursor: default;
          margin-bottom: 16px;
        }
      }
    }
  }

  > button.save {
    width: 460px;
    height: 56px;
    border-radius: 36px;
    margin: 0 auto 24px auto;
  }

  > button.reset {
    width: 460px;
    height: 56px;
    border-radius: 36px;
    margin: 0 auto 40px auto;
  }

  .progress-circle {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;

    > .container {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 120px;
      border-radius: 15px;
      background-color: $white;
    }
  }
}

.modals-common-warning-modal {
  height: 188px;
  width: 272px;
  > .title-sub {
    height: 132px;
    padding: 16px;
    color: $grey900;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
  }
}

.modals-common-alert-modal {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  height: 188px;
  justify-content: center;
  width: 312px;
  > .title {
    padding: 16px;

    > .title-main {
      // font-size: 16px;
      font-weight: bold;
      // line-height: 23.2px;
      margin-bottom: 4px;
      text-align: center;
      color: $grey900;
    }

    > .title-sub {
      // font-size: 14px;
      color: $grey700;
      // line-height: 23.2px;
      text-align: center;
    }
  }
}
.modals-assessments-view-answer-library {
  > .header {
    background-color: black;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 40px;

    > .header-title {
      font-weight: bold;
      color: white;
    }

    > .header-close {
      cursor: pointer;
    }
  }
  > .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    margin-bottom: 40px;

    > .title {
      display: flex;
      width: 100%;
      font-weight: 700;
      align-items: center;
      color: $black;
    }
    > .left {
      display: flex;
      align-items: center;
      > .score {
        display: flex;
        white-space: nowrap;
        align-items: flex-end;
        > p {
          font-weight: 700;
          margin-right: 10px;
        }
      }
      .container {
        display: inline-flex;
        margin-right: 26px;
        position: relative;

        > .number-scoring {
          > span {
            font-weight: 500;
          }

          .number {
            color: $blue500;
            margin-right: 4px;
          }
        }

        > .stars {
          display: inline-flex;
          position: relative;
          z-index: 1;

          &.preset-hide {
            > .star {
              &::after {
                display: none !important;
              }
            }
          }

          > .star {
            width: 24px;
            height: 24px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;

            &.fill {
              &::before {
                content: url('/assets/images/icon/star-fill.svg');
                height: 24px;
                z-index: 2;
              }
            }

            &.empty {
              &::before {
                content: url('/assets/images/icon/star.svg');
                height: 24px;
                z-index: 2;
              }

              &.preset-fill {
                &::after {
                  z-index: 3 !important;
                }
              }
            }

            &.preset-fill {
              &::after {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                content: url('/assets/images/icon/star-preset.svg');
                height: 24px;
              }
            }

            &.preset-empty {
              &::after {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                // content: url('/assets/images/icon/star-white-fill.svg');
                height: 24px;
              }
            }
          }
        }
        // >.stars {
        //     display: inline-flex;
        //     position: relative;
        //     z-index: 1;

        //     >.star {
        //         width: 24px;
        //         height: 24px;
        //         display: inline-flex;
        //         justify-content: center;
        //         // align-items: center;

        //         &.fill {
        //             &::before {
        //                 content: url('/assets/images/icon/star-fill.svg');
        //                 font-size: 0;
        //             }
        //         }

        //         &.empty {
        //             &::before {
        //                 content: url('/assets/images/icon/star.svg');
        //                 font-size: 0;
        //             }
        //         }
        //     }

        //     &.preset {
        //         position: absolute;
        //         top: 0;
        //         left: 0;
        //         width: 100%;

        //         >.star {
        //             &.fill {
        //                 &::before {
        //                     content: url('/assets/images/icon/star-preset.svg');
        //                     font-size: 0;
        //                 }
        //             }
        //             &.empty {
        //                 &::before {
        //                     content: url('/assets/images/icon/star-white-fill.svg');
        //                     font-size: 0;
        //                 }
        //             }
        //         }
        //     }
        // }
      }
      > .submit {
        width: 160px;
        height: 56px;
        border-radius: 16px;
        font-weight: 500;
        transition: all 0.2s;
        cursor: pointer;
        &.active {
          background-color: $blue500;
          color: $white;
        }

        &.disabled {
          cursor: default;
          color: $grey900;
          background: $grey100;
        }
      }
    }
  }
  > .question-tab-nav {
    width: 100%;
    padding: 0 40px;
    margin-bottom: 24px;

    > ul {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $grey100;
      > li {
        cursor: pointer;
        position: relative;
        padding: 0 24px 20px;
        font-weight: 700;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 0;
          height: 2px;
          left: 50%;
          transform: translateX(-50%);
          display: inline-block;
          background-color: $blue500;
          transition: all 0.2s;
        }
        &.active {
          color: $blue500;
          &::after {
            width: 20px;
          }
        }
        &:hover {
          &::after {
            width: 20px;
          }
        }
      }
    }
  }
  > .contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 40px;
    gap: 40px;
    width: 100%;
    margin-bottom: 40px;
    > .content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 40px;
      > .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 40px;
        width: calc(50% - 20px);
        > .question-detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          width: 100%;
          > .question-number {
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $black;
          }
          > .question {
            font-style: normal;
            font-weight: 500;
            display: flex;
            align-items: center;
            color: $grey900;
            word-break: keep-all;
            word-wrap: break-word;

            pre {
              line-height: 1.8 !important;

              * {
                all: revert;
                margin: 0 !important;
              }

              // 테이블 테그
              .tg {
                border-collapse: collapse;
                border-spacing: 0;
                width: 100%;

                th {
                  border-color: black;
                  border-style: solid;
                  border-width: 1px;
                  font-size: 14px;
                  font-weight: normal;
                  overflow: hidden;
                  padding: 10px 5px;
                  word-break: normal;
                }

                td {
                  border-color: black;
                  border-style: solid;
                  border-width: 1px;
                  font-size: 14px;
                  overflow: hidden;
                  padding: 10px 5px;
                  word-break: normal;
                }

                .tg-th {
                  background-color: #6980c4;
                  color: #ffffff;
                  font-weight: bold;
                  text-align: center;
                  vertical-align: top;
                }

                .tg-td {
                  text-align: center;
                  vertical-align: top;
                }

                .tg-td-last {
                  background-color: #ffcc67;
                  text-align: center;
                  vertical-align: top;
                  text-decoration: underline;
                  font-weight: bold;
                }
              }

              .rect-border {
                border: 1px solid $grey300;
                padding: 8px 10px;

                .center {
                  padding: 20px 0;
                }
              }

              .center {
                text-align: center;
              }
            }
          }
        }
        > .answer-detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          width: 100%;
          > .title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            display: flex;
            align-items: center;
            color: $grey900;
          }
          > .answer {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 16px;
            gap: 10px;
            width: 100%;
            height: 128px;
            border: 1px solid $grey300;
            border-radius: 4px;
            font-weight: 400;
            color: $grey900;
            overflow-y: auto;
            word-break: keep-all;
            word-wrap: break-word;

            > pre {
              * {
                line-height: 1.8 !important;
              }
            }
          }
          > .answer-description {
            background-color: $grey100;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            &.video {
              height: 371px;
            }

            &.image {
              height: 400px;
              background-color: #f8f7f2;
            }

            > video {
              width: 100%;
              height: 100%;
            }

            > img {
              display: block;
              width: auto;
              height: 100%;
            }
          }
        }
      }
      > .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 40px;
        width: calc(50% - 20px);
        > .guid-detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          width: 100%;
          > .title {
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
            margin-bottom: 10px;
          }
          > .guid {
            font-style: normal;
            font-weight: 500;
            display: flex;
            align-items: center;
            color: $grey900;
            word-break: keep-all;
            word-wrap: break-word;

            > pre {
              line-height: 1.8 !important;
            }
          }
        }
        > .estimate {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;

          width: 100%;
          > .estimate-title {
            font-weight: bold;
          }

          > .estimate-score-flex {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.mb40 {
              margin-bottom: 40px;
            }

            > .estimate-score {
              position: relative;

              &.flex {
                display: flex;
                align-items: flex-end;
                gap: 8px;

                > .estimate-score-number {
                  font-weight: 500;
                  > span {
                    font-weight: 500;

                    &.number {
                      color: $blue500;
                    }
                  }
                }
              }

              > .estimate-score-contents {
                &.preset-hide {
                  > .star {
                    &::after {
                      display: none !important;
                    }
                  }
                }

                > .star {
                  width: 24px;
                  height: 24px;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  z-index: 1;

                  &.empty {
                    > div {
                      cursor: pointer;
                      content: '';
                      width: 24px;
                      height: 24px;
                      mask-image: url('/assets/images/icon/star.svg');
                      mask-repeat: no-repeat;
                      mask-position: center;
                      mask-size: contain;
                      background-color: $grey300;
                      z-index: 2;

                      &:hover {
                        background-color: $blue700;
                      }
                    }

                    &.preset-fill {
                      &::after {
                        cursor: pointer;
                        z-index: 3 !important;
                      }
                    }
                  }

                  &.fill {
                    > div {
                      cursor: pointer;
                      content: '';
                      width: 24px;
                      height: 24px;
                      mask-image: url('/assets/images/icon/star-fill.svg');
                      mask-repeat: no-repeat;
                      mask-position: center;
                      mask-size: contain;
                      background-color: $blue500;
                      z-index: 2;

                      &:hover {
                        background-color: $blue700;
                      }
                    }

                    &.preset-fill {
                      &::after {
                        z-index: 1 !important;
                      }
                    }
                  }

                  &.preset-fill {
                    &::after {
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: 1;
                      content: url('/assets/images/icon/star-preset.svg');
                      font-size: 0;
                    }
                  }

                  &.preset-empty {
                    &::after {
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: 1;
                      // content: url('/assets/images/icon/star-white-fill.svg');
                      font-size: 0;
                    }
                  }
                }
              }

              > .stars {
                display: inline-flex;
                position: relative;
                z-index: 1;

                &.preset-hide {
                  > .star {
                    &::after {
                      display: none;
                    }
                  }
                }

                > .star {
                  width: 24px;
                  height: 24px;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;

                  &.fill {
                    &::before {
                      content: url('/assets/images/icon/star-fill.svg');
                      font-size: 0;
                    }
                  }

                  &.empty {
                    &::before {
                      content: url('/assets/images/icon/star.svg');
                      font-size: 0;
                    }

                    &.preset-fill {
                      &::after {
                        z-index: 3 !important;
                      }
                    }
                  }

                  &.preset-fill {
                    &::after {
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: -1;
                      content: url('/assets/images/icon/star-preset.svg');
                      font-size: 0;
                    }
                  }

                  &.preset-empty {
                    &::after {
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: -1;
                      // content: url('/assets/images/icon/star-white-fill.svg');
                      font-size: 0;
                    }
                  }
                }
              }

              // .preset-estimate-score-contents {
              //     position: absolute;
              //     top: 0;
              //     left: 0;
              //     width: 100%;

              //     >.star {
              //         width: 24px;
              //         height: 24px;
              //         display: inline-flex;
              //         justify-content: center;
              //         align-items: center;
              //         position: relative;
              //         z-index: 1;

              //         &.fill {
              //             &::before {
              //                 content: url('/assets/images/icon/star-preset.svg');
              //                 font-size: 0;
              //             }
              //         }
              //         &.empty {
              //             &::before {
              //                 content: url('/assets/images/icon/star-white-fill.svg');
              //                 font-size: 0;
              //             }
              //         }
              //     }
              // }
            }

            > .estimate-score-number {
              * {
                font-size: 16px;
                font-weight: 500;

                span {
                  display: inline-block;
                }
              }

              .score-title {
                margin-right: 8px;
              }

              .score-number {
                color: $blue500;
                min-width: 50px;
                text-align: right;

                &.preset {
                  color: $grey400;
                }
              }
            }
          }

          > .divide {
            width: 100%;
            height: 1px;
            background-color: $grey100;
            margin: 8px 0;
          }

          > .estimate-comment {
            width: 100%;
            > textarea {
              width: 100%;
              max-height: 150px !important;
              border: 1px solid $grey300;
              border-radius: 4px;
              padding: 16px;
              overflow: visible !important;
            }
          }
        }
      }

      .admin-preset-text-edit {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;

        > .preset-score {
          margin-bottom: 8px;

          .preset-score-title {
            font-weight: 700;
            margin-bottom: 4px;
          }

          .preset-score-input {
            display: inline-block;
            padding: 16px;
            border: 1px solid $grey300;
            border-radius: 4px;
          }
        }

        > .edit-text {
          .edit-text-title {
            font-weight: 700;
            margin-bottom: 4px;
          }

          .edit-text-textarea {
            display: inline-block;
            width: 100%;
            padding: 16px;
            border: 1px solid $grey300;
            border-radius: 4px;
            max-height: 250px;
          }
        }
      }
    }

    > .code-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 40px;
      height: calc(100vh - 104px - 96px - 71px - 40px);
      // height: 100vh;

      > .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 18px;
        width: calc(50% - 20px);
        height: 100%;
        > .question-detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          width: 100%;
          height: 30%;
          > .question-number {
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $black;
          }
          > .question {
            font-style: normal;
            font-weight: 500;
            color: $grey900;
            word-break: keep-all;
            word-wrap: break-word;
            background-color: $grey50;
            height: 17vh;
            overflow-y: auto;
            > pre {
              padding: 16px;
              line-height: 1.8 !important;

              * {
                all: revert;
                margin: 0 !important;
              }
            }
          }
        }
        > .guid-detail {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          height: 30%;
          > .title {
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
            height: 10%;
          }
          > .guid {
            font-style: normal;
            font-weight: 500;
            height: 90%;
          }
        }
        > .estimate {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          width: 100%;
          height: 40%;
          > .estimate-title {
            font-weight: bold;
          }

          > .estimate-score {
            position: relative;
            > .estimate-score-contents {
              &.preset-hide {
                > .star {
                  &::after {
                    display: none !important;
                  }
                }
              }

              > .star {
                width: 24px;
                height: 24px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 1;

                &.empty {
                  > div {
                    cursor: pointer;
                    content: '';
                    width: 24px;
                    height: 24px;
                    mask-image: url('/assets/images/icon/star.svg');
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: contain;
                    background-color: $grey300;
                    z-index: 2;

                    &:hover {
                      background-color: $blue700;
                    }
                  }

                  &.preset-fill {
                    &::after {
                      cursor: pointer;
                      z-index: 3 !important;
                    }
                  }
                }

                &.fill {
                  > div {
                    cursor: pointer;
                    content: '';
                    width: 24px;
                    height: 24px;
                    mask-image: url('/assets/images/icon/star-fill.svg');
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: contain;
                    background-color: $blue500;
                    z-index: 2;

                    &:hover {
                      background-color: $blue700;
                    }
                  }

                  &.preset-fill {
                    &::after {
                      z-index: 1 !important;
                    }
                  }
                }

                &.preset-fill {
                  &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    content: url('/assets/images/icon/star-preset.svg');
                    font-size: 0;
                  }
                }

                &.preset-empty {
                  &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    // content: url('/assets/images/icon/star-white-fill.svg');
                    font-size: 0;
                  }
                }
              }
              // >.star {
              //     width: 24px;
              //     height: 24px;
              //     display: inline-flex;
              //     justify-content: center;
              //     align-items: center;
              //     position: relative;
              //     z-index: 1;

              //     &.empty {
              //         >div {
              //             cursor: pointer;
              //             content: '';
              //             width: 24px;
              //             height: 24px;
              //             mask-image: url('/assets/images/icon/star.svg');
              //             mask-repeat:no-repeat;
              //             mask-position: center;
              //             mask-size: contain;
              //             background-color: $grey300;

              //             &:hover {
              //                 background-color: $blue700;
              //             }
              //         }
              //     }

              //     &.fill {
              //         >div {
              //             cursor: pointer;
              //             content: '';
              //             width: 24px;
              //             height: 24px;
              //             mask-image: url('/assets/images/icon/star-fill.svg');
              //             mask-repeat:no-repeat;
              //             mask-position: center;
              //             mask-size: contain;
              //             background-color: $blue500;

              //             &:hover {
              //                 background-color: $blue700;
              //             }
              //         }
              //     }
              // }
            }

            // .preset-estimate-score-contents {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;

            //     >.star {
            //         width: 24px;
            //         height: 24px;
            //         display: inline-flex;
            //         justify-content: center;
            //         align-items: center;
            //         position: relative;
            //         z-index: 1;

            //         &.fill {
            //             &::before {
            //                 content: url('/assets/images/icon/star-preset.svg');
            //                 font-size: 0;
            //             }
            //         }
            //         &.empty {
            //             &::before {
            //                 content: url('/assets/images/icon/star-white-fill.svg');
            //                 font-size: 0;
            //             }
            //         }
            //     }
            // }
          }

          > .estimate-comment {
            width: 100%;
            height: 100%;
            > textarea {
              width: 100%;
              height: 100% !important;
              border: 1px solid $grey300;
              border-radius: 4px;
              padding: 16px;
            }
          }
        }
      }
      > .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 40px;
        width: calc(50% - 20px);
        height: 100%;

        > .answer-result-detail {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          > .answer-result-input {
            position: relative;
            height: 60%;
          }
          > .answer-result-title {
            padding: 8px 16px;
            background-color: $grey800;
            height: 9%;
            > h6 {
              font-weight: 700;
              color: $white;
            }
          }
          > .answer-result-content {
            padding: 8px 16px;
            background-color: $grey900;
            height: 31%;
            overflow-y: auto;
            > pre {
              color: $white;
            }
          }
        }
      }
    }

    > .front-code-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 40px;
      height: calc(100vh - 104px - 96px - 71px - 40px);
      // height: 100vh;

      > .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 18px;
        width: calc(50% - 20px);
        height: 100%;
        > .question-detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          width: 100%;
          height: 30%;
          > .question-number {
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $black;
          }
          > .question {
            font-style: normal;
            font-weight: 500;
            color: $grey900;
            word-break: keep-all;
            word-wrap: break-word;
            background-color: $grey50;
            height: 17vh;
            overflow-y: auto;
            > pre {
              padding: 16px;
              line-height: 1.8 !important;

              * {
                all: revert;
                margin: 0 !important;
              }
            }
          }
        }
        > .guid-detail {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          height: 30%;
          > .title {
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
            height: 10%;
          }
          > .guid {
            font-style: normal;
            font-weight: 500;
            height: 90%;
          }
        }
        > .estimate {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          width: 100%;
          height: 40%;
          > .estimate-title {
            font-weight: bold;
          }

          > .estimate-score {
            position: relative;
            > .estimate-score-contents {
              &.preset-hide {
                > .star {
                  &::after {
                    display: none !important;
                  }
                }
              }

              > .star {
                width: 24px;
                height: 24px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 1;

                &.empty {
                  > div {
                    cursor: pointer;
                    content: '';
                    width: 24px;
                    height: 24px;
                    mask-image: url('/assets/images/icon/star.svg');
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: contain;
                    background-color: $grey300;
                    z-index: 2;

                    &:hover {
                      background-color: $blue700;
                    }
                  }

                  &.preset-fill {
                    &::after {
                      cursor: pointer;
                      z-index: 3 !important;
                    }
                  }
                }

                &.fill {
                  > div {
                    cursor: pointer;
                    content: '';
                    width: 24px;
                    height: 24px;
                    mask-image: url('/assets/images/icon/star-fill.svg');
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: contain;
                    background-color: $blue500;
                    z-index: 2;

                    &:hover {
                      background-color: $blue700;
                    }
                  }

                  &.preset-fill {
                    &::after {
                      z-index: 1 !important;
                    }
                  }
                }

                &.preset-fill {
                  &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    content: url('/assets/images/icon/star-preset.svg');
                    font-size: 0;
                  }
                }

                &.preset-empty {
                  &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    // content: url('/assets/images/icon/star-white-fill.svg');
                    font-size: 0;
                  }
                }
              }
              // >.star {
              //     width: 24px;
              //     height: 24px;
              //     display: inline-flex;
              //     justify-content: center;
              //     align-items: center;
              //     position: relative;
              //     z-index: 1;

              //     &.empty {
              //         >div {
              //             cursor: pointer;
              //             content: '';
              //             width: 24px;
              //             height: 24px;
              //             mask-image: url('/assets/images/icon/star.svg');
              //             mask-repeat:no-repeat;
              //             mask-position: center;
              //             mask-size: contain;
              //             background-color: $grey300;

              //             &:hover {
              //                 background-color: $blue700;
              //             }
              //         }
              //     }

              //     &.fill {
              //         >div {
              //             cursor: pointer;
              //             content: '';
              //             width: 24px;
              //             height: 24px;
              //             mask-image: url('/assets/images/icon/star-fill.svg');
              //             mask-repeat:no-repeat;
              //             mask-position: center;
              //             mask-size: contain;
              //             background-color: $blue500;

              //             &:hover {
              //                 background-color: $blue700;
              //             }
              //         }
              //     }
              // }
            }

            // .preset-estimate-score-contents {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;

            //     >.star {
            //         width: 24px;
            //         height: 24px;
            //         display: inline-flex;
            //         justify-content: center;
            //         align-items: center;
            //         position: relative;
            //         z-index: 1;

            //         &.fill {
            //             &::before {
            //                 content: url('/assets/images/icon/star-preset.svg');
            //                 font-size: 0;
            //             }
            //         }
            //         &.empty {
            //             &::before {
            //                 content: url('/assets/images/icon/star-white-fill.svg');
            //                 font-size: 0;
            //             }
            //         }
            //     }
            // }
          }

          > .estimate-comment {
            width: 100%;
            height: 100%;
            > textarea {
              width: 100%;
              height: 100% !important;
              border: 1px solid $grey300;
              border-radius: 4px;
              padding: 16px;
            }
          }
        }
      }
      > .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 40px;
        width: calc(50% - 20px);
        height: 100%;

        > .answer-result-detail {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          > .tab {
            ul {
              display: flex;
              align-items: center;

              li {
                padding: 10px 24px;
                font-weight: 700;
                cursor: pointer;
                position: relative;
                transition: all 0.2s;

                &:hover {
                  color: $blue500;
                }

                &::after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  display: block;
                  width: 0;
                  height: 2px;
                  background-color: $blue500;
                  transition: all 0.2s;
                }

                &.active {
                  color: $blue500;

                  &::after {
                    width: 45px;
                  }
                }
              }
            }
          }
          > .answer-result-input {
            position: relative;
            height: calc((100% - 56.2px - 36px) * 0.5);

            .editor-input {
              height: 100%;
            }
          }
          > .answer-result-title {
            padding: 8px 16px;
            background-color: $grey800;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: auto;

            > h6 {
              font-weight: 700;
              color: $white;
            }
            > button {
              display: inline-block;
              padding: 10.5px 12px;
              border-radius: 4px;
              display: flex;
              align-items: center;

              > svg {
                margin-left: 10px;
              }
            }
          }
          > .answer-result-content {
            background-color: transparent;
            padding: 0;
            height: calc((100% - 56.2px - 36px) * 0.5);
            overflow-y: auto;
            > #iframe-preview {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    > hr {
      width: 100%;
      border: 0;
      height: 1px;
      background-color: $grey200;
    }
    > .submit {
      width: 460px;
      height: 56px;
      border-radius: 16px;
      margin: 0 auto;
    }
  }
}

.modals-assessments-view-answer-video,
.modals-assessments-view-answer-essay,
.modals-assessments-view-answer-multiple-choice,
.modals-assessments-view-answer-file-upload,
.modals-assessments-view-answer-spreadsheet,
.modals-assessments-view-answer-code {
  padding-bottom: 150px;
  > .header {
    background-color: black;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 40px;

    > .header-title {
      // font-size: 20px;
      font-weight: bold;
      color: white;
    }

    > .header-close {
      cursor: pointer;
    }
  }

  > .form {
    width: 660px;
    margin: 0 auto;

    > .question {
      margin-bottom: 40px;

      > .question-title {
        // font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      > .question-description {
        color: $grey900;
        // line-height: 23.2px;

        > p {
          * {
            all: revert;
            margin: 0 !important;
            line-height: 1.8;
          }
        }
      }
      > .video {
        margin-top: 8px;
        > .video-question {
          display: block;
          width: 100%;
        }
      }
    }

    > .answer {
      margin-bottom: 40px;

      > .answer-title {
        // font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      > .answer-description {
        width: 100%;

        &.video {
          height: 371px;
          background-color: $grey100;
          display: flex;
          justify-content: center;
          align-items: center;

          > video {
            max-width: 100%;
            max-height: 100%;
          }
        }

        &.essay {
          min-height: 200px;
          padding: 16px;
          border: 1px solid $grey300;
          border-radius: 4px;
          color: $grey900;
          line-height: 1.4;
        }

        &.multiple-choice {
          > ul.answer-description-list {
            > li.answer-description-list-item {
              &:not(&:last-child) {
                margin-bottom: 16px;
              }

              > .container {
                display: flex;
                align-items: center;

                > .radio-button {
                  color: $blue500;
                  margin-right: 4px;
                }

                > .text {
                  flex: 1;
                  padding: 16px;
                  background-color: $grey100;
                  border: 1px solid $grey300;
                  border-radius: 4px;
                }
              }

              &.answer {
                > .container {
                  > .text {
                    border: 1px solid $blue500;
                  }
                }
              }
            }
          }
        }

        &.file-upload {
          > button {
            padding: 0 16px;
            height: 39px;
            border-radius: 100px;

            &::before {
              content: url('/assets/images/icon/download.svg');
              margin-right: 11px;
            }
          }
        }
      }
    }

    > .currect {
      margin-bottom: 40px;

      > .answer-title {
        // font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      > .answer-description {
        width: 100%;

        &.multiple-choice {
          > ul.answer-description-list {
            > li.answer-description-list-item {
              &:not(&:last-child) {
                margin-bottom: 16px;
              }

              > .container {
                display: flex;
                align-items: center;

                > .radio-button {
                  color: $blue500;
                  margin-right: 4px;
                }

                > .text {
                  flex: 1;
                  padding: 16px;
                  background-color: $grey100;
                  border: 1px solid $grey300;
                  border-radius: 4px;
                }
              }

              &.answer {
                > .container {
                  > .text {
                    border: 1px solid $blue500;
                  }
                }
              }
            }
          }
        }

        &.file-upload {
          > button {
            padding: 0 16px;
            height: 39px;
            border-radius: 100px;

            &::before {
              content: url('/assets/images/icon/download.svg');
              margin-right: 11px;
            }
          }
        }
      }
    }

    > .answer-guide {
      margin-bottom: 40px;

      > .answer-guide-title {
        font-weight: bold;
        margin-bottom: 16px;
      }

      > .answer-guide-description {
        width: 100%;
        min-height: 200px;
        padding: 16px;
        border: 1px solid $grey300;
        border-radius: 4px;
        overflow: auto;

        * {
          all: revert;
          margin: 0;
        }

        // &.essay {
        //     min-height: 200px;
        //     padding: 16px;
        //     border: 1px solid $grey300;
        //     border-radius: 4px;
        //     color: $grey900;
        // }
      }
    }

    > .estimate {
      margin-bottom: 40px;

      > .estimate-title {
        // font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      > .estimate-score-flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .estimate-score {
          margin-bottom: 10px;
          position: relative;

          > .estimate-score-contents {
            &.preset-hide {
              > .star {
                &::after {
                  display: none !important;
                }
              }
            }

            > .star {
              width: 24px;
              height: 24px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              position: relative;
              z-index: 1;

              &.empty {
                > div {
                  cursor: pointer;
                  content: '';
                  width: 24px;
                  height: 24px;
                  mask-image: url('/assets/images/icon/star.svg');
                  mask-repeat: no-repeat;
                  mask-position: center;
                  mask-size: contain;
                  background-color: $grey300;
                  z-index: 2;

                  &:hover {
                    background-color: $blue700;
                  }
                }

                &.preset-fill {
                  &::after {
                    cursor: pointer;
                    z-index: 3 !important;
                  }
                }
              }

              &.fill {
                > div {
                  cursor: pointer;
                  content: '';
                  width: 24px;
                  height: 24px;
                  mask-image: url('/assets/images/icon/star-fill.svg');
                  mask-repeat: no-repeat;
                  mask-position: center;
                  mask-size: contain;
                  background-color: $blue500;
                  z-index: 2;

                  &:hover {
                    background-color: $blue700;
                  }
                }

                &.preset-fill {
                  &::after {
                    z-index: 1 !important;
                  }
                }
              }

              &.preset-fill {
                &::after {
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  content: '';
                  // content: url('/assets/images/icon/star-preset.svg');
                  width: 24px;
                  height: 24px;
                  mask-image: url('/assets/images/icon/star-preset.svg');
                  mask-repeat: no-repeat;
                  mask-position: center;
                  mask-size: contain;
                  background-color: $orange400;
                  font-size: 0;
                }

                &:hover {
                  &::after {
                    background-color: $orange700;
                  }
                }
              }

              &.preset-empty {
                &::after {
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  // content: url('/assets/images/icon/star-white-fill.svg');
                  font-size: 0;
                }
              }
            }
          }

          // .preset-estimate-score-contents {
          //     position: absolute;
          //     top: 0;
          //     left: 0;
          //     width: 100%;

          //     >.star {
          //         width: 24px;
          //         height: 24px;
          //         display: inline-flex;
          //         justify-content: center;
          //         align-items: center;
          //         position: relative;
          //         z-index: 1;

          //         &.fill {
          //             &::before {
          //                 content: url('/assets/images/icon/star-preset.svg');
          //                 font-size: 0;
          //             }
          //         }
          //         &.empty {
          //             &::before {
          //                 content: url('/assets/images/icon/star-white-fill.svg');
          //                 font-size: 0;
          //             }
          //         }
          //     }
          // }
        }

        > .estimate-score-number {
          * {
            font-size: 16px;
            font-weight: 500;

            span {
              display: inline-block;
            }
          }

          .score-title {
            margin-right: 8px;
          }

          .score-number {
            color: $blue500;
            min-width: 50px;
            text-align: right;

            &.preset {
              color: $grey400;
            }
          }
        }
      }

      > .estimate-comment {
        width: 100%;
        > textarea {
          width: 100%;
          max-height: 150px !important;
          border: 1px solid $grey300;
          border-radius: 4px;
          padding: 16px;
          overflow: visible !important;
        }
      }
    }

    > .submit {
      width: 460px;
      height: 56px;
      border-radius: 16px;
      margin: 0 auto;
    }
  }
}
.modals-assessments-view-answer-spreadsheet {
  > .form {
    width: 100%;
    padding: 0 40px;
  }

  .form-spreadsheet {
    position: relative;

    > .cover {
      position: absolute;
      top: 0;
      left: 0;
      height: 64px;
      width: 100%;
      background-color: white;
      z-index: 10;
    }

    #docs-header-container {
      visibility: hidden;
    }

    #docs-menubars {
      visibility: hidden;
    }
  }
}

.modals-assessments-view-answer-video {
  > .form {
    width: 100%;
    padding: 0 40px;

    > .form-container {
      display: flex;
      gap: 40px;
      margin-bottom: 40px;

      > .form-left {
        width: 50%;

        > .question {
          margin-bottom: 40px;

          > .question-title {
            font-weight: bold;
            margin-bottom: 8px;
          }

          > .question-description {
            color: $grey900;

            > p {
              * {
                all: revert;
                margin: 0 !important;
                line-height: 1.8;
              }
            }
          }
          > .video {
            margin-top: 8px;
            > .video-question {
              display: block;
              width: 100%;
            }
          }
        }

        > .answer {
          margin-bottom: 0;
          > .answer-title {
            font-weight: bold;
            margin-bottom: 16px;
          }

          > .answer-description {
            width: 100%;

            &.video {
              height: 371px;
              background-color: $grey100;
              display: flex;
              justify-content: center;
              align-items: center;

              > video {
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
      }

      > .form-right {
        width: 50%;

        > .answer-guide {
          margin-bottom: 40px;

          > .answer-guide-title {
            font-weight: bold;
            margin-bottom: 16px;
          }

          > .answer-guide-description {
            width: 100%;

            * {
              all: revert;
              margin: 0;
            }
          }
        }

        > .answer-voice {
          margin-bottom: 40px;

          > .answer-voice-title {
            font-weight: bold;
            margin-bottom: 16px;
          }

          > .answer-voice-description {
            width: 100%;
            padding: 16px;
            max-height: 200px;
            border: 1px solid $grey300;
            border-radius: 4px;
          }
        }

        > .estimate {
          margin-bottom: 40px;

          > .estimate-title {
            // font-size: 18px;
            font-weight: bold;
            margin-bottom: 16px;
          }
          > .estimate-score-flex {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > .estimate-score {
              margin-bottom: 10px;
              position: relative;

              > .estimate-score-contents {
                &.preset-hide {
                  > .star {
                    &::after {
                      display: none !important;
                    }
                  }
                }

                > .star {
                  width: 24px;
                  height: 24px;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  z-index: 1;

                  &.empty {
                    > div {
                      cursor: pointer;
                      content: '';
                      width: 24px;
                      height: 24px;
                      mask-image: url('/assets/images/icon/star.svg');
                      mask-repeat: no-repeat;
                      mask-position: center;
                      mask-size: contain;
                      background-color: $grey300;
                      z-index: 2;

                      &:hover {
                        background-color: $blue700;
                      }
                    }

                    &.preset-fill {
                      &::after {
                        cursor: pointer;
                        z-index: 3 !important;
                      }
                    }
                  }

                  &.fill {
                    > div {
                      cursor: pointer;
                      content: '';
                      width: 24px;
                      height: 24px;
                      mask-image: url('/assets/images/icon/star-fill.svg');
                      mask-repeat: no-repeat;
                      mask-position: center;
                      mask-size: contain;
                      background-color: $blue500;
                      z-index: 2;

                      &:hover {
                        background-color: $blue700;
                      }
                    }

                    &.preset-fill {
                      &::after {
                        z-index: 1 !important;
                      }
                    }
                  }

                  &.preset-fill {
                    &::after {
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: 1;
                      content: url('/assets/images/icon/star-preset.svg');
                      font-size: 0;
                    }
                  }

                  &.preset-empty {
                    &::after {
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: 1;
                      // content: url('/assets/images/icon/star-white-fill.svg');
                      font-size: 0;
                    }
                  }
                }
              }
            }

            > .estimate-score-number {
              * {
                font-size: 16px;
                font-weight: 500;

                span {
                  display: inline-block;
                }
              }

              .score-title {
                margin-right: 8px;
              }

              .score-number {
                color: $blue500;
                min-width: 50px;
                text-align: right;

                &.preset {
                  color: $grey400;
                }
              }
            }
          }

          > .estimate-comment {
            width: 100%;
            > textarea {
              width: 100%;
              max-height: 150px !important;
              border: 1px solid $grey300;
              border-radius: 4px;
              padding: 16px;
              overflow: visible !important;
            }
          }
        }
      }
    }
  }
}

.modals-assessments-view-answer-multiple-choice {
  .form {
    .estimate-score {
      .estimate-score-contents {
        > .star {
          &.fill {
            > div {
              cursor: default !important;
              &:hover {
                background-color: $blue500 !important;
              }
            }
          }
          &.empty {
            > div {
              cursor: default !important;
              &:hover {
                background-color: $grey300 !important;
              }
            }
          }
        }
      }
    }
  }
}

.modals-assessments-view-answer-styling {
  > .header {
    background-color: black;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 40px;

    > .header-title {
      // font-size: 20px;
      font-weight: bold;
      color: white;
    }

    > .header-close {
      cursor: pointer;
    }
  }

  > .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    margin-bottom: 40px;

    > .title {
      display: flex;
      width: 100%;
      font-weight: 700;
      align-items: center;
      color: $black;
    }

    > .left {
      display: flex;
      align-items: center;

      > .score {
        display: flex;
        white-space: nowrap;

        > p {
          font-weight: 700;
          margin-right: 10px;
        }
      }

      > .container {
        display: inline-flex;
        margin-right: 26px;

        > .stars {
          display: inline-flex;

          > .star {
            width: 24px;
            height: 24px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }

          &.preset {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            > .star {
              &.fill {
                &::before {
                  content: url('/assets/images/icon/star-preset.svg');
                  font-size: 0;
                }
              }
              &.empty {
                &::before {
                  content: url('/assets/images/icon/star-white-fill.svg');
                  font-size: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  > .outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 40px 150px;
    gap: 40px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    > .form {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      gap: 40px;
      width: 100%;
      > .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 32px;
        width: 100%;
        flex: 1 1;
        > .question {
          width: 100%;
          > .question-title {
            // font-size: 18px;
            font-weight: bold;
            margin-bottom: 16px;
          }

          > .question-description {
            color: $grey900;
            font-weight: 500;
            // line-height: 23.2px;

            > p {
              * {
                all: revert;
                margin: 0 !important;
                line-height: 1.8;
              }
            }
          }
        }

        > .answer {
          margin-bottom: 40px;

          > .answer-title {
            // font-size: 18px;
            font-weight: bold;
            margin-bottom: 16px;
          }

          > .answer-description {
            width: 100%;

            .styling-image {
              display: block;
              width: 100%;
            }

            &.video {
              height: 371px;
              background-color: $grey100;
              display: flex;
              justify-content: center;
              align-items: center;

              > video {
                max-width: 100%;
                max-height: 100%;
              }
            }

            &.essay {
              min-height: 200px;
              padding: 16px;
              border: 1px solid $grey300;
              border-radius: 4px;
              color: $grey900;
            }

            &.multiple-choice {
              > ul.answer-description-list {
                > li.answer-description-list-item {
                  &:not(&:last-child) {
                    margin-bottom: 16px;
                  }

                  > .container {
                    display: flex;
                    align-items: center;

                    > .radio-button {
                      color: $blue500;
                      margin-right: 4px;
                    }

                    > .text {
                      flex: 1;
                      padding: 16px;
                      background-color: $grey100;
                      border: 1px solid $grey300;
                      border-radius: 4px;
                    }
                  }

                  &.answer {
                    > .container {
                      > .text {
                        border: 1px solid $blue500;
                      }
                    }
                  }
                }
              }
            }

            &.file-upload {
              > button {
                padding: 0 16px;
                height: 39px;
                border-radius: 100px;

                &::before {
                  content: url('/assets/images/icon/download.svg');
                  margin-right: 11px;
                }
              }
            }
          }
        }
      }

      > .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 32px;
        width: 100%;
        flex: 1 1;
        > .guide {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          width: 100%;
          > .title {
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
          }
          > .desc {
            font-style: normal;
            font-weight: 500;
            display: flex;
            align-items: center;
            color: $grey900;
          }
        }
        > .items {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          width: 100%;
          > .title {
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
          }
        }
        > .estimate {
          margin-bottom: 40px;
          width: 100%;

          > .estimate-title {
            // font-size: 18px;
            font-weight: bold;
            margin-bottom: 16px;
          }

          > .estimate-score {
            position: relative;
            > .star {
              width: 24px;
              height: 24px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              position: relative;
              z-index: 1;

              &.empty {
                > div {
                  cursor: pointer;
                  content: '';
                  width: 24px;
                  height: 24px;
                  mask-image: url('/assets/images/icon/star.svg');
                  mask-repeat: no-repeat;
                  mask-position: center;
                  mask-size: contain;
                  background-color: $grey300;
                  z-index: 2;

                  &:hover {
                    background-color: $blue700;
                  }
                }

                &.preset-fill {
                  &::after {
                    cursor: pointer;
                    z-index: 3 !important;
                  }
                }
              }

              &.fill {
                > div {
                  cursor: pointer;
                  content: '';
                  width: 24px;
                  height: 24px;
                  mask-image: url('/assets/images/icon/star-fill.svg');
                  mask-repeat: no-repeat;
                  mask-position: center;
                  mask-size: contain;
                  background-color: $blue500;
                  z-index: 2;

                  &:hover {
                    background-color: $blue700;
                  }
                }

                &.preset-fill {
                  &::after {
                    z-index: 1 !important;
                  }
                }
              }

              &.preset-fill {
                &::after {
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  content: url('/assets/images/icon/star-preset.svg');
                  font-size: 0;
                }
              }

              &.preset-empty {
                &::after {
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  // content: url('/assets/images/icon/star-white-fill.svg');
                  font-size: 0;
                }
              }
            }

            // .preset-estimate-score-contents {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;

            //     >.star {
            //         width: 24px;
            //         height: 24px;
            //         display: inline-flex;
            //         justify-content: center;
            //         align-items: center;
            //         position: relative;
            //         z-index: 1;

            //         &.fill {
            //             &::before {
            //                 content: url('/assets/images/icon/star-preset.svg');
            //                 font-size: 0;
            //             }
            //         }
            //         &.empty {
            //             &::before {
            //                 content: url('/assets/images/icon/star-white-fill.svg');
            //                 font-size: 0;
            //             }
            //         }
            //     }
            // }
          }

          > .estimate-comment {
            width: 100%;
            > textarea {
              width: 100%;
              max-height: 150px !important;
              border: 1px solid $grey300;
              border-radius: 4px;
              padding: 16px;
              overflow: visible !important;
            }
          }
        }
      }
    }
    > .submit {
      width: 460px;
      height: 56px;
      border-radius: 16px;
      margin: 0 auto;
    }
  }
}

.modals-profile-change-password {
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    height: 80px;
    background-color: black;

    > .header-title {
      // font-size: 20px;
      font-weight: bold;
      color: white;
    }

    > .header-close {
      cursor: pointer;
      width: 36px;
      height: 36px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: '';
        width: 21px;
        height: 21px;
        mask-image: url('/assets/images/icon/close.svg');
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
        background-color: white;
      }
    }
  }

  > .container {
    margin: 40px auto;
    width: 90%;
    max-width: 660px;

    > .title {
      margin-bottom: 40px;

      > .title-main {
        // font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      > .title-sub {
        color: $grey900;
      }
    }

    > .input {
      width: 100%;

      &:not(&:last-child) {
        margin-bottom: 32px;
      }
    }

    > .submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
      margin: 0 auto;
    }
  }
}

.modals-profile-invite-member {
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    height: 80px;
    background-color: $blue500;

    > .header-title {
      font-weight: bold;
      color: white;
    }

    > .header-close {
      cursor: pointer;
      width: 36px;
      height: 36px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: '';
        width: 21px;
        height: 21px;
        mask-image: url('/assets/images/icon/close.svg');
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
        background-color: white;
      }
    }
  }

  > .container {
    margin: 40px auto;
    width: 90%;

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .input-wrapper {
        display: flex;
        gap: 32px;
      }

      .invited-button {
        > .submit {
          width: 460px;
          height: 56px;
          border-radius: 32px;
          margin: 0 auto;
          font-size: 16px;
        }
      }
    }
  }
}

.modal-recruitments-create,
.setting-recruitment-container {
  overflow-y: auto !important;
  padding: 54px 60px;
  background-color: $white !important;

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 58px;

    > .header-title {
      font-weight: 700;
      color: #333;
    }

    > .header-close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('/assets/images/icon/close.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  > .container {
    margin: 0 auto;

    .wrapper {
      display: flex;

      > .left {
        width: 160px;

        .title-box {
          display: flex;
          color: #333;
          font-size: 16px;
          font-style: normal;
          line-height: normal;

          pre {
            font-weight: 600 !important;
          }
        }
      }

      > .right {
        width: calc(100% - 160px);

        .process-setting-wrapper {
          display: flex;
          flex-direction: column;

          .label-row-group {
            display: flex;
            align-items: center;
            gap: 14px;
            width: fit-content;
            width: 200px;

            &.no-flex {
              display: block;
              margin-bottom: 12px !important;

              .MuiButtonBase-root {
                padding: 0;
                margin-right: 14px;
              }
            }

            &.optional {
              margin: 0;
              margin-top: 12px;
              padding-left: 32px;
              max-width: 210px;

              > label {
                margin: 0;
                align-items: flex-start;
              }

              .MuiTypography-root {
                font-size: 14px;
                color: $grey900;
                font-weight: 500;
              }
            }

            &.selected-assessment {
              width: auto;
              gap: 24px;

              .selected-wrapper {
                display: flex;
                align-items: center;
                gap: 14px;
                width: 200px;
              }

              .assessment-form {
                min-width: 210px;
                max-width: 250px;
              }
            }

            &:not(:last-child) {
              margin-bottom: 24px;
            }

            .flow-icon {
              width: 12px;
              height: 12px;
              padding: 1px;
              background-color: $white;

              .rect {
                display: block;
                width: 100%;
                height: 100%;
                background-color: $grey600;
                border-radius: 2px;
              }
            }

            .flow-name {
              font-weight: 500;
              color: $grey900;
            }

            > .optional-checkbox-wrapper {
              display: flex;
              gap: 14px;
              width: fit-content;
              align-items: center;
            }

            > .form-assessments {
              max-width: 300px;
              text-align: left;

              input {
                z-index: 0;
              }

              .toggle {
                margin-right: 0;
              }
            }
          }
        }
      }

      &.button-box {
        justify-content: center;

        .confirm-button {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          display: flex;
          width: 220px;
          padding: 18px 80px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          background: #3193ee;
        }
      }

      .date-picker-contents {
        > .select {
          display: flex;
          align-items: center;
          gap: 35px;
          margin-top: -9px;
          margin-bottom: 30px;
          width: fit-content;

          p {
            font-weight: 500;
          }

          .ant-switch {
            width: 36px;
            height: 20px;
            padding: 0;
            display: flex;

            &:active {
              .MuiSwitch-thumb {
                width: 16px;
              }
              .MuiSwitch-switchBase {
                .Mui-checked {
                  transform: 'translateX(9px)';
                }
              }
            }

            .MuiSwitch-switchBase {
              padding: 2px;

              &.Mui-checked {
                transform: translateX(17px);
                color: $white;
              }

              &.Mui-checked + .MuiSwitch-track {
                opacity: 1;
                background-color: $blue500;
              }
            }

            .MuiSwitch-thumb {
              box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
              width: 15.5px;
              height: 15.5px;
              border-radius: 50%;
              transition: all 0.2s;
            }

            .MuiSwitch-track {
              border-radius: 16px;
              opacity: 1;
              background-color: $grey300;
              box-sizing: border-box;
            }
          }

          .date-switch {
            margin-left: -12px;
          }
        }

        > .time-picker-box {
          flex-direction: column;
          gap: 14px;

          .time-label {
            display: flex;
            align-items: center;

            .time-title {
              width: 90px;
              font-weight: 500;
            }

            .icon-box {
              width: 58px;
              font-size: 0;
            }
          }
        }
      }

      &.title-wrapper {
        margin-bottom: 56px;
        align-items: center;
      }

      &.information-wrapper {
        margin-bottom: 92px;

        > .right {
          .radio-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }
        }
      }

      &.process-wrapper {
        margin-bottom: 72px;

        .right {
          position: relative;

          > .MuiFormControl-root {
            z-index: 2;
          }

          hr {
            z-index: 1;
            height: 92%;
            width: 0.5px;
            display: block;
            position: absolute;
            top: 0;
            left: 5px;
            border: 1px dashed $grey500;
          }
        }
      }

      &.standard-wrapper {
        margin-bottom: 36px;

        .right {
          .input-standard {
            display: flex;
            margin-bottom: 16px;
            gap: 12px;
            font-size: 14px;

            > .standard-box {
              display: flex;
              align-items: center;
              padding: 8px 20px;
              border-radius: 8px;
              border: 1px solid $grey200;
              background: $grey50;

              &.disable {
                color: $grey500;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }

            :first-child {
              width: 260px;
            }
            :nth-child(2) {
              width: calc(100% - 260px - 32px - 24px);
            }
            :last-child {
              width: 38px;
              > svg {
                path {
                  fill: grey;
                }
              }
            }
          }
          .standard-example {
            display: flex;
            color: $grey500;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            gap: 12px;
            margin-bottom: 24px;
            :first-child {
              width: 260px;
            }
            :last-child {
              width: calc(100% - 260px - 12px);
            }
          }
        }
      }

      &.schedule-wrapper {
        margin-bottom: 48px;

        .right {
          .time-picker-wrapper {
            .MuiInputBase-root {
              padding-right: 0;

              .MuiInputBase-input {
                width: 140px;
                padding: 9px 13px;
                border-radius: 6px;
                background-color: $blue50;
                font-size: 14px;
                color: $grey600;
                font-weight: 500;
              }

              .MuiOutlinedInput-notchedOutline {
                border-color: $blue100;
              }

              .MuiInputAdornment-root {
                position: absolute;
                right: -30px;
              }
            }
          }
        }
      }
    }
  }
}

.modals-tutorial {
  width: 460px;
  // height:449.12px;
  padding: 0 0 24px 0;
  > .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    // gap: 228px;
    width: 460px;
    height: 56px;
    background: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    > .title {
      // width: 114px;
      height: 23px;
      font-style: normal;
      font-weight: 700;
      // font-size: 16px;
      // line-height: 145%;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: $grey900;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    // >.close{
    //     position: absolute;
    //     left: 20.83%;
    //     right: 20.83%;
    //     top: 20.83%;
    //     bottom: 20.83%;
    //     background: $grey900;
    // }
  }

  > .modal-main {
    width: 100%;
    display: block;
    text-align: center;
    > .title {
      // width: 328px;
      // height: 26px;
      font-style: normal;
      font-weight: 700;
      // font-size: 18px;
      // line-height: 145%;
      display: block;
      // align-items: center;
      // text-align: center;
      letter-spacing: -0.01em;
      color: $black;
      // flex: none;
      order: 1;
      flex-grow: 0;
      margin: 24px;
    }
    > .video {
      margin: 24px auto 0;
      width: 412px;
      height: 231.12px;
    }
    > .close-button {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      // width: 114px;
      height: 40px;
      background: #ffffff;
      border: 1px solid $grey300;
      border-radius: 4px;
      flex: none;
      order: 3;
      flex-grow: 0;
      margin: 24.8px auto 0;
    }
  }
}

.modals-speaking {
  width: 560px;
  height: auto;
  padding: 0 0 24px 0;
  > .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    width: 100%;
    height: 56px;
    background: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    > .title {
      height: 23px;
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: $grey900;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    > .close {
      cursor: pointer;
    }
  }

  > .modal-main {
    width: 100%;
    display: block;
    text-align: center;
    > .title {
      font-style: normal;
      font-weight: 700;
      display: block;
      letter-spacing: -0.01em;
      color: $black;
      order: 1;
      flex-grow: 0;
      margin: 24px;
    }
    > .video {
      padding: 24px 24px 0;
      width: 100%;
      height: auto;
    }
    > .close-button {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      height: 40px;
      background: #ffffff;
      border: 1px solid $grey300;
      border-radius: 4px;
      flex: none;
      order: 3;
      flex-grow: 0;
      margin: 24.8px auto 0;
    }
  }
}
.modals-add-candidate {
  width: 100%;
  background-color: $white;
  border-radius: 8px;
  margin: 0 auto;

  > .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    width: 100%;
    height: 56px;
    background: $white;
    flex: none;
    order: 0;
    flex-grow: 0;
    > .title {
      height: 23px;
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: $grey900;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    > .close {
      cursor: pointer;
    }
  }

  > .modal-main {
    max-height: 85vh;
    height: 100%;
    overflow-y: auto;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: $white;

    .apply-title-wrapper {
      h2,
      h4 {
        font-weight: 700;
      }
    }

    .flex {
      display: flex;
      gap: 16px;
    }

    > .apply-recruitment-title {
      padding: 8px;
      text-align: center;
      background-color: $grey50;
      border-radius: 8px;

      h4 {
        font-weight: 700;
      }
    }

    > .apply-input-form {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .apply-input-box {
        &.flex {
          display: flex;
          align-items: center;
          gap: 16px;

          & > * {
            flex: 1;
          }
        }

        &.flex-strech {
          display: flex;
          align-items: stretch;
          gap: 16px;

          > .grey-box {
            justify-content: space-evenly;
          }

          & > * {
            flex: 1;
          }
        }

        &.type-add-input-box {
          align-items: flex-start;

          & > * {
            flex: none;
          }

          & > :first-child {
            width: 210px;
          }
          & > :last-child {
            width: 240px;
          }

          .sub-title {
            p {
              margin-top: 8px;
            }
          }
        }

        &.currency-wrapper {
          align-items: flex-start;

          .label {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 145%; /* 20.3px */
            letter-spacing: -0.14px;
            color: $grey700;
            margin-bottom: 16px;

            i {
              color: $red500;
              font-weight: 700;
            }
          }

          .grey-box {
            padding: 8px 14px;
            margin-bottom: 16px;

            pre {
              font-size: 12px !important;
              font-style: normal;
              font-weight: 500 !important;
              line-height: 130% !important; /* 15.6px */
              letter-spacing: -0.24px;
              color: $grey700;
            }
          }

          .flex {
            display: flex;
            align-items: flex-start;
            gap: 16px;

            & > :first-child {
              flex: 4;
            }
            & > :last-child {
              flex: 7;
            }
          }

          .basic-input,
          .basic-select {
            .input-label {
              display: none;
            }
          }
        }

        &.etc-currency-wrapper {
          margin-top: -24px;
        }

        &.resume-wrapper {
          align-items: flex-start;
          position: relative;

          & > :first-child {
            flex: 4;
          }
          & > :last-child {
            flex: 7;
          }

          .align-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;

            .plus-button-wrapper {
              margin-top: -12px;

              svg {
                path {
                  fill: $grey900;
                }
              }
            }

            .basic-file {
              .input-caution {
                white-space: pre;
              }
            }
          }
        }

        &.resume-list-wrapper {
          margin-top: -16px;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .resume-grey-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 14px;
            border-radius: 4px;
            border: 1px solid $grey300;
            background-color: $grey100;

            p {
              color: $grey500;
              font-weight: 500;
            }
          }
        }

        &.etc-link-wrapper {
          & > * {
            flex: none;
          }

          & > :first-child {
            width: calc(100% - 56px);
          }
          & > :last-child {
            width: 40px;

            svg {
              path {
                fill: $grey900;
              }
            }
          }
        }

        &.recommend-wrapper {
          align-items: flex-end;
        }

        .sub-title {
          font-weight: 500;
          color: $grey700;
          margin-bottom: 12px;
        }

        .apply-agree-radio-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;

          .input-label {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 145%;
            letter-spacing: -0.14px;
            color: $grey700;

            i {
              color: $red500;
            }
          }
        }

        .grey-box {
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          background-color: $grey50;
          border: 1px solid $grey300;
          border-radius: 4px;

          > label {
            color: $grey800;
            font-weight: 600;

            i {
              color: $red500;
              font-weight: 600;
            }
          }

          .basic-select {
            gap: 0;

            .toggle {
              background-color: $white;
            }
          }

          .input-caution {
            color: $grey700;
          }
        }
      }
    }

    > .apply-submit-button {
      button {
        width: 100%;
      }
    }
  }
}
.modal-set-period {
  width: 560px;
  // height:449.12px;
  padding: 0 0 24px 0;
  background-color: #ffffff;

  > .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    margin-bottom: 12px;
    width: 560px;
    height: 56px;
    flex: none;
    order: 0;
    flex-grow: 0;
    > .title {
      height: 23px;

      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: $grey900;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }

  > .modal-main {
    width: 100%;
    display: block;
    padding: 0 16px;

    .select-box {
      margin: 12px 0;
      .sub-title {
        font-style: normal;
        font-weight: 700;
        line-height: 145%;
        letter-spacing: -0.16px;
        color: $grey900;
      }
      .select {
        height: 56px;
        margin-top: 8px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background: $white;
        border: 1px solid $blue500;
        border-radius: 4px;
        padding: 16px;
        cursor: pointer;
        > .radio-button {
          color: $grey900;
        }
        > p {
          width: 516px;
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          color: $grey900;
        }
        &.selected {
          > .radio-button {
            color: $blue500;
          }
        }
      }
    }
    .time-picker-box {
      width: 100%;
      display: flex;
      margin: 12px 0;
      .time-label {
        flex: 1;
      }
    }
    > .save-button {
      margin: 16px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 24px;
      height: 40px;
      background: $blue500;
      border-radius: 4px;
      flex: none;
      color: #ffffff;
      order: 3;
      flex-grow: 0;
      margin: 24.8px auto 0;
    }
  }
}
.modals-assessments-obpi-set {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 60px 0;
  gap: 10px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.15);

  > .obpi-set-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 24px;
    gap: 24px;

    width: 640px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 8px;
    height: calc(100vh - 120px);
    overflow-y: auto;
    overscroll-behavior: contain;
    > .obpi-set-modal-header {
      // position: fixed;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 16px;
      gap: 228px;
      width: 640px;
      height: 56px;
      background: $white;
      > .obpi-set-modal-header-title {
        height: 23px;
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: $grey900;
      }
      > .obpi-set-modal-header-close {
        width: 24px;
        height: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &::after {
          content: '';
          width: 14px;
          height: 14px;
          mask-image: url('/assets/images/icon/close-modal.svg');
          mask-position: center;
          mask-size: contain;
          background-color: $grey900;
        }
      }
    }
    > .obpi-set-modal-scroll {
      > .obpi-set-modal-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 32px;
        gap: 24px;
        width: 640px;
        > .obpi-set-modal-content-discription {
          width: 576px;
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          color: $black;
        }
        > .obpi-set-modal-content-select-candidate {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 8px;
          width: 576px;
          height: 80px;

          > .select-form {
            width: 576px;
            height: 56px;
            background: #ffffff;
            border-radius: 4px;
          }
          > .obpi-set-modal-content-select-candidate-discription {
            width: 576px;
            height: 16px;
            font-style: normal;
            font-weight: 500;
            color: $grey500;
          }
        }
        > .obpi-detail-result-section {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0;
          gap: 8px;
          width: 576px;
          height: 60px;
          > .obpi-detail-result-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            gap: 4px;
            width: 100%;
            > .obpi-detail-result-title {
              width: 100%;
              font-style: normal;
              font-weight: 700;
              align-items: center;
              text-align: center;
              color: $grey900;
            }
            > .obpi-detail-results {
              > .obpi-detail-result-answer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                padding: 0;
                gap: 4px;
                // width: 880px;
                height: 16px;
                > .obpi-detail-result-answer-item {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  width: 60.4px;
                  height: 16px;
                  background: $grey100;
                  border-radius: 4px;
                  &.selected {
                    background: $blue500;
                    &::after {
                      width: 12px;
                      height: 12px;
                      display: inline-block;
                      content: '';
                      -webkit-mask: url('/assets/images/icon/star-white-fill.svg')
                        no-repeat 50% 50%;
                      mask: url('/assets/images/icon/star-white-fill.svg')
                        no-repeat 50% 50%;
                      -webkit-mask-size: cover;
                      mask-size: cover;
                      background-color: $white;
                    }
                  }
                }
              }
            }
            > .opbi-detail-result-discription-box {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              padding: 0;
              gap: 4px;
              //   width: 880px;
              height: 16px;
              > .discription {
                width: 286px;
                height: 16px;
                font-style: normal;
                font-weight: 700;
                align-items: center;
                color: $grey700;
                &.left {
                  text-align: left;
                }
                &.right {
                  text-align: right;
                }
              }
            }
          }
        }
        > .save {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 24px;
          gap: 8px;

          width: 576px;
          height: 56px;
          background: $blue500;
          border-radius: 32px;
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          text-align: center;
          color: $white;
          &:hover {
            background: $blue600;
          }
          &.disabled {
            cursor: auto;
            color: $grey900;
            background: $grey100;
          }
        }
      }
    }
  }
}

.modals-assessments-obpi-guide {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 60px 0;
  gap: 10px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.15);

  > .obpi-guide-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 24px;
    gap: 24px;

    width: 460px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 8px;
    > .obpi-guide-modal-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 16px;
      gap: 228px;
      width: 460px;
      height: 56px;
      background: $white;
      > .obpi-guide-modal-header-title {
        height: 23px;
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: $grey900;
      }
      > .obpi-guide-modal-header-close {
        width: 24px;
        height: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &::after {
          content: '';
          width: 14px;
          height: 14px;
          mask-image: url('/assets/images/icon/close-modal.svg');
          mask-position: center;
          mask-size: contain;
          background-color: $grey900;
        }
      }
    }
    > .obpi-guide-modal-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 24px;
      gap: 24px;
      width: 460px;
      > .obpi-guide-modal-section-desc {
        font-style: normal;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: $black;
        word-break: keep-all;
        word-wrap: break-word;
      }
      > .obpi-guide-modal-section-graph {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0;
        gap: 47px;
        width: 100%;
        > .graph {
          width: 80px;
          height: 80px;
        }
      }
      > .obpi-guide-modal-section-block {
        width: 100%;
        > .obpi-guide-modal-section-block-title {
          font-style: normal;
          font-weight: 700;
          display: flex;
          align-items: center;
          color: $black;
        }
        > .obpi-guide-modal-section-block-desc {
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          color: $black;
          word-break: keep-all;
          word-wrap: break-word;
        }
      }
    }
  }
}

.modals-assessment-view-detail-library {
  .header {
    background-color: black;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    position: sticky;
    top: 0;

    .header-title {
      font-weight: bold;
      color: white;
    }

    .header-close {
      cursor: pointer;
    }
  }

  .title-box {
    padding: 40px;

    h2 {
      font-weight: 700;
    }
  }

  .contents {
    padding: 0 40px;

    .question-contents {
      // multiple-choice css
      .question-contents-list-items {
        display: flex;
        gap: 40px;
        flex-grow: 0;
        padding-bottom: 40px;
        border-bottom: 1px solid $grey200;
        &:not(:first-child) {
          padding-top: 40px;
        }

        > div {
          width: 50%;
        }

        .question-contents-list-title {
          > h6 {
            margin-bottom: 8px;
            font-weight: 700;
          }
        }

        .question-contents-list-choice {
          ul {
            li {
              &:not(:last-child) {
                margin-bottom: 16px;
              }
              .container {
                padding: 0;
                display: flex;
                align-items: center;
                border: 1px solid $grey100;
              }
            }
          }

          .grey-box {
            padding: 16px;
            background-color: $grey50;
            border-radius: 16px;
            p {
              font-weight: 700;
            }
          }
        }
      }

      //obpi css
      .obpi-question-contents-list {
        .obpi-question-contents-list-items {
          padding-bottom: 40px;
          .obpi-question-contents-list-title {
            text-align: center;
            margin-bottom: 8px;
            > h6 {
              font-weight: 700;
            }
          }
          .obpi-question-contents-list-choice {
            ul {
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(18%, auto));
              gap: 16px;

              li {
                text-align: center;
                padding: 16px;
                border: 1px solid $grey100;
              }
            }
          }
        }
      }
    }
  }
}

.modals-interview-schedule {
  background-color: $grey50;

  .header {
    background-color: black;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    position: sticky;
    top: 0;
    z-index: 10;

    .header-title {
      font-weight: bold;
      color: white;
    }

    .header-close {
      cursor: pointer;
    }
  }

  .main {
    padding: 40px 20px 150px;

    .schedule-container {
      display: flex;
      flex-direction: column;
      gap: 40px;

      > .contents {
        display: flex;
        gap: 20px;

        // common
        .flex-column-gap-8 {
          display: flex;
          flex-direction: column;
          gap: 8px;

          &.flex-3 {
            flex: 3;
          }

          &.flex-7 {
            flex: 7;
          }
        }

        .flex-row-gap-20 {
          display: flex;
          gap: 20px;
        }

        .border {
          background-color: $white;
          border-radius: 4px;
          border: 1px solid $grey200;
          padding: 16px;
        }

        .sub-title {
          font-weight: 700;
          color: $black;
        }

        > aside {
          background-color: $white;
          border-radius: 8px;
          border: 1px solid $grey200;
          padding: 40px;

          &.left-contents {
            flex: 1;
            max-width: 1020px;
            margin: 0 auto;

            > div {
              position: sticky;
              top: 100px;
              left: 0;
              display: flex;
              flex-direction: column;
              gap: 32px;
            }

            .select-time {
              height: 55px;
            }

            .interview-date-wrapper {
              &.flex-3 {
                flex: 3;
              }

              &.flex-7 {
                flex: 7;
              }
            }

            .textarea-wrap {
              textarea {
                width: 100%;
                padding: 16px 14px;
                background-color: $white;
                border-radius: 4px;
                border: 1px solid $grey200;
                height: 100px;
                font-size: 16px;
                font-weight: 500;
                color: $grey900;
                transition: border 0.2s ease-in-out;

                &:focus {
                  border-color: $blue500;
                  box-shadow: 0px 0px 0px 3px rgba(50, 148, 238, 0.25);
                }
              }
            }

            .input-text-wrap {
              textarea {
                width: 100%;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 145%; /* 23.2px */
                letter-spacing: -0.16px;
                padding: 16px 14px;
                color: $grey900;
                height: 200px;
                border: 1px solid $grey200;
                background-color: $white;
                border-radius: 4px;
                transition: border 0.2s ease-in-out;

                &::placeholder {
                  color: $grey400;
                }

                &:focus {
                  border-color: $blue500;
                  box-shadow: 0px 0px 0px 3px rgba(50, 148, 238, 0.25);
                }
              }
            }

            .setting-date-wrapper {
              ul {
                display: flex;
                gap: 20px;

                li {
                  flex-grow: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  border: 1px solid $grey200;
                  border-radius: 4px;
                  padding: 12px 16px;

                  span {
                    font-size: 14px;
                    color: $grey900;
                    font-weight: 500;
                    width: 40px;
                    white-space: nowrap;
                  }

                  .date-picker {
                    width: 80%;

                    .timePicker {
                      margin-top: 0 !important;
                    }

                    .MuiInputBase-root {
                      padding-right: 0;

                      .MuiInputBase-input {
                        max-width: 100%;
                        width: 100%;
                        padding: 9px 13px;
                        border-radius: 6px;
                        background-color: $blue50;
                        font-size: 14px;
                        color: $grey600;
                        font-weight: 500;
                      }

                      .MuiOutlinedInput-notchedOutline {
                        border-color: $blue100;
                        padding-right: 60px;
                      }

                      .MuiInputAdornment-root {
                        position: absolute;
                        right: -40px;
                      }
                    }
                  }
                }
              }
            }
          }

          &.right-contents {
            flex: 9;

            display: flex;
            flex-direction: column;
            gap: 30px;

            .outlook-calendar {
              background-color: $grey50;
              border: 1px solid $grey200;
              border-radius: 8px;
              position: relative;

              p {
                position: absolute;
                color: $grey700;
                top: 50%;
                left: 0;
                width: 100%;
                transform: translateY(-50%);
                text-align: center;
              }
            }
          }
        }
      }

      > .button-contents {
        display: flex;
        justify-content: center;

        .send-button {
          max-width: 460px;
          width: 100%;
          font-weight: 500;
        }
      }
    }
  }
}

// tooltip css
.estimate-score-contents {
  display: inline-block;
  + .tooltip {
    display: none;
    flex-direction: row;
    padding: 8px;
    gap: 10px;
    width: 200px;
    background: $grey100;
    border-radius: 4px;
    font-weight: 500;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: $grey900;
    margin-left: -90px;
  }

  &.preset-hide {
    &:hover + .tooltip {
      display: none;
    }
  }

  &:hover + .tooltip {
    display: block;
    position: fixed;
  }
}

.modal-candidate-detail {
  min-width: 1204px;
  overflow-x: auto;
  overflow-y: auto !important;
  background-color: $white !important;

  > .header {
    padding: 46px 40px 24px;
    background-color: white;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;

    > .header-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 40px;
      height: 100%;

      > .header-sub-title-left {
        color: #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        min-width: 200px;

        &.null {
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
        &::after {
          content: url('/assets/images/icon/candidate-detail-arrow-left.svg');
          width: 29px;
          height: 29px;
        }
      }

      > .header-sub-title-right {
        color: #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        min-width: 200px;

        &.null {
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
        &::before {
          content: url('/assets/images/icon/candidate-detail-arrow-right.svg');
          width: 29px;
          height: 29px;
        }
      }

      > .header-title {
        font-weight: bold;
      }

      > .header-close {
        cursor: pointer;
        width: 36px;
        height: 36px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        &::after {
          content: '';
          background: url('/assets/images/icon/close-black.svg');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  > .container {
    position: relative;
    margin: 0 auto;
    margin-bottom: 40px;
    width: 90%;

    .tab-flex {
      display: flex;
      justify-content: space-between;

      .tab-container {
        display: flex;
        gap: 8px;
        margin-bottom: 12px;

        .tab-box {
          padding: 14px;
          height: fit-content;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          cursor: pointer;
          border-radius: 8px;
          position: relative;
          width: fit-content;
          z-index: 2;
          background-color: $grey100;
          color: $grey500;

          &.selected {
            font-size: 16px;
            font-weight: 700;
            color: #3193ee;
            background-color: $blue50;
          }

          &.hidden {
            visibility: hidden;
          }
        }
      }

      .interviewer-button {
        max-width: 480px;
        width: 100%;

        .flex {
          display: flex;
          align-items: center;
          gap: 8px;

          > :first-child {
            flex: 7;
          }
          > :last-child {
            flex: 3;
          }
        }
      }
    }

    .wrapper {
      border-radius: 8px;
      padding: 40px 32px;

      &.candidate-list-detail {
        background-color: $grey50;

        > .information-container {
          .information-wrapper {
            display: flex;
            flex-direction: column;
            gap: 32px;

            &:not(:first-child) {
              padding-top: 32px;
            }

            &:not(:last-child) {
              padding-bottom: 32px;
              border-bottom: 1px solid $grey300;
            }

            .main-title {
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              color: $blue500;
            }

            ul {
              display: flex;
              flex-wrap: wrap;
              gap: 24px 30px;

              li {
                flex-basis: calc(20% - 30px);
                display: flex;
                flex-direction: column;
                gap: 14px;

                span.sub-title {
                  font-weight: 500;
                  color: $grey600;
                }

                p.desc {
                  font-weight: 700;
                  color: $grey900;
                }
              }
            }
          }
        }

        > .comment-container {
          padding-top: 24px;
          border-top: 1px solid $grey300;

          .comment-wrapper {
            ul {
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;

              li {
                display: flex;
                gap: 32px;

                .comment {
                  flex: 6;

                  pre {
                    color: $grey800;
                    font-weight: 400;
                    word-break: keep-all;
                  }
                }

                .write-info {
                  flex: 4;
                  display: flex;
                  gap: 20px;

                  > div {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;

                    &.writer {
                      width: 90px;
                    }

                    &.date {
                      width: 140px;
                    }

                    span.title {
                      color: $grey600;
                      font-weight: 500;
                    }

                    p {
                      font-weight: 700;
                      color: $grey900;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.reviewing-detail {
        background-color: $grey50;

        .tagging {
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          gap: 8px;

          p {
            display: inline-block;
            padding: 6px 12px;
            text-align: center;
            border-radius: 8px;
            background-color: $blue50;
            color: $blue500;
            font-weight: 700;
          }
        }

      .apply-resume{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        > .apply-title {
          font-weight: 700;
        }
      }

        > .reviewing-header {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding-bottom: 24px;
          border-bottom: 1px solid $grey300;

          > .resume-list-section {
            display: block;
            flex: 1;

            > ul.resume-list {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              gap: 12px 28px;

              li {
                display: flex;
                align-items: center;
                gap: 8px;

                span {
                  font-weight: 500;
                }

                a {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  color: $blue500;

                  i {
                    font-size: 0;

                    svg {
                      path {
                        fill: $blue500;
                      }
                    }
                  }
                }

                & > :first-child {
                  width: 80px;
                }
                & > :nth-child(2) {
                  width: calc(100% - 80px - 8px);
                }
              }

              & > * {
                width: calc(50% - 14px);
              }
            }
          }

          > .status-tab {
            display: flex;
            justify-content: center;
            flex: 1;

            .tab-container {
              display: flex;
              gap: 8px;
              border-radius: 8px;
              border: 1px solid $grey200;
              background-color: $grey100;
              justify-content: center;
              align-items: stretch;
              cursor: pointer;
              padding: 10px;
              height: 80px;
              width: 100%;
              & > * {
                &:last-child {
                  &.active {
                    background-color: $red500;
                    color: $white;
                  }
                  &:hover {
                    background-color: $red500;
                    color: white;
                  }
                }
              }
              > .active {
                font-weight: 700;
                background-color: #3193ee;
                color: white;
              }
              > div {
                flex: 1;
                white-space: pre-wrap;
                display: flex;
                padding: 8px 20px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: $grey700;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-radius: 8px;
                transition: background 0.2s ease-in-out;
                &:hover {
                  background-color: #3193ee;
                  color: white;
                }
              }
            }
          }
        }

        > .information-container {
          padding: 24px 0;

          .information-wrapper {
            .information-edit-button-wrapper {
              display: flex;
              justify-content: flex-end;
            }

            ul {
              display: flex;
              flex-wrap: wrap;
              gap: 24px 30px;

              li {
                flex-basis: calc(20% - 30px);
                display: flex;
                flex-direction: column;
                gap: 14px;

                span.sub-title {
                  font-weight: 500;
                  color: $grey600;
                }

                p.desc {
                  font-weight: 700;
                  color: $grey900;
                }

                .candidate-info-edit-select {
                  background: white;
                  padding: 8px;
                }

                .candidate-edit-input {
                  border-radius: 5px;
                  padding: 8px;
                    border: 1px solid $grey300;
                }
              }
            }
          }
        }

        > .comment-container {
          padding-top: 24px;
          border-top: 1px solid $grey300;

          .comment-wrapper {
            ul {
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;

              li {
                display: flex;
                gap: 32px;

                .comment {
                  flex: 6;

                  pre {
                    color: $grey800;
                    font-weight: 400;
                    word-break: keep-all;
                  }
                }

                .write-info {
                  flex: 4;
                  display: flex;
                  gap: 20px;

                  > div {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;

                    &.writer {
                      width: 90px;
                    }

                    &.date {
                      width: 140px;
                    }

                    span.title {
                      color: $grey600;
                      font-weight: 500;
                    }

                    p {
                      font-weight: 700;
                      color: $grey900;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.virtual-detail {
        padding: 0;

        .right {
          .right-wrapper {
            width: 100%;

            .right-sticky {
              position: sticky;
              top: 120px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 16px;

              .tab-container {
                margin-bottom: 0;
              }

              .comment-container {
                border: 1px solid $grey200;
                border-radius: 8px;
                overflow: hidden;
                width: 100%;
                font-size: 0;

                textarea {
                  width: 100%;
                  padding: 20px;
                  height: 268px;
                  font-size: 12px;
                  font-weight: 500;
                  color: $grey900;
                  line-height: 20px;
                  background-color: $grey100;
                }
              }

              .submit-button {
                width: 100%;

                button {
                  width: 100%;
                  padding: 18px 0;
                  text-align: center;
                  display: inline-block;
                  border-radius: 8px;
                  font-weight: 700;
                }
              }
            }
          }
        }

        .flex {
          gap: 20px;

          .left,
          .right {
            background-color: $grey50;
            border-radius: 8px;
          }

          .left {
            .left-content {
              padding: 32px 16px;
            }
          }

          .right {
            .right-wrapper {
              width: 100%;
              padding: 40px;

              .right-sticky {
                position: sticky;
                top: 120px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 16px;

                .tab-container {
                  margin-bottom: 0;
                }

                .comment-container {
                  border: 1px solid $grey200;
                  border-radius: 8px;
                  overflow: hidden;
                  width: 100%;
                  font-size: 0;

                  textarea {
                    width: 100%;
                    padding: 20px;
                    height: 268px;
                    font-size: 12px;
                    font-weight: 500;
                    color: $grey900;
                    line-height: 20px;
                    background-color: $grey100;
                  }
                }

                .submit-button {
                  width: 100%;

                  button {
                    width: 100%;
                    padding: 18px 0;
                    text-align: center;
                    display: inline-block;
                    border-radius: 8px;
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }

      &.person-interview-detail {
        background-color: $grey50;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .opinion-container {
          width: 100%;
          justify-content: flex-end;
          display: flex;
          align-items: center;
          gap: 4px;
          cursor: pointer;
          margin-bottom: 0;
          margin-top: 20px;

          &.disabled {
            > div {
              cursor: default;
            }
          }

          > .active {
            background-color: $blue300;
            color: white;
            font-weight: 700;
          }

          > div {
            width: 25%;
            display: flex;
            padding: 18px 80px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            margin-right: 30px;
            border-radius: 8px;
            background-color: $grey200;
            color: $grey900;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
          }
        }

        .score-container {
          display: flex;
          width: 100%;
          .left {
            flex: 2;
            .standard-title {
              color: $grey900;
              font-size: 15px;
              font-style: normal;
              font-weight: 700;
              line-height: 145%;
              letter-spacing: -0.2px;
            }
          }

          .right {
            flex: 8;
            .standard-desc {
              color: $grey800;
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: 145%;
              letter-spacing: -0.2px;
              margin-bottom: 24px;
            }

            .score-container {
              display: flex;
              .NA-box {
                display: flex;
                width: 90px;
                height: 60px;
                padding: 24px 30px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                background-color: white;
                color: $grey800;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
                letter-spacing: -0.2px;
                margin-right: 12px;

                &.selected {
                  color: white;
                  font-weight: bold;
                  background-color: $red500;
                }
              }
              .score-box {
                display: flex;
                flex-direction: row;
                color: $grey500;
                font-style: normal;
                height: 60px;
                padding: 24px 36px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                background-color: white;
                border-radius: 8px;
                cursor: pointer;
                .middle {
                  display: flex;
                  width: 60px;
                  height: 60px;
                  padding: 8px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  transition: all 0.2s ease-in-out;
                  &.high-selected {
                    height: 74px;
                    color: white;
                    font-weight: bold;
                    background-color: $blue500;
                    box-shadow: 0 5px 8px 0 rgba(130, 130, 130, 0.2);
                    border-radius: 12px;
                  }
                  &.middle-selected {
                    height: 74px;
                    color: white;
                    font-weight: bold;
                    background-color: $orange500;
                    box-shadow: 0 5px 8px 0 rgba(130, 130, 130, 0.2);
                    border-radius: 12px;
                  }
                  &.low-selected {
                    height: 74px;
                    color: white;
                    font-weight: bold;
                    background-color: $red500;
                    box-shadow: 0 5px 8px 0 rgba(130, 130, 130, 0.2);
                    border-radius: 12px;
                  }
                }
              }
            }
          }
        }
        .summary-title {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > span {
            color: $grey900;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: -0.24px;
          }

          > .interviewer-box {
            display: flex;
            align-items: center;
            gap: 24px;

            > span {
              font-size: 16px;
              color: $grey500;
              font-weight: 500;
            }
          }
        }
        .summary-container {
          display: flex;
          width: 100%;
          gap: 20px;
          .left {
            display: flex;
            width: 90px;
            justify-content: center;
            align-items: center;
            .score-title {
              color: $grey700;
              font-size: 15px;
              font-style: normal;
              font-weight: 700;
              line-height: 140%;
              letter-spacing: -0.2px;
            }
          }

          .right {
            display: flex;
            width: calc(100% - 90px - 20px);
            .total-score-container {
              display: flex;
              width: 100%;
              gap: 20px;
              justify-content: center;
              align-items: center;
              .score-box {
                height: 24px;
                border-radius: 50px;
                background: $grey200;
                width: calc(100% - 70px - 20px);
                overflow: hidden;

                .score-bar {
                  border-radius: 50px;
                  height: 24px;
                  transition: width 1s ease-in-out;
                  background-color: $blue600;
                }
              }

              .score-percent {
                width: 70px;
                font-size: 20px;
                line-height: 145%;
                letter-spacing: -0.32px;

                > span {
                  color: $blue500;
                  font-size: 27px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 145%;
                  letter-spacing: -0.32px;
                }
              }
            }
          }
        }
        .flex {
          align-items: center;

          .left {
            flex: 7;
            .interview-content {
              color: #666;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin: 8px;
              height: 35px;
              padding: 10px;
              gap: 8px;
              border-radius: 8px;
              border: 1px solid #999;
              background: #fff;
            }

            .title-container {
              flex: 2;
              color: #333;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              margin-bottom: 20px;
            }

            .left-content {
              padding: 40px 16px;
            }
          }

          .right {
            flex: 3;
            justify-content: flex-end;
            gap: 8px;

            .tab-container {
              margin-bottom: 0;
            }

            .interview-content {
              display: flex;
              align-items: center;
              color: $grey900;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              height: 35px;
              padding: 6px 8px;
              gap: 8px;
              border-radius: 8px;
              border: 1px solid $grey300;
              background: $grey50;
              margin: 0 !important;
            }
            .invite-button {
              display: flex;
              align-items: center;
              color: $grey900;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              height: 35px;
              padding: 6px 8px;
              gap: 8px;
              border-radius: 8px;
              border: 1px solid $grey300;
              background: $grey50;
              cursor: pointer;
              &:hover {
                background-color: #d9d9d9;
              }
            }
          }
        }
        .comment-container {
          border: 1px solid $grey200;
          border-radius: 8px;
          overflow: hidden;
          width: 100%;

          textarea {
            width: 100%;
            padding: 20px 50px;
            height: 140px;
            font-size: 12px;
            font-weight: 500;
            color: $grey900;
            line-height: 20px;
            background-color: $grey100;
          }
        }

        .submit-button {
          margin-top: 40px;
          display: flex;
          justify-content: center;

          button {
            width: 100%;
            padding: 18px 0;
            text-align: center;
            display: inline-block;
            border-radius: 8px;
            font-weight: 700;

            &.gap {
              margin-right: 30px;
            }
          }
        }
        .interview-status-tab {
          .tab-container {
            margin-top: 32px;
            margin-bottom: 0;
            display: flex;

            > div {
              cursor: pointer;
              font-size: 13px;
              padding: 18px 0;
              width: 100%;
              color: white;
              text-align: center;
              display: inline-block;
              border-radius: 8px;
              font-weight: 700;

              &:not(:last-child) {
                margin-right: 16px;
              }
            }
          }
        }
      }

      &.document-detail {
        background-color: $grey50;

        .flex {
          display: flex;
        }

        hr {
          border-color: #e2e5e9;
          border-style: solid;
          border-width: 1px 0 0 0;
        }

        .salary-title {
          color: $grey800;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .information-container {
          padding: 24px 0;
          ul {
            display: flex;
            flex-wrap: wrap;
            gap: 24px 30px;

            li {
              flex-basis: calc(40% - 30px);
              display: flex;
              flex-direction: column;
              gap: 14px;

              span.sub-title {
                font-weight: 500;
                color: $grey600;
              }

              p.desc {
                font-weight: 700;
                color: $grey900;
              }
            }
          }
        }

        .flex {
          gap: 20px;
          margin-bottom: 32px;
          .left {
            flex: 4;
            .salary-info-box {
              background-color: white;
              width: 100%;
              padding: 24px;

              .start-date-box {
                display: flex;
                gap: 25px;
                align-items: center;

                &.reason {
                  margin-top: 20px;
                }
              }

              > :nth-child(2) {
                margin-top: 25px;
                display: flex;
                gap: 40px;
                font-size: 12px;

                div {
                  flex: 1;
                  > .title {
                    display: block;
                    color: $grey600;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    margin-bottom: 13px;
                  }

                  > .number-box {
                    display: flex;
                    margin-bottom: 20px;

                    :first-child {
                      color: $grey800;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 100%;
                    }
                    :last-child {
                      color: $grey600;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 100%;
                      display: flex;
                      justify-content: flex-end;
                    }
                  }
                }
              }

              :nth-child(4) {
                color: $grey700;
                text-align: right;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                letter-spacing: -0.2px;
              }

              :nth-child(5) {
                color: $grey900;
                text-align: right;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
                letter-spacing: -0.24px;
              }

              :nth-child(7) {
                color: $grey600;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                margin-bottom: 8px;
              }

              :nth-child(8) {
                color: $grey800;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                margin-bottom: 30px;
              }

              .salary-sub-title {
                color: $grey600;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
              }
            }
          }
          .right {
            display: flex;
            flex: 6;
            justify-content: flex-end;

            .tab-container {
              display: flex;
              gap: 8px;
              border-radius: 8px;
              border: 1px solid $grey200;
              background-color: $grey100;
              justify-content: center;
              align-items: stretch;
              cursor: pointer;
              padding: 10px;
              height: 80px;
              max-width: 400px;
              width: 100%;
              & > * {
                &:last-child {
                  &.active {
                    background-color: $red500;
                    color: $white;
                  }
                  &:hover {
                    background-color: $red500;
                    color: white;
                  }
                }
              }
              > .active {
                font-weight: 700;
                background-color: #3193ee;
                color: white;
              }
              > div {
                flex: 1;
                white-space: pre-wrap;
                display: flex;
                padding: 8px 20px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: $grey700;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-radius: 8px;
                transition: background 0.2s ease-in-out;
                &:hover {
                  background-color: #3193ee;
                  color: white;
                }
              }
            }

            > .salary-info-box {
              background-color: white;
              padding: 24px;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .salary-title {
                color: $grey800;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              .title {
                display: block;
                color: $grey600;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                font-size: 14px;
              }

              .desc {
                display: block;
                color: $grey900;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                font-size: 14px;
              }

              .doc-list-box {
                display: flex;
                gap: 20px;
                margin: 16px 0 16px 0;

                :first-child {
                  a {
                    color: $blue500;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                  }
                }
              }
            }
          }

          .refer {
            flex: 1;

            .reference-title {
              margin-top: 32px;
              margin-bottom: 20px;
              display: block;
            }
          }
        }
      }

      &.offer-detail {
        background-color: $grey50;

        .flex {
          display: flex;
          margin-bottom: 20px;
          justify-content: space-between;

          .left {
            .tagging {
              margin-bottom: 20px;

              p {
                display: inline-block;
                padding: 6px 12px;
                text-align: center;
                border-radius: 8px;
                background-color: $blue50;
                color: $blue500;
                font-weight: 700;
              }
            }

            .start-date-box {
              display: flex;
              align-items: center;
              gap: 20px;

              h6 {
                font-weight: 700;
                color: $grey800;
              }

              span {
                color: $grey600;
                font-weight: 500;
              }
            }
          }

          .right {
            .tab-container {
              display: flex;
              gap: 8px;
              border-radius: 8px;
              border: 1px solid $grey200;
              background-color: $grey100;
              justify-content: center;
              align-items: stretch;
              cursor: pointer;
              padding: 10px;
              height: 80px;
              width: 400px;
              & > * {
                &:last-child {
                  &.active {
                    background-color: $red500;
                    color: $white;
                  }
                  &:hover {
                    background-color: $red500;
                    color: white;
                  }
                }
              }
              > .active {
                font-weight: 700;
                background-color: #3193ee;
                color: white;
              }
              > div {
                flex: 1;
                white-space: pre-wrap;
                display: flex;
                padding: 8px 20px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: $grey700;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-radius: 8px;
                transition: background 0.2s ease-in-out;
                &:hover {
                  background-color: #3193ee;
                  color: white;
                }
              }
            }
          }
        }

        section.contents-section {
          padding: 24px;
          background-color: $white;
          border-radius: 4px;
          border: 1px solid $grey200;

          article.title-box {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid $grey200;

            h6 {
              font-weight: 700;
              color: $grey800;
            }
          }

          article.contents-box {
            span.grey-label {
              display: inline-block;
              font-size: 12px;
              font-weight: 500;
              line-height: 100%; /* 12px */
              color: $grey600;
            }

            .salary-box {
              margin-bottom: 34px;

              span {
                margin-bottom: 14px;
              }

              p {
                color: $grey800;
                font-weight: 700;
              }
            }

            .etc-box {
              span {
                margin-bottom: 8px;
              }

              p {
                color: $grey800;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}

.upload-candidate-file {
  background-color: $app_background_color;
  border-radius: 8px;
  width: 400px;
  //height: 400px;
  padding: 16px;

  > .modal-header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  > .modal-main {
    display: flex;
    height: 350px;
    flex-direction: column;
    gap: 16px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    >.apply-input-box{
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.edit-candidate-email {
  background-color: $app_background_color;
  border-radius: 8px;
  width: 400px;
  padding: 16px;

  > .modal-header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  > .modal-main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    >.apply-input-box{
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.modal-candidate-list-detail {
  min-width: 1204px;
  overflow-x: auto;
  overflow-y: auto !important;
  background-color: $white !important;
  display: flex;
  flex-direction: column;
  gap: 67px;

  > .header {
    padding: 78px 47px 24px;
    background-color: white;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;

    > .header-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 40px;
      height: 100%;

      > .header-sub-title-left {
        color: #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        min-width: 200px;

        &.null {
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
        &::after {
          content: url('/assets/images/icon/candidate-detail-arrow-left.svg');
          width: 29px;
          height: 29px;
        }
      }

      > .header-sub-title-right {
        color: #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        min-width: 200px;

        &.null {
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
        &::before {
          content: url('/assets/images/icon/candidate-detail-arrow-right.svg');
          width: 29px;
          height: 29px;
        }
      }

      > .header-title {
        font-weight: bold;
      }

      > .header-close {
        cursor: pointer;
        width: 36px;
        height: 36px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        &::after {
          content: '';
          background: url('/assets/images/icon/close-black.svg');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  > .container {
    margin: 0 auto;
    margin-bottom: 40px;
    width: 100%;
    padding: 0 47px;

    .wrapper {
      border-radius: 8px;
      padding: 40px 32px;

      &.candidate-list-detail {
        background-color: $grey50;

        .tagging {
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          gap: 8px;

          p {
            display: inline-block;
            padding: 6px 12px;
            text-align: center;
            border-radius: 8px;
            background-color: $blue50;
            color: $blue500;
            font-weight: 700;
          }
        }

        > .information-container {
          .information-wrapper {
            display: flex;
            flex-direction: column;
            gap: 32px;

            &:not(:first-child) {
              padding-top: 32px;
            }

            &:not(:last-child) {
              padding-bottom: 32px;
              border-bottom: 1px solid $grey300;
            }

            .main-title {
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              color: $blue500;
            }

            ul {
              display: flex;
              flex-wrap: wrap;
              gap: 24px 30px;

              li {
                flex-basis: calc(20% - 30px);
                display: flex;
                flex-direction: column;
                gap: 14px;

                span.sub-title {
                  font-weight: 500;
                  color: $grey600;
                }

                p.desc {
                  font-weight: 700;
                  color: $grey900;
                }
              }
            }
          }
        }

        > .comment-container {
          padding-top: 24px;
          border-top: 1px solid $grey300;

          .comment-wrapper {
            ul {
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;

              li {
                display: flex;
                gap: 32px;

                .comment {
                  flex: 6;

                  pre {
                    color: $grey800;
                    font-weight: 400;
                    word-break: keep-all;
                  }
                }

                .write-info {
                  flex: 4;
                  display: flex;
                  gap: 20px;

                  > div {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;

                    &.writer {
                      width: 90px;
                    }

                    &.date {
                      width: 140px;
                    }

                    span.title {
                      color: $grey600;
                      font-weight: 500;
                    }

                    p {
                      font-weight: 700;
                      color: $grey900;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-invited-interviewer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .invited-interviewer-container {
    max-width: 560px;
    width: 100%;
    overflow-y: auto;
    background-color: $white;
    padding: 24px 40px 48px 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;

    > .invited-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding-bottom: 16px;

      h3 {
        font-weight: 700;
        color: $grey900;
      }
    }

    > .invited-title-wrapper {
      padding: 8px;
      text-align: center;

      h2 {
        font-weight: 700;
      }
    }

    > .members-wrapper {
      .member-list-slide-box {
        .members-title-box {
          display: flex;
          gap: 10px;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          padding: 16px 14px;
          border: 1px solid $grey300;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          background-color: $grey50;
          cursor: pointer;

          aside.title-box {
            .apply-agree-title {
              font-weight: 500;
              color: $grey900;
            }
          }

          aside.icon {
            width: 10px;

            svg {
              transition: all 0.2s ease-out;
            }
          }

          &.true {
            aside.icon {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }

        .member-list-container {
          overflow: hidden;

          .member-list-wrapper {
            border: 1px solid $grey300;
            border-top: none;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            &.slide-in {
              animation: slide-in ease-out 0.15s;
            }

            &.slide-out {
              animation: slide-out ease-out 0.15s forwards;
            }

            ul {
              max-height: 292px;
              overflow-y: auto;

              li {
                padding: 16px;
                display: flex;
                align-items: center;
                gap: 10px;
                border-bottom: 1px solid $grey100;
                box-sizing: border-box;
                transition: all 0.2s ease-in-out;

                &.selected {
                  background-color: $grey100;
                }

                aside.initial-box {
                  .round {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 800;

                    &.light-green {
                      background-color: $green500;
                    }
                    &.orange {
                      background-color: $red500;
                    }
                    &.indigo {
                      background-color: $indigo600;
                    }
                    &.purple {
                      background-color: $purple500;
                    }
                    &.pink {
                      background-color: $pink500;
                    }
                    &.black {
                      background-color: $grey900;
                    }
                    &.green {
                      background-color: $grey700;
                    }
                    &.blue {
                      background-color: $blue500;
                    }
                  }
                }

                aside.info-box {
                  h6 {
                    font-weight: 700;
                  }

                  p {
                    font-weight: 500;
                    color: $grey500;
                  }
                }
              }
            }
          }
        }

        .submit-button {
          margin-top: 16px;
          display: flex;

          div {
            flex: 1;
            margin: 8px;
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.modal-invited-member {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 560px;
  height: 100%;

  &.modal-successful-modal {
    margin: 0 auto;
  }

  &.modal-offer-modal {
    margin: 0 auto;

    section.invited-member-container {
      article.form-contents-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .flex {
          display: flex;
          gap: 8px;

          > * {
            &:first-child {
              flex: 8;
            }
            &:last-child {
              flex: 3;
            }
          }
        }

        .currency-form {
          .input-label {
            opacity: 0;
          }

          .dropdown-box {
            top: 78%;
          }
        }

        aside {
          span.grey-title {
            font-weight: 500;
            color: $grey700;
          }

          textarea.etc-text-box {
            margin-top: 8px;
            display: block;
            width: 100%;
            height: 115px;
            padding: 16px 14px;
            border: 1px solid $grey300;
            border-radius: 4px;
          }
        }
      }

      article.total-salary-wrapper {
        text-align: right;
        display: flex;
        flex-direction: column;
        gap: 6px;

        > span {
          color: $grey700;
          font-weight: 500;
        }

        > p {
          color: $grey900;
          font-weight: 700;
        }
      }
    }
  }

  &.modal-update-member-type {
    .invited-member-container {
      min-height: auto;
    }
  }

  &.modal-update-score {
    margin: 0 auto;

    .invited-member-container {
      min-height: 330px;

      .invited-form-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .update-form-title-box {
          h6 {
            font-weight: 500;
            color: $grey700;
          }
        }

        .update-score-box {
          display: flex;
          align-items: center;
          gap: 20px;
          justify-content: center;

          .form-box {
            display: flex;
            align-items: center;
            gap: 8px;

            .score-input {
              display: inline-block;
              padding: 14px;
              text-align: center;
              height: 90px;
              width: 128px;
              border: 1px solid $grey300;
              background-color: $white;
              border-radius: 4px;
              font-size: 40px;
              font-style: normal;
              font-weight: 800;
              line-height: 145%; /* 58px */
              letter-spacing: -0.4px;
              color: $blue600;
            }

            span {
              font-weight: 700;
              color: $grey700;
            }
          }
        }
      }
    }
  }

  &.modal-confirm-date {
    margin: 0 auto;

    .invited-member-container {
      min-height: auto;
    }
  }

  .invited-member-container {
    min-height: 400px;
    width: 100%;
    background-color: $white;
    padding: 24px 40px 48px 40px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    border-radius: 8px;
    max-height: 90%;

    > .invited-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;

      h3 {
        font-weight: 700;
        color: $grey900;
      }

      span {
        cursor: pointer;
      }
    }

    > .invited-form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }

    > .invited-button {
      button {
        width: 100%;
      }
    }
  }
}

.modals-change-status {
  width: 90vw;
  height: 90vh;
  max-width: 312px;
  max-height: 174px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > .title {
    width: 100%;
    height: calc(100% - 56px);
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 8px;

    > .title-main {
      font-weight: bold;
      word-break: keep-all;
    }

    > .title-sub {
      word-break: keep-all;
      color: $grey500;
    }
  }
}

.modal-change-recruitment {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .change-recruitment-container {
    max-width: 560px;
    width: 100%;
    overflow: visible;
    background-color: $white;
    padding: 24px 40px 48px 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;

    > .change-recruitment-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding-bottom: 16px;
      margin-bottom: 30px;

      h3 {
        font-weight: 700;
        color: $grey900;
      }
    }
    .submit-button {
      width: 100%;
      margin-top: 45px;

      button {
        width: 100%;
        padding: 18px 0;
        text-align: center;
        display: inline-block;
        border-radius: 8px;
        font-weight: 700;
      }
    }
  }
}

.calendar-container {
  padding: 20px;
  position: relative;

  .outlook-login {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(165, 165, 165, 0.5);

    :first-child {
      position: absolute;
      width: 360px;
      height: 56px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
