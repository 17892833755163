@import '../include.scss';

#divFooter {
  margin: 80px auto 0;
  width: 160px;
  height: 35px;
  background-image: url('/assets/images/logo/powerdby-logo.svg');
  background-size: contain;
}

.subjective-answer {
  width: 100%;
  overflow: auto;
  // word-break: break-all;
  word-break: keep-all;
  word-wrap: break-word;
  > pre {
    word-break: keep-all;
    word-wrap: break-word;
  }
  > .sendfile {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    height: 32px;
    background: #ffffff;
    border: 1px solid $blue500;
    border-radius: 4px;
    color: $blue500;
    font-weight: 500;
    // font-size: 12px;
    // line-height: 130%;
    letter-spacing: -0.02em;
    text-align: center;
  }
}

section.answer {
  margin-bottom: 16px;
}

@media print {
  @page {
    size: A4;
    margin: 10mm 0;
  }

  .subjective-answer {
    max-width: 660px;
    overflow: auto;
    // word-break: break-all;
    word-break: keep-all;
    word-wrap: break-word;
  }

  // #pageNumber {
  //     position: fixed;
  //     bottom: 0;
  //     right: 40px;
  //     // counter-increment: page;

  //     &:after {
  //         content: counter(page) " of " counter(pages);
  //         counter-increment: page;
  //     }
  // }

  // #divFooter{
  //     position: fixed;
  //     width:160px;
  //     height:35px;
  //     bottom: 10px;
  //     left: 50%;
  //     transform: translate(-50%, 0);
  //     background-image: url('/assets/images/logo/powerdby-logo.svg');
  //     background-size: contain;
  // }
  body {
    margin: 0;
    -webkit-print-color-adjust: exact !important;
  }
  img,
  svg,
  .alert,
  .info {
    break-inside: avoid;
  }
  .page-break {
    display: block;
    page-break-after: auto;
  }
}

ul.answer-description-list {
  > li.answer-description-list-item {
    &:not(&:last-child) {
      margin-bottom: 16px;
    }

    > .container {
      display: flex;
      align-items: center;

      > .radio-button {
        color: $blue500;
        margin-right: 4px;
      }

      > .text {
        flex: 1;
        padding: 8px;
        background-color: $grey100;
        border: 1px solid $grey300;
        border-radius: 4px;
      }
    }

    &.answer {
      > .container {
        > .text {
          border: 1px solid $blue500;
        }
      }
    }
  }
}

.currect > .answer-title {
  font-weight: 700;
  // font-size: 12px;
  // line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
}

.pages-protected-candidates-list {
  padding-top: 0px !important;

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 34px;
    height: 104px;

    > .header-title {
      // font-size: 28px;
      font-weight: bold;
    }

    > .header-right {
      display: flex;
      gap: 16px;

      > .header-right-download {
        display: flex;
        gap: 16px;

        .header-rigth-button-wrap {
          position: relative;

          .header-right-resume {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 13.25px;
          }
        }

        button {
          height: 56px;
          border-radius: 4px;
          padding: 8px 24px;
          transition: all 0.2s;
        }

        .option-wrap {
          position: absolute;
          top: 100%;
          left: -10px;
          overflow: hidden;
          box-sizing: content-box;
          padding: 10px;
          z-index: 3;

          .option-list {
            padding: 8px 0;
            max-width: 380px;
            min-width: 280px;
            white-space: nowrap;
            width: auto;
            overflow: auto;
            background-color: $white;
            box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
              0px 1px 2px rgba(0, 0, 0, 0.3);
            border-radius: 4px;

            .option-list-item {
              padding: 12px 16px;
              transition: all 0.2s;
              cursor: pointer;

              &:hover {
                background-color: $grey100;
              }
            }

            &.slide-in {
              animation: slide-in ease-out 0.15s;
            }

            &.slide-out {
              animation: slide-out ease-out 0.15s forwards;
            }
          }

          @keyframes slide-in {
            0% {
              transform: translateY(-100%);
            }

            100% {
              transform: translateY(0);
            }
          }

          @keyframes slide-out {
            0% {
              transform: translateY(0);
            }

            100% {
              transform: translateY(calc(-100% - 10px));
            }
          }
        }
      }

      > .header-right-search {
        > .header-right-search-input {
          width: 360px;
          height: 56px;
        }
      }
    }

    > .header-create {
      padding: 0 16px;
      height: 56px;
      border-radius: 36px;

      &::before {
        content: url('/assets/images/icon/plus.svg');
        position: relative;
        top: 2px;
        margin-right: 11px;
      }
    }
  }

  > .tag {
    text-align: right;
    margin-bottom: 16px;

    .tag-wrap {
      display: inline-block;
      background-color: $white;

      .dropdown-list-box {
        left: auto;
        right: -10px;
        max-width: 380px;
        min-width: 280px;
        width: auto;
      }
    }
  }

  > .table {
    > .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      > .table-header-left {
        > .table-header-left-search {
          width: 360px;
          height: 56px;
        }
      }

      > .table-header-right {
        display: inline-flex;
        align-items: center;
        gap: 16px;

        > .select {
          width: 240px;
          height: 56px;
        }
      }
    }

    .align-right {
      text-align: right;
    }

    .detail {
      > .detail-icon {
        width: 24px;
        height: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &::after {
          content: '';
          width: 12px;
          height: 20px;
          mask-image: url('/assets/images/icon/chevron-right.svg');
          mask-repeat: no-repeat;
          mask-position: center;
          mask-size: contain;
          background-color: black;
        }
      }
    }
    .assessment {
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        max-width: 350px;

        > span {
          flex-direction: row;
          align-items: flex-start;
          padding: 6px 12px;
          // max-width: 124px;
          height: 32px;
          background: $purple50;
          border-radius: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 145%;
          align-items: center;
          text-align: center;
          letter-spacing: -0.01em;
          color: $grey900;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 40%;
        }

        > p {
          padding: 6px 12px;
          background-color: $grey50;
          border-radius: 8px;
        }
      }
    }

    .empty {
      margin: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > .thumbnail {
        width: 360px;
        height: 360px;
        background-image: url('https://user-images.githubusercontent.com/24651852/183318936-a5fcac30-c841-45e9-8fcb-636700c1a479.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 40px;
      }

      > p {
        // font-size: 28px;
        text-align: center;
        // line-height: 36.4px;

        > span {
          color: $blue500;
        }
      }
    }
  }
}

.pages-protected-candidates-statistic {
  padding-top: 0px !important;
  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 34px;
    height: 104px;

    > .header-title {
      // font-size: 28px;
      font-weight: bold;
    }
  }
  > .graph {
    width: 100%;
    display: flex;
    gap: 32px;
    flex-direction: column;
    > .row {
      width: 100%;
      display: flex;
      > .col {
        flex: 1 1;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        > .graph-title {
          margin: 0 auto;
        }
        > .recharts-wrapper {
          margin: 0 auto;
        }
        > .legend {
          width: 100%;
          padding: 0 20px;
          gap: 8px;
          display: flex;
          margin: 0 auto;
          text-align: center;
          justify-content: center;
          margin-top: -10px;
          > .legend-cell {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            > .bullet {
              margin-top: -5px;
              margin-right: -3px;
            }
          }
        }
      }
    }
  }
}

.pages-protected-candidates-print {
  width: 100% !important;
  background-color: $white;
  padding: 10px 40px !important;
  color: $black;
  letter-spacing: -0.01em;
  max-width: 794px !important;

  > .header {
    flex-direction: inherit;
    align-items: flex-start !important;
    width: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0 !important;
    > .logo {
      display: flex;
      width: 120px;
      height: 45px;
      left: 0px;
      top: 0px;
      align-items: center;
      img {
        max-width: 120px;
        max-height: 45px;
        margin: 0 auto;
      }
    }
    > .test-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0px;
      // font-size: 14px;
      // line-height: 145%;
      height: 20px;
      > .my-assessments {
        font-weight: 700;
      }
      > .test {
        font-weight: 400;
        margin-left: 16px;
      }
    }
  }
}
.pages-protected-candidates-detail,
.pages-protected-candidates-print {
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 24px;

    > .header-left {
      display: inline-flex;
      gap: 16px;

      > .header-left-go-back {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        border-radius: 50%;

        &::after {
          content: url('/assets/images/icon/arrow-left.svg');
        }
      }

      > .header-left-title {
        > .header-left-title-name {
          // font-size: 28px;
          font-weight: bold;
          margin-bottom: 8px;
        }

        > .header-left-title-email {
        }
      }
    }

    > .header-right {
      display: inline-flex;
      gap: 24px;

      > .header-right-select {
        width: 240px;
        height: 56px;
      }

      > .header-right-invite {
        padding: 0 24px;
        height: 56px;
        border-radius: 36px;
        min-width: 110px;
      }
    }
  }
  > .gucci {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px;
    gap: 32px;
    background: $white;
    margin-bottom: 32px;

    > .title {
      height: 28px;
      font-weight: 700;
      display: flex;
      align-items: center;
      color: $black;
    }
    > .row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 100%;
      height: auto;
      > .cell {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        height: auto;
        > .title {
          font-weight: 500;
          display: flex;
          align-items: center;
          color: $grey700;
        }
        > .content {
          font-weight: 500;
          display: flex;
          align-items: center;
          color: $grey900;
          > a {
            text-decoration: none;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 12px;
            gap: 8px;
            height: 40px;
            background: $white;
            border: 1px solid $grey300;
            border-radius: 4px;
            font-style: normal;
            font-weight: 500;
            text-align: center;
            color: $grey900;
          }
        }
      }
    }
  }

  > .no-information-alert {
    background-color: $white;
    padding: 24px;
    text-align: center;

    h3 {
      font-weight: 500;
      color: $grey700;
    }
  }
}

.print-button {
  cursor: pointer;
  height: 40px;
  margin-left: 16px;
  background: #ffffff;
  justify-content: center;
  color: $blue500;
  align-items: center;
  border: 1px solid $blue500;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  // font-size: 16px;
  // line-height: 120%;
  text-align: center;
  padding: 8px 12px;
  letter-spacing: -0.01em;
  &:hover {
    background: $blue50;
  }

  &:active {
    background: #ffffff;
  }
  > .icon {
    width: 15px;
    height: 13.5px;
    margin-right: 9.5px;
  }
}

.results-section,
.pages-protected-candidates-detail-assessment,
.pages-protected-candidates-detail-assessment-print {
  .single-chart {
    width: 100%;
    justify-content: space-around;
  }

  .circular-chart {
    display: block;
    max-width: 100%;
    max-height: 250px;
  }

  .circle-bg {
    fill: none;
    stroke: $grey100;
    stroke-width: 3.8;
  }

  .circle {
    fill: none;
    stroke-width: 3.8;
    stroke-linecap: butt;
    animation: progress 1s ease-out forwards;
  }

  .circle-noani {
    fill: none;
    stroke-width: 3.8;
    stroke-linecap: butt;
    animation: progress 0s ease-out forwards;
  }

  .circle-bg-khai {
    fill: none;
    stroke: $grey100;
    stroke-width: 1;
  }

  .circle-khai {
    fill: none;
    stroke-width: 1;
    stroke-linecap: butt;
    animation: progress 1s ease-out forwards;
  }

  .circle-noani-khai {
    fill: none;
    stroke-width: 1;
    stroke-linecap: butt;
    animation: progress 0s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circular-chart.blue600 > .circle,
  .circular-chart.blue600 > .circle-khai,
  .circular-chart.blue600 > .circle-noani,
  .circular-chart.blue600 > .circle-noani-khai {
    stroke: $blue600;
  }
  .circular-chart.blue > .circle,
  .circular-chart.blue500 > .circle-khai,
  .circular-chart.blue > .circle-noani,
  .circular-chart.blue500 > .circle-noani-khai {
    stroke: $blue500;
  }
  .circular-chart.red > .circle,
  .circular-chart.red500 > .circle-khai,
  .circular-chart.red > .circle-noani,
  .circular-chart.red500 > .circle-noani-khai {
    stroke: $red500;
  }
  .circular-chart.red700 > .circle-khai,
  .circular-chart.red700 > .circle-noani-khai {
    stroke: $red700;
  }
  .circular-chart.orange > .circle,
  .circular-chart.orange500 > .circle-khai,
  .circular-chart.orange > .circle-noani,
  .circular-chart.orange500 > .circle-noani-khai {
    stroke: $orange500;
  }

  .circular-chart.orange-opc > .circle,
  .circular-chart.orange-opc > .circle-khai,
  .circular-chart.orange-opc > .circle-noani,
  .circular-chart.orange-opc > .circle-noani-khai {
    stroke: $orange500;
    opacity: 0.8;
  }
  .circular-chart.red-opc > .circle,
  .circular-chart.red-opc > .circle-khai,
  .circular-chart.red-opc > .circle-noani,
  .circular-chart.red-opc > .circle-noani-khai {
    stroke: #ff0000;
    opacity: 0.8;
  }
  .circular-chart.red500-opc > .circle,
  .circular-chart.red500-opc > .circle-noani {
    stroke: $red500;
    opacity: 0.8;
  }

  background-color: white;
  padding: 40px 32px;
  // overflow: hidden;
  letter-spacing: -0.01em;

  &.show {
    > .header {
      > .header-fold {
        &::after {
          transform: none;
        }
      }
    }

    > .tabs,
    > .result,
    > .info {
    }
  }

  &.hide {
    > .header {
      margin-bottom: 0;

      > .header-fold {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    > .tabs,
    > .result,
    > .info,
    > .results-section {
      display: none;
      height: 0;
      margin-bottom: 0;
    }
  }

  &:not(&:last-child) {
    margin-bottom: 40px;
  }

  > .user-info {
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    flex: none;
    margin: 20px 0 40px;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    &.khai {
      margin: 8px 0 8px 0;
      display: flex;
      align-items: center;
      gap: 16px;
      > .name {
        height: auto;
        font-weight: 500;
      }

      .e-mail {
        margin: 0;
      }
      .completed {
        margin: 0;
        color: black;
      }

      .title {
        color: $grey700;
      }
    }
    > .name {
      height: 47px;
      font-weight: 700;
      // font-size: 36px;
      // line-height: 130%;
      display: flex;
      align-items: center;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    > .e-mail {
      margin-top: 8px;
      height: 23px;
      font-weight: 500;
      // font-size: 16px;
      // line-height: 145%;
      display: flex;
      align-items: center;
    }
    > .completed {
      margin-top: 8px;
      height: 20px;
      font-weight: 500;
      // font-size: 14px;
      // line-height: 145%;
      display: flex;
      align-items: center;
      // letter-spacing: -0.01em;
      color: $grey700;
    }
  }

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    > .header-title {
      > .header-title-main {
        margin-bottom: 8px;
        height: 40px;
        display: flex;
        align-items: center;

        span {
          &:nth-child(1) {
            // font-size: 28px;
            font-weight: bold;
            margin-right: 16px;
          }

          // &:nth-child(2) {
          //     font-size: 28px;
          // }
        }
      }

      > .header-title-sub {
        // font-size: 14px;
        color: $grey500;
      }
    }

    > .header-fold {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &.block {
        &::after {
          transform: none;
        }
      }

      &.none {
        &::after {
          transform: rotate(180deg);
        }
      }

      &::after {
        content: url('/assets/images/icon/chevron-top.svg');
        position: relative;
      }
    }
  }

  > .tabs {
    margin-bottom: 40px;
    overflow: hidden;
    transition: height 0.2s;

    > ul.tabs-list {
      display: flex;
      border-bottom: 1px solid $grey100;

      > li.tabs-list-item {
        > .container {
          cursor: pointer;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 64px;
          padding: 0 24px;
          position: relative;
          transition: background-color 0.2s;

          &:after {
            content: '';
            z-index: 2;
            position: absolute;
            bottom: 0;
            width: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 2px;
            background-color: #3294ee;
          }

          > span {
            font-weight: 700;
          }

          &:hover {
            background-color: $grey100;
            border-radius: 4px;
          }

          &.selected {
            * {
              color: $blue500;
            }
            // border-bottom: 2px solid $blue500;
            &:after {
              width: 59px;
            }
          }
        }
      }
    }
  }

  > .result {
    overflow: hidden;

    > .result-hiring-stage {
      display: flex;
      justify-content: space-between;
      padding-bottom: 18px;
      margin-bottom: 40px;

      > .result-hiring-stage-left {
        > .result-hiring-stage-left-title {
          // font-size: 20px;
          font-weight: bold;
          margin-bottom: 16px;
        }

        > .result-hiring-stage-left-description {
          border: 1px solid $grey300;
          width: 200px;
          height: 32px;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 8px;
          border-radius: 8px;
          position: relative;
          cursor: pointer;

          &.disabled {
            cursor: default;
            border-color: $grey100;
          }

          > span {
          }

          > .status-left {
            display: flex;
            align-items: center;
            gap: 12px;

            > .progress-icon {
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }

            &.step--1 {
              .progress-icon {
                background-color: $grey300;
              }
            }

            &.step-1 {
              .progress-icon {
                background-color: $green500;
              }
            }

            &.step-2 {
              .progress-icon {
                background-color: $orange500;
              }
            }

            &.step-3 {
              .progress-icon {
                background-color: $red500;
              }
            }

            &.step-4 {
              .progress-icon {
                background-color: $blue500;
              }
            }

            &.step-0 {
              .progress-icon {
                background-color: $grey300;
              }

              &.step-leave-true {
                .progress-icon {
                  background-color: $grey500;
                }
              }
            }
          }
          > .status-right {
            font-size: 0;
          }

          > .candidates-stage-dropdown {
            position: absolute;
            z-index: 100;
            top: 32px;
            left: 0px;
            width: 100%;
            background-color: white;

            > ul.candidates-stage-dropdown-list {
              display: flex;
              flex-direction: column;
              box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
                0px 1px 2px rgba(0, 0, 0, 0.3);
              border-radius: 4px;
              padding: 6px 0;

              > li.candidates-stage-dropdown-list-item {
                cursor: pointer;
                width: 100%;
                height: 48px;
                display: inline-flex;
                gap: 12px;
                align-items: center;
                padding: 0 16px;
                position: relative;

                &:hover {
                  background-color: $grey100;
                }

                .candidates-stage-dropdown-status-icon {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;

                  &.under-investigation {
                    background-color: $orange500;
                  }
                  &.accept {
                    background-color: $blue500;
                  }
                  &.reject {
                    background-color: $red500;
                  }
                }
              }
            }
          }
        }
      }

      > .result-hiring-stage-right {
        > .result-hiring-stage-right-title {
          // font-size: 20px;
          font-weight: bold;
          margin-bottom: 16px;
        }

        > .result-hiring-stage-right-buttons {
          display: inline-flex;
          gap: 16px;

          > button {
            padding: 8px 16px;
            border-radius: 100px;

            &::after {
              content: url('/assets/images/icon/email.svg');
              margin-left: 10px;
              position: relative;
              top: 3px;
            }
          }
        }
      }
    }

    > .result-objective {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 40px;
      width: 100%;
      position: relative;

      .khai-retry-button-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      > .result-khai-p-section {
        width: 100%;

        ul {
          list-style-type: disc;
        }
        > .khai-logo {
          margin-bottom: 16px;
        }
        > .result-khai-p-title {
          font-weight: bold;
          color: $grey900;
          margin-bottom: 16px;
        }
        > .result-khai-p-wrap {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          width: 100%;
          > .result-khai-p-overall {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            width: 100%;
            // height: 448px;
            > .result-khai-p-overall-result {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 20px;
              gap: 16px;
              width: 400px;
              // height: 448px;
              border: 1px solid $grey200;
              border-right-width: 0;
              border-bottom-width: 0;
              > .title {
                width: 100%;
                height: 26px;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                color: $grey900;
              }
              > .score {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px 0px 8px;
                gap: 16px;
                width: 360px;
                height: 144px;
                > .overall-grade {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 0px;
                  gap: 8px;
                  width: 172px;
                  height: 136px;
                  > .title {
                    // width: 100%;
                    height: 20px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    // text-align: center;
                    margin: 0 auto;
                    color: $grey700;
                  }
                  > .grade {
                    width: 108px;
                    height: 108px;
                  }
                }
                > .overall-score {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 0px;
                  gap: 8px;
                  width: 172px;
                  height: 136px;
                  > .title {
                    // width: 100%;
                    height: 20px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    // text-align: center;
                    margin: 0 auto;
                    color: $grey700;
                  }
                  > .score-graph {
                    width: 108px;
                    height: 107.02px;
                    > .percentage {
                      text-align: center;
                      position: relative;
                      bottom: 65%;
                      > .number {
                        font-weight: 700;
                      }
                      > .unit {
                        font-weight: 700;
                      }
                    }
                  }
                }
              }
              > .candidates-rank,
              > .bigdata-rank,
              > .response-confidence {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 16px 20px;
                // gap: 16px;
                width: 360px;
                height: 58px;
                background: $grey50;
                border-radius: 8px;
                justify-content: space-between;
                > .title {
                  // width: 209px;
                  height: 26px;
                  font-style: normal;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  color: $grey900;
                }
                > .rank {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-end;
                  justify-content: flex-end;
                  padding: 0px;
                  gap: 4px;
                  // width: 180px;
                  height: 26px;
                  > .my-rank {
                    height: 26px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: $grey900;
                  }
                  > .base {
                    height: 20px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.01em;
                    color: #89949f;
                  }
                  > .desc {
                    height: 26px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    &.blue500 {
                      color: $blue500;
                    }
                    &.red500 {
                      color: $red500;
                    }
                    &.orange500 {
                      color: $orange500;
                    }
                  }
                }
              }
            }
            > .result-hkai-p-ovrall-score-graph {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 20px 17px;
              padding-bottom: 28px;
              gap: 16px;
              width: 896px;
              // height: 448px;
              border: 1px solid $grey200;
              border-bottom-width: 0px;
              > .title {
                width: 100%;
                height: 26px;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                color: $grey900;
              }
              > .graph {
                width: 862px;
                height: 284px;
                position: relative;
                > .candidate-score {
                  position: absolute;
                  left: 16px;
                  top: 3px;
                  margin-left: 16px;
                }
              }
            }
          }
          > .result-khai-p-result-by-propensity {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            gap: 16px;
            width: 100%;
            height: 338px;
            border: 1px solid $grey200;
            border-bottom-width: 0px;
            > .title {
              width: 100%;
              height: 26px;
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $grey900;
            }
            > .result {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0px;
              gap: 40px;
              width: 100%;
              height: 256px;
              > .radar-graph {
                width: 360px;
                height: 256px;
                display: flex;
                flex-direction: column;
                align-items: center;
                > .graph {
                  width: 289.71px;
                  height: 256px;
                }
                > .legend {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  padding: 0px;
                  gap: 24px;
                  height: 16px;
                  justify-content: center;
                  > .candidate {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 4px;
                    height: 16px;
                    > .title {
                      height: 16px;
                      font-style: normal;
                      font-weight: 700;
                      display: flex;
                      align-items: center;
                      color: $grey900;
                    }
                    > .bullet {
                      width: 10px;
                      height: 10px;
                      background: $blue500;
                      border-radius: 5px;
                    }
                  }
                  > .bigdata {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 4px;
                    height: 16px;
                    > .title {
                      height: 16px;
                      font-style: normal;
                      font-weight: 700;
                      display: flex;
                      align-items: center;
                      color: $grey900;
                    }
                    > .bullet {
                      width: 10px;
                      height: 10px;
                      background: $green500;
                      border-radius: 5px;
                    }
                  }
                }
              }
              > .result-desc {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                gap: 16px;
                width: 856px;
                height: 214px;
                > .propensity {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 20px;
                  gap: 24px;
                  width: 274.67px;
                  height: 214px;
                  background: $grey50;
                  border-radius: 8px;
                  > .title {
                    width: 100%;
                    height: 26px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    color: $grey900;
                  }
                  > .grade {
                    width: 60px;
                    height: 60px;
                  }
                  > .desc {
                    width: 100%;
                    height: 40px;
                    font-style: normal;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: $grey700;
                  }
                }
              }
            }
          }
          > .result-khai-p-detail-result {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 20px;
            gap: 16px;
            width: 100%;
            height: 400px;
            border: 1px solid $grey200;
            > .title {
              width: 100%;
              height: 26px;
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $grey900;
            }
            > .detail-result {
              width: 100%;
              height: 266px;
              display: flex;
              gap: 16px;
              > .propensity {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 16px 0px;
                gap: 4px;
                width: 100%;
                height: 266px;
                background: $grey50;
                border-radius: 8px;
                > .title {
                  width: 100%;
                  height: 26px;
                  font-style: normal;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  justify-content: center;
                  color: $grey900;
                }
                > .graphs {
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  padding: 0px;

                  > .graph {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 4px;
                    height: 204px;
                    width: 135px;
                    > .inner {
                      box-sizing: border-box;
                      border-bottom: 1px solid $grey200;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: flex-end;
                      padding: 0px 40px;
                      gap: 10px;
                      width: 135px;
                      height: 160px;
                      position: relative;
                      > .graph {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-end;
                        width: 55px;
                        height: 160px;
                        margin: 0 auto;
                        position: relative;
                        > .my-score {
                          width: 31px;
                          height: 100px;
                          background: linear-gradient(
                            180deg,
                            #3294ee 0%,
                            #0066ff 100%
                          );
                          position: absolute;
                          left: 12px;
                        }
                        > .bigdata-score {
                          width: 55px;
                          height: 80px;
                          background: rgba(36, 193, 133, 0.3);
                          border-top: 2px solid $green500;
                          position: absolute;
                          left: 0;
                        }
                        > .tooltip {
                          display: none;
                          flex-direction: row;
                          align-items: flex-start;
                          padding: 8px;
                          gap: 10px;
                          width: 300px;
                          background: $grey100;
                          border-radius: 4px;
                          font-style: normal;
                          font-weight: 500;
                          align-items: center;
                          color: $grey900;
                          margin-left: -150px;
                        }
                        &:hover {
                          background: $grey100;
                        }
                        &:hover > .tooltip {
                          display: block;
                          position: fixed;
                          z-index: 30;
                        }
                      }
                    }
                    > .desc {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      padding: 0px;
                      height: 40px;
                      > .title {
                        width: 135px;
                        height: 20px;
                        font-style: normal;
                        font-weight: 700;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        color: $grey900;
                      }
                      > .score {
                        width: 135px;
                        height: 20px;
                        font-style: normal;
                        font-weight: 700;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        color: $grey900;
                      }
                    }
                  }
                }
              }
            }
            > .legend {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 0px;
              gap: 24px;
              height: 16px;
              > .candidate {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 4px;
                height: 16px;
                > .title {
                  height: 16px;
                  font-style: normal;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  color: $grey900;
                }
                > .bullet {
                  width: 10px;
                  height: 10px;
                  background: $blue500;
                  border-radius: 5px;
                }
              }
              > .bigdata {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 4px;
                height: 16px;
                > .title {
                  height: 16px;
                  font-style: normal;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  color: $grey900;
                }
                > .bullet {
                  width: 10px;
                  height: 10px;
                  background: $green500;
                  border-radius: 5px;
                }
              }
            }
          }
          > .result-khai-p-comment {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px;
            gap: 8px;
            width: 100%;
            border: 1px solid $grey200;
            border-top: none;
            > .title {
              width: 100%;
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $grey900;
            }

            .interview-container {
              display: flex;
              flex-direction: column;
              gap: 16px;
              width: 100%;

              .interview-wrapper {
                display: flex;
                align-items: stretch;
                align-self: stretch;
                gap: 24px;
                padding: 20px;
                border-radius: 8px;
                background-color: $grey50;

                .interview-category {
                  background-color: $white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  max-width: 100px;
                  width: 100%;
                  padding: 0px 20px;
                  border-radius: 8px;

                  h4 {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 145%;
                    letter-spacing: -0.18px;
                    word-break: keep-all;
                  }
                }

                .interview-contents {
                  max-width: calc(100% - 100px);
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 30px;

                  .interview-wrap {
                    display: flex;
                    align-items: stretch;
                    gap: 16px;

                    .interview-title-wrap {
                      max-width: 360px;
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      gap: 8px;

                      .interview-tag {
                        font-weight: 700;
                        color: $white;
                        padding: 4px 8px;
                        border-radius: 16px;
                        white-space: nowrap;
                        max-width: 36px;
                        font-size: 12px;

                        &.red {
                          background-color: $red500;
                        }
                        &.blue {
                          background-color: $blue500;
                        }
                      }

                      .interview-title {
                        font-weight: 700;
                        font-size: 18px;
                      }

                      .interview-desc {
                        p {
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 145%;
                        }
                      }
                    }

                    .interview-icon-box {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      max-width: 68px;
                      width: 100%;
                    }

                    .interview-question-wrap {
                      max-width: 630px;
                      width: 100%;

                      .interview-question-title {
                        margin-bottom: 8px;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 145%;
                      }

                      .interview-question {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        p.interview-main-quesetion {
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 145%;
                        }

                        ul.interview-sub-question {
                          list-style: none;

                          li.interview-sub-question-item {
                            position: relative;
                            padding-left: 20px;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 145%;

                            &::after {
                              content: '';
                              width: 4px;
                              height: 4px;
                              border-radius: 50%;
                              background-color: $grey900;
                              position: absolute;
                              top: 8px;
                              left: 8px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      > .result-khai-o-section {
        width: 100%;
        > .khai-logo {
          margin-bottom: 16px;
        }
        > .result-khai-o-title {
          width: 100%;
          font-weight: bold;
          color: $grey900;
          margin-bottom: 16px;
        }
        > .result-khai-o-overall {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;
          margin-bottom: 16px;
          > .title {
            width: 100%;
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
          }
          > .result-wrap {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            > .result-cell {
              flex: 1;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              padding: 16px 20px;
              gap: 4px;
              height: 109px;
              background: $grey50;
              border-radius: 8px;
              > .result-title {
                height: 26px;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                text-align: center;
                color: $grey900;
                margin: 0 auto;
              }
              > .result-comment {
                height: 47px;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                text-align: center;
                margin: 0 auto;
                &.blue500 {
                  color: $blue500;
                }
                &.red500 {
                  color: $red500;
                }
                &.orange500 {
                  color: $orange500;
                }
              }
            }
          }
          > .alert {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;
            gap: 8px;
            width: 100%;
            height: 40px;
            background: $red50;
            border-radius: 8px;
            > .icon {
              width: 24px;
              height: 24px;
            }
            > .comment {
              width: 100%;
              font-style: normal;
              font-weight: 500;
              display: flex;
              align-items: center;
              color: $black;
            }
          }
        }
        > .result-khai-o-detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;
          width: 100%;

          > .title {
            width: 100%;
            height: 23px;
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
          }
          > .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px 12px;
            gap: 8px;
            width: 100%;
            height: 60px;
            background: $grey50;
            border-radius: 8px;
            > .title {
              width: 100%;
              height: 20px;
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $black;
            }

            > .contents {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 0px;
              gap: 16px;
              width: 100%;
              height: 20px;

              > .content {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 4px;
                height: 20px;

                > .bullet {
                  width: 10px;
                  height: 10px;
                  border-radius: 5px;

                  &.grey400 {
                    background: $grey400;
                  }

                  &.orange500 {
                    background: $orange500;
                  }

                  &.red500 {
                    background: $red500;
                  }
                }

                > .comment {
                  font-style: normal;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  color: $black;
                }
              }
            }
          }
          .table {
            width: 100%;
          }
          .table-header-cell {
            padding: 8px 20px;
            gap: 10px;
            text-align: center;
            vertical-align: middle;
            border: 1px solid $grey200;
          }
          .table-body-cell {
            padding: 20px;
            vertical-align: middle;
            border: 1px solid $grey200;
            &.score {
              text-align: right;
            }
            &.graph-cell {
              padding: 0 !important;
              position: relative;
              > .progress-bar {
                position: relative;
                width: 100%;
                height: 20px;
                background-color: $grey100;
                > .fill {
                  height: 20px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  &.blue500 {
                    background-color: $blue500;
                  }
                  &.orange500 {
                    background-color: $orange500;
                  }
                  &.red500 {
                    background-color: $red500;
                  }
                }
              }
              > .average {
                height: 100%;
                width: 2px;
                background: $grey300;
                position: absolute;
                top: 0;
              }
              > .caution {
                height: 100%;
                width: 2px;
                background: $orange500;
                position: absolute;
                top: 0;
              }
              > .vigilance {
                height: 100%;
                width: 2px;
                background: $red500;
                position: absolute;
                top: 0;
              }
            }
            &.bold {
              font-style: normal;
              font-weight: 700;
              color: $grey900;
            }
          }
          > .alert {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;
            gap: 8px;
            width: 100%;
            height: 40px;
            background: $red50;
            border-radius: 8px;
            > .icon {
              width: 24px;
              height: 24px;
            }
            > .comment {
              width: 100%;
              font-style: normal;
              font-weight: 500;
              display: flex;
              align-items: center;
              color: $black;
            }
          }
        }
        > .result-khai-o-interview {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 16px;
          width: 100%;
          margin-top: 16px;

          > .title {
            width: 100%;
            height: 23px;
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
          }

          > .interview-container {
            position: relative;
            width: 100%;

            article.interview-wrap {
              display: flex;
              align-items: stretch;

              &:not(:last-child) {
                margin-bottom: 16px;
              }

              // 공통
              aside:not(.interview-icon-box) {
                padding: 20px;
                border-radius: 8px;
                background-color: $grey50;
                min-height: 170px;

                &.print {
                  min-height: 120px;
                }
              }
              // 개별
              .interview-title-wrap {
                max-width: 400px;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;

                &.print {
                  max-width: 240px;
                  gap: 4px;
                }

                .interview-tag {
                  font-weight: 700;
                  color: $white;
                  padding: 4px 8px;
                  border-radius: 16px;
                  white-space: nowrap;
                  max-width: 142px;
                  font-size: 12px;

                  &.red {
                    background-color: $red500;
                  }
                  &.orange {
                    background-color: $orange500;
                  }
                  &.green {
                    background-color: $green500;
                  }

                  &.print {
                    font-size: 8px;
                    padding: 2px 4px;
                    max-width: 112px;
                  }
                }

                .interview-title {
                  font-weight: 700;
                }

                .interview-desc {
                  p {
                    font-weight: 500;
                  }
                }
              }

              .interview-icon-box {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 68px;
                width: 100%;

                &.print {
                  max-width: 40px;
                }

                svg {
                  width: 100%;
                }
              }

              .interview-question-wrap {
                max-width: 828px;
                width: 100%;

                &.print {
                  max-width: calc(100% - 240px - 40px);
                }

                .interview-question-title {
                  margin-bottom: 8px;
                  font-weight: 700;

                  &.print {
                    font-size: 8px;
                    margin-bottom: 4px;
                  }
                }

                .interview-question {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                  &.print {
                    gap: 4px;
                    ul.interview-sub-question {
                      li.interview-sub-question-item {
                        padding-left: 10px !important;

                        &::after {
                          content: '';
                          width: 2px !important;
                          height: 2px !important;
                          top: 6px !important;
                          left: 4px !important;
                        }
                      }
                    }
                  }

                  p.interview-main-quesetion {
                    font-weight: 500;
                  }

                  ul.interview-sub-question {
                    li.interview-sub-question-item {
                      position: relative;
                      padding-left: 20px;
                      font-weight: 500;

                      &::after {
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background-color: $grey900;
                        position: absolute;
                        top: 8px;
                        left: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      > .result-objective-section {
        width: 100%;
        > .result-objective-title {
          // font-size: 20px;
          font-weight: bold;
          color: $grey900;
          margin-bottom: 16px;
        }

        > .result-objective-wrap {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 40px;
          width: 100%;
          > .result-objective-graph {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px;
            gap: 16px;
            width: 300px;
            height: 282px;
            > .graph-title {
              width: 300px;
              height: 26px;
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $grey900;
            }
            > .average-score-graph {
              margin-top: 8px;
              width: 240px;
              height: 240px;
              text-align: center;
              > .percentage {
                position: relative;
                bottom: 65%;
                > .number {
                  font-weight: 700;
                }
                > .unit {
                  font-weight: 700;
                }
              }
            }
          }
          > .result-objective-table {
            width: 100%;
            thead {
              border: 1px solid $grey300;
            }
            tr {
              border-left: 1px solid $grey300;
              border-right: 1px solid $grey300;
              border-bottom: 1px solid $grey300;
            }
            .thead {
              // font-size:16px;
              font-weight: 700;
              // letter-spacing: -0.01em;
              &.title {
              }

              &.score {
              }

              &.survey {
              }
            }

            .tbody {
              // font-size:18px;
              font-weight: 500;
              // letter-spacing: -0.01em;

              &.title {
              }

              &.category {
                padding-left: 32px;
              }

              &.score {
                > .container {
                  > span {
                  }

                  > .progress-bar {
                    position: relative;
                    width: 100%;
                    height: 4px;
                    background-color: $grey100;
                    border-radius: 4px;

                    > .fill {
                      height: 4px;
                      background-color: $blue500;
                      position: absolute;
                      top: 0;
                      left: 0;
                      border-radius: 4px;
                      &.sub {
                        background-color: $blue300;
                      }
                    }
                  }
                }
              }

              &.survey {
              }

              &.culture-fit {
                background-color: $grey100;

                > .container {
                  > .value-survey {
                    margin-bottom: 16px;
                  }

                  > .value-survey,
                  > .culture-survey {
                    > .culture-fit-title {
                      // font-size: 14px;
                      font-weight: bold;
                      margin-bottom: 8px;
                    }

                    > .attributes-list {
                      display: flex;
                      gap: 8px;

                      > .attributes-list-item {
                        display: inline;
                        background-color: $blue50;
                        border-radius: 8px;
                        padding: 6px 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      > .result-subjective-table {
        width: 100%;
        margin-top: 16px;

        .result-subjective-title {
          font-weight: bold;
          color: $grey900;
          margin-bottom: 16px;
        }

        thead {
          border: 1px solid $grey300;
        }
        tr {
          border-left: 1px solid $grey300;
          border-right: 1px solid $grey300;
          border-bottom: 1px solid $grey300;
        }
        .thead {
          letter-spacing: -0.01em;
          // font-size:16px;
          font-weight: 700;
          &.title {
          }

          &.section {
          }

          &.score {
          }

          &.view-answer {
          }
        }

        .tbody {
          // letter-spacing: -0.01em;
          // font-size:18px;
          > .types {
            display: flex;
            flex-direction: row;
            gap: 4px;
          }
          font-weight: 500;
          &.title {
            word-break: keep-all;
            word-wrap: break-word;
          }

          &.section {
          }

          &.score {
            // .contain-flex {
            //     display: flex;
            //     align-items: center;

            //     .container {
            //         display: inline-flex;
            //         position: relative;

            //         > .stars {
            //             display: inline-flex;
            //             position: relative;
            //             z-index: 1;

            //             &.preset-hide {
            //                 >.star {
            //                     &::after {
            //                         display: none;
            //                     }
            //                 }
            //             }

            //             > .star {
            //                 width: 24px;
            //                 height: 24px;
            //                 display: inline-flex;
            //                 justify-content: center;
            //                 align-items: center;
            //                 position: relative;

            //                 &.fill {
            //                     &::before {
            //                         content: url('/assets/images/icon/star-fill.svg');
            //                         font-size: 0;
            //                     }
            //                 }

            //                 &.empty {
            //                     &::before {
            //                         content: url('/assets/images/icon/star.svg');
            //                         font-size: 0;
            //                     }

            //                     &.preset-fill {
            //                         &::after {
            //                             z-index: 3 !important;
            //                         }
            //                     }
            //                 }

            //                 &.preset-fill {
            //                     &::after {
            //                         position: absolute;
            //                         top: 0;
            //                         left: 0;
            //                         z-index: -1;
            //                         content: url('/assets/images/icon/star-preset.svg');
            //                         font-size: 0;
            //                     }
            //                 }

            //                 &.preset-empty {
            //                     &::after {
            //                         position: absolute;
            //                         top: 0;
            //                         left: 0;
            //                         z-index: -1;
            //                         // content: url('/assets/images/icon/star-white-fill.svg');
            //                         font-size: 0;
            //                     }
            //                 }
            //             }

            //             // &.preset {
            //             //     position: absolute;
            //             //     top: 0;
            //             //     left: 0;
            //             //     width: 100%;

            //             //     > .star {
            //             //         &.fill {
            //             //             &::before {
            //             //                 content: url('/assets/images/icon/star-preset.svg');
            //             //                 font-size: 0;
            //             //             }
            //             //         }
            //             //         &.empty {
            //             //             &::before {
            //             //                 content: url('/assets/images/icon/star-white-fill.svg');
            //             //                 font-size: 0;
            //             //             }
            //             //         }
            //             //     }
            //             // }
            //         }
            //     }

            //     .score-container {
            //         margin-left: 10px;
            //         width: 56px;
            //         text-align: right;

            //         > * {
            //             font-weight: 500;
            //             font-size: 16px;
            //         }

            //         > span {
            //             margin-right: 3px;
            //             color: $blue500;

            //             &.preset {
            //                 color: $grey400;
            //             }
            //         }
            //     }
            // }
          }

          &.view-answer {
            > .container {
              display: flex;
              justify-content: flex-end;

              > button {
                padding: 0 16px;
                height: 39px;
                border-radius: 100px;

                &::after {
                  content: url('/assets/images/icon/pen.svg');
                  margin-left: 10px;
                  position: relative;
                  top: 3px;
                }
                &.disabled {
                  background: $blue100;
                  color: $white;
                }
              }
            }
          }
        }

        .styling-container {
          // display: inline-flex;
          width: 100%;
          background-color: $grey50;

          > td {
            > .styling-wrapper {
              display: flex;
              padding: 16px 32px;
              gap: 32px;

              > .styling-question {
                width: 50%;

                > .styling-title {
                  margin-bottom: 16px;

                  h4 {
                    font-weight: 700;
                    margin-bottom: 8px;
                  }
                }

                > .styling-answer {
                  h4 {
                    font-weight: 700;
                    margin-bottom: 6px;
                  }
                }

                img {
                  display: inline-block;
                  max-width: 100%;
                }
              }
            }
          }
        }
      }

      > .result-obpi {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        width: 100%;
        > .result-obpi-title {
          width: 100%;
          font-style: normal;
          font-weight: 700;
          display: flex;
          align-items: center;
          color: $grey900;
        }
        > .result-obpi-text {
          width: 100%;
          display: flex;
          gap: 16px;
          flex-direction: column;
          > p {
            width: 100%;
            font-style: normal;
            font-weight: 400;
            display: flex;
            align-items: center;
            color: $grey700;
            word-break: keep-all;
            word-wrap: break-word;
          }
        }
        > .result-obpi-content {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 40px;
          width: 100%;
          > .result-obpi-content-left {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px;
            gap: 32px;
            width: 300px;
            .obpi-fit-score {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 0px;
              gap: 16px;

              width: 300px;
              > .obpi-fit-score-title {
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                width: 300px;
                font-style: normal;
                font-weight: 700;
                align-items: center;
                color: $grey900;
              }
              > .result-obpi-fitscore-graph {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0px;
                gap: 16px;
                width: 300px;
                > .graph-title {
                  width: 300px;
                  height: 26px;
                  font-style: normal;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  color: $grey900;
                }
                > .average-score-graph {
                  margin-top: 8px;
                  width: 240px;
                  height: 240px;
                  text-align: center;
                  > .percentage {
                    position: relative;
                    bottom: 65%;
                    > .number {
                      font-weight: 700;
                    }
                    > .unit {
                      font-weight: 700;
                    }
                  }
                }
              }
              > .result-obpi-content-select-candidate {
                width: 300px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 8px;

                > .select-form {
                  width: 300px;
                  height: 56px;
                  background: $white;
                  border-radius: 4px;
                }
                > .result-obpi-content-select-candidate-discription {
                  width: 300px;
                  font-style: normal;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  color: $grey500;
                }
              }
            }
            > .result-obpi-audit {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px;
              gap: 16px;
              width: 300px;
              > .result-obpi-audit-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 4px;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                color: $grey900;
                > button {
                  background-image: url(/assets/images/icon/guide-icon.svg);
                  background-color: transparent;
                  background-repeat: no-repeat;
                  background-position: 0px 0px;
                  border: none;
                  cursor: pointer;
                  height: 24px;
                  width: 24px;
                  // padding-left: 16px;
                  vertical-align: middle;
                  &:hover {
                    background-image: url(/assets/images/icon/guide-icon-hovered.svg);
                  }
                }
              }
              > .result-obpi-audit-result {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px;
                gap: 16px;
                width: 300px;
                background: $white;
                border-radius: 4px;
                > .row {
                  display: grid;
                  flex-direction: row;
                  align-items: flex-start;
                  padding: 0px;
                  gap: 32px;
                  width: 100%;
                  grid-template-columns: repeat(auto-fill, 100px);
                  justify-content: space-around;
                  > .col {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0px;
                    gap: 4px;
                    width: 100px;
                    height: 124px;
                    > .title {
                      width: 100px;
                      font-style: normal;
                      font-weight: 700;
                      align-items: center;
                      text-align: center;
                      color: $grey900;
                    }
                    > .graph {
                      width: 100px;
                      height: 100px;
                      text-align: center;
                      > .percentage {
                        position: relative;
                        bottom: 69%;
                        > .number {
                          font-weight: 700;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          > .result-obpi-content-right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 24px;
            width: 100%;
            > .obpi-detail-result-list {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px;
              gap: 4px;
              width: 100%;
              > .obpi-detail-result-title {
                width: 100%;
                font-style: normal;
                font-weight: 700;
                align-items: center;
                text-align: center;
                color: $grey900;
                display: flex;
                justify-content: center;
                > .obpi-detail-result-questionmark {
                  background-image: url(/assets/images/icon/guide-icon.svg);
                  background-color: transparent;
                  background-repeat: no-repeat;
                  background-size: 20px, 20px;
                  background-position: 0px 0px;
                  margin-left: 4px;
                  border: none;
                  // cursor: pointer;
                  height: 20px;
                  width: 20px;
                  // padding-left: 16px;
                  vertical-align: middle;
                  + .tooltip {
                    display: none;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 8px;
                    gap: 10px;
                    width: 200px;
                    background: $grey100;
                    border-radius: 4px;
                    font-style: normal;
                    font-weight: 500;
                    align-items: center;
                    text-align: center;
                    color: $grey900;
                    margin-left: -90px;
                    word-break: keep-all;
                    word-wrap: break-word;
                    z-index: 100;
                  }
                  &:hover + .tooltip {
                    display: block;
                    position: fixed;
                  }
                }
              }
              > .obpi-detail-results {
                width: 100%;
                > .obpi-detail-result-answer {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: flex-end;
                  padding: 0px;
                  gap: 4px;
                  width: 100%;
                  height: 16px;
                  > .obpi-detail-result-answer-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    width: 11%;
                    height: 16px;
                    background: $grey100;
                    border-radius: 4px;
                    &.selected {
                      background: $blue500;
                      &::after {
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                        content: '';
                        -webkit-mask: url('/assets/images/icon/star-white-fill.svg')
                          no-repeat 50% 50%;
                        mask: url('/assets/images/icon/star-white-fill.svg')
                          no-repeat 50% 50%;
                        -webkit-mask-size: cover;
                        mask-size: cover;
                        background-color: $white;
                      }
                    }
                  }
                }
              }
              > .opbi-detail-result-discription-box {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                padding: 0px;
                gap: 16px;
                width: 100%;
                height: 16px;
                > .discription {
                  width: 50%;
                  height: 16px;
                  font-style: normal;
                  font-weight: 700;
                  align-items: center;
                  color: $grey700;
                  &.left {
                    text-align: left;
                  }
                  &.right {
                    text-align: right;
                  }
                }
              }
              > .obpi-detail-result-interpretation {
                font-style: normal;
                font-weight: 400;
                display: flex;
                align-items: center;
                color: $grey700;
                word-break: keep-all;
                word-wrap: break-word;
              }
            }
          }
        }
      }
    }

    > .result-subjective {
      margin-bottom: 40px;
      > .result-subjective-title {
        // font-size: 20px;
        font-weight: bold;
        color: $grey900;
        margin-bottom: 16px;
      }

      > .result-subjective-table {
        margin-top: 16px;
        thead {
          border: 1px solid $grey300;
        }
        tr {
          border-left: 1px solid $grey300;
          border-right: 1px solid $grey300;
          border-bottom: 1px solid $grey300;
        }
        .thead {
          letter-spacing: -0.01em;
          // font-size:16px;
          font-weight: 700;
          &.title {
            word-break: keep-all;
            word-wrap: break-word;
          }

          &.section {
          }

          &.score {
          }

          &.view-answer {
          }
        }

        .tbody {
          // letter-spacing: -0.01em;
          // font-size:18px;
          font-weight: 500;

          &.title {
            // text-indent: 10px;
            line-height: 1.8;

            p {
              white-space: pre-wrap;
              margin: 0;
              line-height: 1.8 !important;
              // text-indent: 10px;

              * {
                all: revert;
                margin: 0 !important;
                line-height: 1.6 !important;
              }

              h1 {
                font-size: 20px;
              }
              h2 {
                font-size: 18px;
              }
              h3 {
                font-size: 16px;
              }
              h4 {
                font-size: 16px;
              }
              h5 {
                font-size: 16px;
              }
              h6 {
                font-size: 16px;
              }
            }
          }

          &.section {
          }

          &.score {
            // .contain-flex{
            //     display: flex;
            //     align-items: center;

            //     .container {
            //         display: inline-flex;
            //         position: relative;

            //         > .stars {
            //             display: inline-flex;
            //             position: relative;
            //             z-index: 1;

            //             &.preset-hide {
            //                 >.star {
            //                     &::after {
            //                         display: none;
            //                     }
            //                 }
            //             }

            //             > .star {
            //                 width: 24px;
            //                 height: 24px;
            //                 display: inline-flex;
            //                 justify-content: center;
            //                 align-items: center;
            //                 position: relative;

            //                 &.fill {
            //                     &::before {
            //                         content: url('/assets/images/icon/star-fill.svg');
            //                         font-size: 0;
            //                         z-index: 2;
            //                     }

            //                     &.preset-fill {
            //                         &::after {
            //                             z-index: 1;
            //                         }
            //                     }
            //                 }

            //                 &.empty {
            //                     &::before {
            //                         content: url('/assets/images/icon/star.svg');
            //                         font-size: 0;
            //                         z-index: 2;
            //                     }

            //                     &.preset-fill {
            //                         &::after {
            //                             z-index: 3 !important;
            //                         }
            //                     }
            //                 }

            //                 &.preset-fill {
            //                     &::after {
            //                         position: absolute;
            //                         top: 0;
            //                         left: 0;
            //                         z-index: 1;
            //                         content: url('/assets/images/icon/star-preset.svg');
            //                         font-size: 0;
            //                     }
            //                 }

            //                 &.preset-empty {
            //                     &::after {
            //                         position: absolute;
            //                         top: 0;
            //                         left: 0;
            //                         z-index: 1;
            //                         // content: url('/assets/images/icon/star-white-fill.svg');
            //                         font-size: 0;
            //                     }
            //                 }
            //             }

            //             // &.preset {
            //             //     position: absolute;
            //             //     top: 0;
            //             //     left: 0;
            //             //     width: 100%;

            //             //     > .star {
            //             //         &.fill {
            //             //             &::before {
            //             //                 content: url('/assets/images/icon/star-preset.svg');
            //             //                 font-size: 0;
            //             //             }
            //             //         }
            //             //         &.empty {
            //             //             &::before {
            //             //                 content: url('/assets/images/icon/star-white-fill.svg');
            //             //                 font-size: 0;
            //             //             }
            //             //         }
            //             //     }
            //             // }
            //         }
            //     }

            //     .score-container {
            //         margin-left: 10px;
            //         width: 56px;
            //         text-align: right;

            //         > * {
            //             font-weight: 500;
            //             font-size: 16px;
            //         }

            //         > span {
            //             margin-right: 3px;
            //             color: $blue500;

            //             &.preset {
            //                 color: $grey400;
            //             }
            //         }
            //     }
            // }
          }

          &.view-answer {
            > .container {
              display: flex;
              justify-content: flex-end;

              > button {
                padding: 0 16px;
                height: 39px;
                border-radius: 100px;

                &::after {
                  content: url('/assets/images/icon/pen.svg');
                  margin-left: 10px;
                  position: relative;
                  top: 3px;
                }
                &.disabled {
                  background: $blue100;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    > .result-comment {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;
      > textarea {
        width: 100%;
        height: 240px !important;
        min-height: 240px;
        border: 1px solid $grey300;
        border-radius: 4px;
        padding: 16px;
      }
      > button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 24px;
        gap: 8px;
        height: 56px;
        border-radius: 4px;
        color: $white;
        &.disabled {
          background: $blue200;
        }
      }
    }
  }

  > .info {
    display: flex;
    gap: 40px;
    overflow: hidden;

    > .box {
      flex: 1;
    }

    > .info-thumbnail {
      > img {
        max-width: 100%;
      }
    }

    > .info-prevent {
      > .info-prevent-title {
        // font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      > .row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(&:last-child) {
          margin-bottom: 16px;
        }

        > .column {
          // &.key {
          //     font-size: 14px;
          // }

          &.value {
            // font-size: 14px;
            font-weight: bold;
          }
        }
      }

      > .info-prevent-ip-once,
      > .info-prevent-webcam-activate,
      > .info-prevent-full-size,
      > .info-prevent-mouse-pointer {
        > .column {
          &.value {
            color: white;
            width: 60px;
            height: 20px;
            border-radius: 8px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            &.yes {
              background-color: $blue500;
            }

            &.no {
              background-color: $pink500;
            }
          }
        }
      }
    }

    > .info-detail {
      > .info-detail-invited-at,
      > .info-detail-completed-at,
      > .info-detail-entry-route {
        &:not(&:last-child) {
          margin-bottom: 24px;
        }

        > .title {
          // font-size: 18px;
          font-weight: bold;
          margin-bottom: 16px;
        }

        > .desc {
          // font-size: 14px;
          color: $grey900;
        }
      }
    }
  }

  .gucci {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 16px;
    background: $white;
    margin-bottom: 40px;

    > .title {
      height: 28px;
      font-weight: 700;
      display: flex;
      align-items: center;
      color: $black;
    }
    > .row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 100%;
      height: auto;
      > .cell {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        height: auto;
        > .title {
          font-weight: 500;
          display: flex;
          align-items: center;
          color: $grey700;
        }
        > .content {
          font-weight: 500;
          display: flex;
          align-items: center;
          color: $grey900;
          > a {
            text-decoration: none;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 12px;
            gap: 8px;
            height: 40px;
            background: $white;
            border: 1px solid $grey300;
            border-radius: 4px;
            font-style: normal;
            font-weight: 500;
            text-align: center;
            color: $grey900;
          }
        }
      }
    }
  }
}

// tooltip css
.stars {
  + .tooltip {
    display: none;
    flex-direction: row;
    padding: 8px;
    gap: 10px;
    width: 200px;
    background: $grey100;
    border-radius: 4px;
    font-weight: 500;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: $grey900;
    margin-left: -90px;
  }

  &.preset-hide {
    &:hover + .tooltip {
      display: none;
    }
  }

  &:hover + .tooltip {
    display: block;
    position: fixed;
  }
}

// 테이블 테그
.tg {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
  }

  td {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
  }

  .tg-th {
    background-color: #6980c4;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    vertical-align: top;
  }

  .tg-td {
    text-align: center;
    vertical-align: top;
  }

  .tg-td-last {
    background-color: #ffcc67;
    text-align: center;
    vertical-align: top;
    text-decoration: underline;
    font-weight: bold;
  }
}

.rect-border {
  border: 1px solid $grey300;
  padding: 8px 10px;

  .center {
    padding: 20px 0;
  }
}

.center {
  text-align: center;
}

// pdf 저장 페이지 테이블 태그
.pages-protected-candidates-print {
  .tg {
    border-collapse: collapse;
    border-spacing: 0;
    width: 50%;

    th {
      border-color: black;
      border-style: solid;
      border-width: 1px;
      font-size: 12px;
      font-weight: normal;
      overflow: hidden;
      padding: 5px 5px;
      word-break: normal;
    }

    td {
      border-color: black;
      border-style: solid;
      border-width: 1px;
      font-size: 12px;
      overflow: hidden;
      padding: 5px 5px;
      word-break: normal;
    }

    .tg-th {
      background-color: #6980c4;
      color: #ffffff;
      font-weight: bold;
      text-align: center;
      vertical-align: top;
    }

    .tg-td {
      text-align: center;
      vertical-align: top;
    }

    .tg-td-last {
      background-color: #ffcc67;
      text-align: center;
      vertical-align: top;
      text-decoration: underline;
      font-weight: bold;
    }
  }
  .pages-protected-candidates-detail-assessment-print {
    padding: 0 !important;
    margin-bottom: 0px !important;
    > .result {
      > .result-subjective {
        // >.result-subjective-title{
        //     font-size: 18px !important;
        // }
        > .alert {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 16px 24px;
          width: 100%;
          background: $red50;
          border-radius: 16px;
          align-self: stretch;
          > .text {
            margin-left: 18px;
            font-weight: 700;
            // font-size: 12px;
            // line-height: 130%;
            display: flex;
            align-items: center;
          }
        }
        > .result-subjective-table {
          .thead {
            // font-size:12px !important;
            padding: 8px 16px !important;
            &.title {
            }

            &.section {
            }

            &.score {
            }

            &.view-answer {
            }
          }

          .tbody {
            // font-size:12px !important;
            padding: 8px 16px !important;
            &.answer {
              padding: 8px 16px 8px 32px !important;
            }
            > .title {
              font-weight: 700;
              // font-size: 12px;
              // line-height: 130%;
              margin-bottom: 4px;
            }
            &.title {
              p {
                white-space: pre-wrap;
                margin: 0;
                line-height: 1.8 !important;
                text-indent: 8px !important;

                * {
                  all: revert;
                  margin: 0 !important;
                  line-height: 1.4 !important;
                }

                h1 {
                  font-size: 18px !important;
                }
                h2 {
                  font-size: 16px !important;
                }
                h3 {
                  font-size: 14px !important;
                }
                h4 {
                  font-size: 12px !important;
                }
                h5 {
                  font-size: 12px !important;
                }
                h6 {
                  font-size: 12px !important;
                }
              }
            }
            &.score {
              .container {
                > .stars {
                  display: inline-flex;
                  position: relative;
                  z-index: 1;

                  &.preset-hide {
                    > .star {
                      &::after {
                        display: none;
                      }
                    }
                  }

                  > .star {
                    width: 24px;
                    height: 24px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    &.fill {
                      &::before {
                        content: url('/assets/images/icon/star-fill.svg');
                        font-size: 0;
                      }
                    }

                    &.empty {
                      &::before {
                        content: url('/assets/images/icon/star.svg');
                        font-size: 0;
                      }

                      &.preset-fill {
                        &::after {
                          z-index: 3 !important;
                        }
                      }
                    }

                    &.preset-fill {
                      &::after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        content: url('/assets/images/icon/star-preset.svg');
                        font-size: 0;
                      }
                    }

                    &.preset-empty {
                      &::after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        // content: url('/assets/images/icon/star-white-fill.svg');
                        font-size: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      > .result-objective {
        > .result-objective-title {
          // font-size: 18px !important;
          color: $black !important;
        }
        > .result-khai-p-section {
          width: 100%;

          > .khai-title {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 16px;

            > .khai-logo {
              max-width: 56px;

              svg {
                width: 100%;
              }
            }
            > .result-khai-p-title {
              font-weight: bold;
              color: $grey900;
            }
          }

          > .result-khai-p-wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            width: 100%;
            > .result-khai-p-overall {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 0px;
              width: 100%;
              // height: 245.82px;
              > .result-khai-p-overall-result {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 11.0383px;
                gap: 8.83px;
                width: 220.77px;
                // height: 245.82px;
                border: 1px solid $grey200;
                border-right-width: 0;
                border-bottom-width: 0;
                > .title {
                  width: 100%;
                  font-style: normal;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  color: $grey900;
                }
                > .score {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  padding: 0px 0px 4.41534px;
                  gap: 8.83px;
                  width: 100%;
                  height: 80.44px;
                  > .overall-grade {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0px;
                    gap: 8px;
                    width: 50%;
                    height: 76.02px;
                    > .title {
                      height: 12px;
                      font-style: normal;
                      font-weight: 700;
                      display: flex;
                      align-items: center;
                      // text-align: center;
                      margin: 0 auto;
                      color: $grey700;
                    }
                    > .grade {
                      width: 59.61px;
                      height: 59.61px;
                    }
                  }
                  > .overall-score {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0px;
                    gap: 8px;
                    width: 50%;
                    height: 76.02px;
                    > .title {
                      // width: 100%;
                      height: 12px;
                      font-style: normal;
                      font-weight: 700;
                      display: flex;
                      align-items: center;
                      // text-align: center;
                      margin: 0 auto;
                      color: $grey700;
                    }
                    > .score-graph {
                      width: 59.61px;
                      height: 59.06px;
                      > .percentage {
                        text-align: center;
                        position: relative;
                        bottom: 70%;
                        > .number {
                          font-weight: 700;
                        }
                        > .unit {
                          font-weight: 700;
                        }
                      }
                    }
                  }
                }
                > .candidates-rank,
                > .bigdata-rank,
                > .response-confidence {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 8.83067px 11.0383px;
                  width: 198.69px;
                  height: 30.66px;
                  background: $grey50;
                  border-radius: 4.4px;
                  justify-content: space-between;
                  > .title {
                    // width: 209px;
                    height: 26px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    color: $grey900;
                  }
                  > .rank {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: flex-end;
                    padding: 0px;
                    gap: 4px;
                    // width: 180px;
                    height: 26px;
                    > .my-rank {
                      height: 26px;
                      font-style: normal;
                      font-weight: 700;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      color: $grey900;
                    }
                    > .base {
                      height: 20px;
                      font-style: normal;
                      font-weight: 700;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      letter-spacing: -0.01em;
                      color: #89949f;
                    }
                    > .desc {
                      height: 26px;
                      font-style: normal;
                      font-weight: 700;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      &.blue500 {
                        color: $blue500;
                      }
                      &.red500 {
                        color: $red500;
                      }
                      &.orange500 {
                        color: $orange500;
                      }
                    }
                  }
                }
              }
              > .result-hkai-p-ovrall-score-graph {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 12.0383px 9.38259px;
                gap: 8.83px;

                width: calc(100% - 220.77px);
                // height: 245.82px;
                border: 1px solid $grey200;
                border-bottom-width: 0px;
                > .title {
                  width: 100%;
                  height: 26px;
                  font-style: normal;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  color: $grey900;
                }
                > .graph {
                  width: 476.16px;
                  height: 156.74px;
                  position: relative;
                  > .score-distribution-graph {
                    width: 100%;
                    height: 100%;
                  }
                  > .candidate-score {
                    width: 38.83px;
                    height: 129.32px;
                    position: absolute;
                    top: 6px;
                    margin-left: 9px;
                  }
                }
              }
            }
            > .result-khai-p-result-by-propensity {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 11.0383px;
              gap: 8.83px;
              width: 100%;
              height: 188.2px;
              border: 1px solid $grey200;
              border-bottom-width: 0px;
              > .title {
                width: 100%;
                height: 26px;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                color: $grey900;
              }
              > .result {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 40px;
                width: 100%;
                height: 141.29px;
                > .radar-graph {
                  width: 198.69px;
                  height: 141.29px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  > .graph {
                    width: 187.2px;
                    height: 126px;
                    svg {
                      overflow: visible;
                    }
                  }
                  > .legend {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 24px;
                    height: 16px;
                    justify-content: center;
                    > .candidate {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      padding: 0px;
                      gap: 4px;
                      height: 16px;
                      > .title {
                        height: 16px;
                        font-style: normal;
                        font-weight: 700;
                        display: flex;
                        align-items: center;
                        color: $grey900;
                      }
                      > .bullet {
                        width: 6px;
                        height: 6px;
                        background: $blue500;
                        border-radius: 5px;
                      }
                    }
                    > .bigdata {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      padding: 0px;
                      gap: 4px;
                      height: 16px;
                      > .title {
                        height: 16px;
                        font-style: normal;
                        font-weight: 700;
                        display: flex;
                        align-items: center;
                        color: $grey900;
                      }
                      > .bullet {
                        width: 6px;
                        height: 6px;
                        background: $green500;
                        border-radius: 5px;
                      }
                    }
                  }
                }
                > .result-desc {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  padding: 0px;
                  gap: 16px;
                  width: calc(100% - 198.69px);
                  height: 100%;
                  > .propensity {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 11.0383px;
                    gap: 13.25px;
                    width: 33.3%;
                    height: 100%;
                    background: $grey50;
                    border-radius: 4.4px;
                    > .title {
                      width: 100%;
                      height: 13px;
                      font-style: normal;
                      font-weight: 700;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      justify-content: center;
                      color: $grey900;
                    }
                    > .grade {
                      width: 33.12px;
                      height: 33.12px;
                    }
                    > .desc {
                      // width: 100%;
                      justify-content: center;
                      margin: 0 auto;
                      font-style: normal;
                      font-weight: 500;
                      display: flex;
                      align-items: unset;
                      text-align: center;
                      color: $grey700;
                    }
                  }
                }
              }
            }
            > .result-khai-p-detail-result {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 16.5575px 11.0383px;
              gap: 8.83px;
              width: 100%;
              height: 224.16px;
              border: 1px solid $grey200;
              break-after: page;
              > .title {
                width: 100%;
                height: 16px;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                color: $grey900;
              }
              > .detail-result {
                width: 100%;
                height: 148.38px;
                display: flex;
                gap: 16px;
                > .propensity {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 8.83067px 0px;
                  gap: 2.21px;
                  // width: 100%;
                  height: 146.42px;
                  background: $grey50;
                  border-radius: 8px;
                  > .title {
                    width: 100%;
                    height: 13px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    color: $grey900;
                  }
                  > .graphs {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0px;
                    width: 100%;

                    > .graph {
                      box-sizing: border-box;
                      display: flex;
                      flex: 1;
                      flex-direction: column;
                      align-items: flex-start;
                      padding: 0px;
                      gap: 4px;
                      height: 121px;
                      width: 100%;
                      > .inner {
                        box-sizing: border-box;
                        border-bottom: 1px solid $grey200;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-end;
                        padding: 0;
                        gap: 10px;
                        width: 100%;
                        height: 88.31px;
                        > .graph {
                          display: flex;
                          flex-direction: row;
                          justify-content: center;
                          align-items: flex-end;
                          position: relative;
                          width: 30.36px;
                          height: 100%;
                          margin: 0 auto;
                          > .my-score {
                            width: 17.11px;
                            height: 100px;
                            background: linear-gradient(
                              180deg,
                              #3294ee 0%,
                              #0066ff 100%
                            );
                            position: absolute;
                            left: 6.5px;
                          }
                          > .bigdata-score {
                            width: 30.36px;
                            height: 80px;
                            background: rgba(36, 193, 133, 0.3);
                            border-top: 2px solid $green500;
                            position: absolute;
                            left: 0;
                          }
                          > .tooltip {
                            display: none;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 8px;
                            gap: 10px;
                            width: 300px;
                            background: $grey100;
                            border-radius: 4px;
                            font-style: normal;
                            font-weight: 500;
                            align-items: center;
                            color: $grey900;
                            margin-left: -150px;
                          }
                          &:hover {
                            background: $grey100;
                          }
                          // &:hover > .tooltip {
                          //     display: block;
                          //     position: fixed;
                          //     z-index: 30;
                          // }
                        }
                      }
                      > .desc {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 0px;
                        height: 23px;
                        width: 100%;
                        > .title {
                          width: 100%;
                          height: 12px;
                          font-style: normal;
                          font-weight: 700;
                          display: flex;
                          align-items: center;
                          text-align: center;
                          justify-content: center;
                          color: $grey900;
                        }
                        > .score {
                          width: 100%;
                          height: 12px;
                          font-style: normal;
                          font-weight: 700;
                          display: flex;
                          align-items: center;
                          text-align: center;
                          justify-content: center;
                          color: $grey900;
                        }
                      }
                    }
                  }
                }
              }
              > .legend {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                gap: 13.25px;
                height: 9px;
                > .candidate {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 0px;
                  gap: 2.21px;
                  height: 9px;
                  > .title {
                    height: 9px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    color: $grey900;
                  }
                  > .bullet {
                    width: 6px;
                    height: 6px;
                    background: $blue500;
                    border-radius: 3px;
                  }
                }
                > .bigdata {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 0px;
                  gap: 2.21px;
                  height: 9px;
                  > .title {
                    height: 9px;
                    font-style: normal;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    color: $grey900;
                  }
                  > .bullet {
                    width: 6px;
                    height: 6px;
                    background: $green500;
                    border-radius: 3px;
                  }
                }
              }
            }
            > .result-khai-p-comment {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 11.0383px;
              gap: 4.42px;
              width: 100%;
              border: 1px solid $grey200;
              border-top: none;
              border-bottom-width: 0;
              > .title {
                width: 100%;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                color: $grey900;
              }

              .interview-container {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;

                .interview-wrapper {
                  display: flex;
                  align-items: stretch;
                  align-self: stretch;
                  gap: 16px;
                  padding: 16px;
                  border-radius: 8px;
                  background-color: $grey50;

                  .interview-category {
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    max-width: 50px;
                    width: 100%;
                    padding: 0px 10px;
                    border-radius: 8px;

                    h4 {
                      font-size: 9px;
                      font-weight: 700;
                      line-height: 145%;
                      letter-spacing: -0.18px;
                      word-break: keep-all;
                    }
                  }

                  .interview-contents {
                    max-width: calc(100% - 50px);
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .interview-wrap {
                      display: flex;
                      align-items: stretch;
                      gap: 16px;

                      .interview-title-wrap {
                        max-width: 200px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        .interview-tag {
                          font-weight: 700;
                          color: $white;
                          padding: 2px 4px;
                          border-radius: 16px;
                          white-space: nowrap;
                          max-width: 25px;
                          font-size: 8px;

                          &.red {
                            background-color: $red500;
                          }
                          &.blue {
                            background-color: $blue500;
                          }
                        }

                        .interview-title {
                          font-weight: 700;
                          font-size: 9px;
                        }

                        .interview-desc {
                          p {
                            font-weight: 500;
                            font-size: 9px;
                            line-height: 145%;
                          }
                        }
                      }

                      .interview-icon-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 40px;
                        width: 100%;
                      }

                      .interview-question-wrap {
                        max-width: calc(100% - 200px - 40px - 32px);
                        width: 100%;

                        .interview-question-title {
                          margin-bottom: 8px;
                          font-weight: 700;
                          font-size: 8px;
                          line-height: 145%;
                        }

                        .interview-question {
                          display: flex;
                          flex-direction: column;
                          gap: 8px;

                          p.interview-main-quesetion {
                            font-weight: 500;
                            font-size: 9px;
                            line-height: 145%;
                          }

                          ul.interview-sub-question {
                            list-style: none;

                            li.interview-sub-question-item {
                              position: relative;
                              padding-left: 10px;
                              font-weight: 500;
                              font-size: 9px;
                              line-height: 145%;

                              &::after {
                                content: '';
                                width: 2px;
                                height: 2px;
                                border-radius: 50%;
                                background-color: $grey900;
                                position: absolute;
                                top: 6px;
                                left: 4px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            > .result-khai-p-detail-comment {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 11.0383px;
              gap: 4.42px;
              width: 100%;
              border: 1px solid $grey200;
              .bold {
                font-style: normal;
                font-weight: 700;
                align-items: center;
              }
              .grey900 {
                color: $grey900;
              }
              tr,
              td {
                border: 1px solid $grey200;
                padding: 8px;
              }

              > .title {
                width: 100%;
                height: 16px;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
                color: $grey900;
              }
              > .comments {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                width: 100%;
                > .title {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 8px;
                  gap: 10px;
                  width: 70px;
                }
              }
            }
          }
        }
        > .result-khai-o-section {
          width: 100%;
          > .khai-title {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 12px;
            > .khai-logo {
              max-width: 56px;

              svg {
                width: 100%;
              }
            }
            > .result-khai-o-title {
              width: 100%;
              font-weight: bold;
              color: $grey900;
            }
          }

          > .result-khai-o-overall {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            margin-bottom: 12px;
            > .title {
              width: 100%;
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $grey900;
            }
            > .result-wrap {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 0px;
              gap: 8px;
              > .result-cell {
                flex: 1;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 12px 20px;
                gap: 4px;
                height: 48px;
                background: $grey50;
                border-radius: 8px;
                > .result-title {
                  height: 12px;
                  font-style: normal;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  color: $grey900;
                  margin: 0 auto;
                }
                > .result-comment {
                  height: 28px;
                  font-style: normal;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  margin: 0 auto;
                  &.blue500 {
                    color: $blue500;
                  }
                  &.red500 {
                    color: $red500;
                  }
                  &.orange500 {
                    color: $orange500;
                  }
                }
              }
            }
            > .alert {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 8px;
              gap: 8px;
              width: 100%;
              height: 24px;
              background: $red50;
              border-radius: 8px;
              > .icon {
                width: 16px;
                height: 16px;
              }
              > .comment {
                width: 100%;
                font-style: normal;
                font-weight: 500;
                display: flex;
                align-items: center;
                color: $black;
              }
            }
          }
          > .result-khai-o-detail {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            width: 100%;
            > .title {
              width: 100%;
              height: 16px;
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $grey900;
            }
            > .info {
              display: flex;
              align-items: center;
              padding: 8px 12px;
              gap: 8px;
              width: 100%;
              height: 24px;
              background: $grey50;
              border-radius: 8px;
              flex-direction: row;
              > .title {
                flex: 100px;
                height: 16px;
                font-style: normal;
                font-weight: 700;
                color: $black;
              }

              > .contents {
                flex: calc(100% - 120px);
                display: flex;
                gap: 16px;
                align-items: center;
                > .content {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 0px;
                  gap: 4px;
                  height: 13px;

                  > .bullet {
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;

                    &.grey400 {
                      background: $grey400;
                    }

                    &.orange500 {
                      background: $orange500;
                    }

                    &.red500 {
                      background: $red500;
                    }
                  }

                  > .comment {
                    font-style: normal;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    color: $black;
                  }
                }
              }
            }
            .table {
              width: 100%;
            }
            .table-header-cell {
              padding: 8px 10px;
              gap: 10px;
              text-align: center;
              vertical-align: middle;
              border: 1px solid $grey200;
            }
            .table-body-cell {
              padding: 10px;
              vertical-align: middle;
              border: 1px solid $grey200;
              &.score {
                text-align: right;
              }
              &.graph-cell {
                padding: 0 !important;
                position: relative;
                > .progress-bar {
                  position: relative;
                  width: 100%;
                  height: 10px;
                  background-color: $grey100;
                  > .fill {
                    height: 10px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    &.blue500 {
                      background-color: $blue500;
                    }
                    &.orange500 {
                      background-color: $orange500;
                    }
                    &.red500 {
                      background-color: $red500;
                    }
                  }
                }
                > .average {
                  height: 100%;
                  width: 2px;
                  background: $grey300;
                  position: absolute;
                  top: 0;
                }
                > .caution {
                  height: 100%;
                  width: 2px;
                  background: $orange500;
                  position: absolute;
                  top: 0;
                }
                > .vigilance {
                  height: 100%;
                  width: 2px;
                  background: $red500;
                  position: absolute;
                  top: 0;
                }
              }
              &.bold {
                font-style: normal;
                font-weight: 700;
                color: $grey900;
              }
            }
            > .alert {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 8px;
              gap: 8px;
              width: 100%;
              height: 24px;
              background: $red50;
              border-radius: 8px;
              > .icon {
                width: 16px;
                height: 16px;
              }
              > .comment {
                width: 100%;
                font-style: normal;
                font-weight: 500;
                display: flex;
                align-items: center;
                color: $black;
              }
            }
          }
          > .result-khai-o-interview {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            width: 100%;

            > .title {
              width: 100%;
              height: 23px;
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $grey900;
            }

            > .interview-container {
              position: relative;
              width: 100%;

              article.interview-wrap {
                display: flex;
                align-items: stretch;

                &:not(:last-child) {
                  margin-bottom: 16px;
                }

                // 공통
                aside:not(.interview-icon-box) {
                  padding: 20px;
                  border-radius: 8px;
                  background-color: $grey50;
                  min-height: 170px;

                  &.print {
                    min-height: 120px;
                  }
                }
                // 개별
                .interview-title-wrap {
                  max-width: 400px;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                  &.print {
                    max-width: 240px;
                    gap: 4px;
                  }

                  .interview-tag {
                    font-weight: 700;
                    color: $white;
                    padding: 4px 8px;
                    border-radius: 16px;
                    white-space: nowrap;
                    max-width: 142px;
                    font-size: 12px;

                    &.red {
                      background-color: $red500;
                    }
                    &.orange {
                      background-color: $orange500;
                    }
                    &.green {
                      background-color: $green500;
                    }

                    &.print {
                      font-size: 8px;
                      padding: 2px 4px;
                      max-width: 112px;
                    }
                  }

                  .interview-title {
                    font-weight: 700;
                  }

                  .interview-desc {
                    p {
                      font-weight: 500;
                    }
                  }
                }

                .interview-icon-box {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  max-width: 68px;
                  width: 100%;

                  &.print {
                    max-width: 40px;
                  }

                  svg {
                    width: 100%;
                  }
                }

                .interview-question-wrap {
                  max-width: 828px;
                  width: 100%;

                  &.print {
                    max-width: calc(100% - 240px - 40px);
                  }

                  .interview-question-title {
                    margin-bottom: 8px;
                    font-weight: 700;

                    &.print {
                      font-size: 8px;
                      margin-bottom: 4px;
                    }
                  }

                  .interview-question {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    &.print {
                      gap: 4px;
                      ul.interview-sub-question {
                        li.interview-sub-question-item {
                          padding-left: 10px !important;

                          &::after {
                            content: '';
                            width: 2px !important;
                            height: 2px !important;
                            top: 6px !important;
                            left: 4px !important;
                          }
                        }
                      }
                    }

                    p.interview-main-quesetion {
                      font-weight: 500;
                    }

                    ul.interview-sub-question {
                      li.interview-sub-question-item {
                        position: relative;
                        padding-left: 20px;
                        font-weight: 500;

                        &::after {
                          content: '';
                          width: 4px;
                          height: 4px;
                          border-radius: 50%;
                          background-color: $grey900;
                          position: absolute;
                          top: 8px;
                          left: 8px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        > .result-objective-section {
          gap: 40px;
          width: 100%;
          > .result-objective-graph {
            width: 240px !important;
            > .graph-title {
              width: 240px !important;
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $grey900;
            }
            > .average-score-graph {
              margin-top: 8px;
              width: 200px !important;
              height: 200px !important;
              text-align: center;
              > .percentage {
                position: relative;
                bottom: 67% !important;
              }
            }
          }
          > .result-objective-table {
            thead {
              border: 1px solid $grey300;
            }
            tr {
              border-left: 1px solid $grey300;
              border-right: 1px solid $grey300;
              border-bottom: 1px solid $grey300;
            }
            .thead {
              // font-size:12px !important;
              padding: 8px 16px !important;
            }

            .tbody {
              padding: 8px 16px !important;
              // font-size:12px !important;
              &.title {
                font-weight: 700 !important;
              }
              &.category {
                padding: 14px 16px 14px 32px !important;
              }
            }
          }
        }
        > .result-subjective-table {
          .thead {
            // font-size:12px !important;
            padding: 8px 16px !important;
            &.title {
            }

            &.section {
            }

            &.score {
            }

            &.view-answer {
            }
          }

          .tbody {
            // font-size:12px !important;
            padding: 8px 16px !important;
            &.answer {
              padding: 8px 16px 8px 48px !important;
            }
            > .title {
              font-weight: 700;
              // font-size: 12px;
              // line-height: 130%;
              margin-bottom: 4px;
            }
            &.detailtitle {
              word-break: keep-all;
              word-wrap: break-word;
              padding: 8px 16px 8px 32px !important;
              > pre {
                word-break: keep-all;
                word-wrap: break-word;
              }
            }
            &.score {
              .container {
                > .stars {
                  display: inline-flex;
                  position: relative;
                  z-index: 1;

                  &.preset-hide {
                    > .star {
                      &::after {
                        display: none;
                      }
                    }
                  }

                  > .star {
                    width: 24px;
                    height: 24px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    &.fill {
                      &::before {
                        content: url('/assets/images/icon/star-fill.svg');
                        font-size: 0;
                      }
                    }

                    &.empty {
                      &::before {
                        content: url('/assets/images/icon/star.svg');
                        font-size: 0;
                      }

                      &.preset-fill {
                        &::after {
                          z-index: 3 !important;
                        }
                      }
                    }

                    &.preset-fill {
                      &::after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        content: url('/assets/images/icon/star-preset.svg');
                        font-size: 0;
                      }
                    }

                    &.preset-empty {
                      &::after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        // content: url('/assets/images/icon/star-white-fill.svg');
                        font-size: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        > .result-obpi {
          > .result-obpi-content {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 40px;
            width: 100%;
            > .result-obpi-content-left {
              width: 240px !important;
              .obpi-fit-score {
                width: 240px !important;
                > .obpi-fit-score-title {
                  width: 240px !important;
                }
                > .result-obpi-fitscore-graph {
                  width: 240px !important;
                  > .graph-title {
                    width: 240px !important;
                  }
                  > .average-score-graph {
                    width: 200px !important;
                    height: 200px !important;
                  }
                }
              }
              > .result-obpi-audit {
                width: 240px !important;
                > .result-obpi-audit-result {
                  width: 240px !important;
                  > .row {
                    grid-template-columns: repeat(auto-fill, 80px) !important;
                    > .col {
                      width: 80px !important;
                      > .title {
                        width: 80px !important;
                      }
                      > .graph {
                        width: 80px !important;
                        height: 80px !important;
                        > .percentage {
                          bottom: 72% !important;
                        }
                      }
                    }
                  }
                }
              }
            }
            > .result-obpi-content-right {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px;
              gap: 24px;
              width: 100%;
              > .obpi-detail-result-list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 4px;
                width: 100%;
                > .obpi-detail-result-title {
                  width: 100%;
                  font-style: normal;
                  font-weight: 700;
                  align-items: center;
                  text-align: center;
                  color: $grey900;
                }
                > .obpi-detail-results {
                  width: 100%;
                  > .obpi-detail-result-answer {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-end;
                    padding: 0px;
                    gap: 4px;
                    width: 100%;
                    height: 16px;
                    > .obpi-detail-result-answer-item {
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      gap: 10px;
                      width: 11%;
                      height: 16px;
                      background: $grey100;
                      border-radius: 4px;
                      &.selected {
                        background: $blue500;
                        &::after {
                          width: 12px;
                          height: 12px;
                          display: inline-block;
                          content: '';
                          -webkit-mask: url('/assets/images/icon/star-white-fill.svg')
                            no-repeat 50% 50%;
                          mask: url('/assets/images/icon/star-white-fill.svg')
                            no-repeat 50% 50%;
                          -webkit-mask-size: cover;
                          mask-size: cover;
                          background-color: $white;
                        }
                      }
                    }
                  }
                }
                > .opbi-detail-result-discription-box {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: flex-start;
                  padding: 0px;
                  gap: 16px;
                  width: 100%;
                  height: 16px;
                  > .discription {
                    width: 50%;
                    height: 16px;
                    font-style: normal;
                    font-weight: 700;
                    align-items: center;
                    color: $grey700;
                    &.left {
                      text-align: left;
                    }
                    &.right {
                      text-align: right;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    > .info {
      margin-top: 40px;
      letter-spacing: -0.01em;
      color: $black !important;
      > .box {
        flex: 1;
        border: 1px solid $grey300;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
      }

      > .info-prevent {
        > .info-prevent-title {
          // font-size: 16px !important;
          font-weight: 700 !important;
        }

        > .row {
          // font-size: 12px !important;
          font-weight: 500 !important;
          margin-top: 16px;
          margin-bottom: 0px !important;
        }
      }

      > .info-detail {
        > .info-detail-invited-at,
        > .info-detail-completed-at,
        > .info-detail-entry-route {
          &:not(&:last-child) {
            margin-bottom: 16px !important;
          }

          > .title {
            margin-bottom: 7px !important;
            // font-size : 16px !important;
          }
          // >.desc {
          //     font-size : 12px !important;
          // }
        }
      }
    }
  }
  #divFooter {
    margin: 60px auto 0;
    width: 120px;
    height: 26.25px;
    background-image: url('/assets/images/logo/powerdby-logo.svg');
    background-size: contain;
  }
}
