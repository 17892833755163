#pages-apply {
  max-width: 720px !important;
  width: 100% !important;
  background-color: $white;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);

  .apply-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .apply-title-wrapper {
      h2,
      h4 {
        font-weight: 700;
      }
    }

    .apply-recruitment-title {
      padding: 8px;
      text-align: center;
      background-color: $grey50;
      border-radius: 8px;

      h3 {
        font-weight: 700;
      }
    }

    .apply-form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .apply-input-box {
        &.flex {
          display: flex;
          align-items: center;
          gap: 16px;

          & > * {
            flex: 1;
          }
        }

        &.flex-strech {
          display: flex;
          align-items: stretch;
          gap: 16px;

          > .grey-box {
            justify-content: space-evenly;
          }

          & > * {
            flex: 1;
          }
        }

        &.flex-column {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }

        &.currency-wrapper {
          align-items: flex-start;

          .label {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 145%; /* 20.3px */
            letter-spacing: -0.14px;
            color: $grey700;
            margin-bottom: 16px;

            i {
              color: $red500;
              font-weight: 700;
            }
          }

          .grey-box {
            padding: 8px 14px;
            margin-bottom: 16px;

            pre {
              font-size: 12px !important;
              font-style: normal;
              font-weight: 500 !important;
              line-height: 130% !important; /* 15.6px */
              letter-spacing: -0.24px;
              color: $grey700;
            }
          }

          .flex {
            display: flex;
            align-items: flex-start;
            gap: 16px;

            & > :first-child {
              flex: 4;
            }
            & > :last-child {
              flex: 7;
            }
          }

          .basic-input,
          .basic-select {
            .input-label {
              display: none;
            }
          }
        }

        &.etc-currency-wrapper {
          margin-top: -24px;
        }

        &.resume-wrapper {
          align-items: flex-start;
          position: relative;

          & > :first-child {
            flex: 4;
          }
          & > :last-child {
            flex: 7;
          }

          .align-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;

            .plus-button-wrapper {
              margin-top: -12px;

              svg {
                path {
                  fill: $grey900;
                }
              }
            }

            .basic-file {
              .input-caution {
                white-space: pre;
              }
            }
          }
        }

        &.resume-list-wrapper {
          margin-top: -16px;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .resume-grey-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 14px;
            border-radius: 4px;
            border: 1px solid $grey300;
            background-color: $grey100;

            p {
              color: $grey500;
              font-weight: 500;
            }
          }
        }

        &.etc-link-wrapper {
          & > * {
            flex: none;
          }

          & > :first-child {
            width: calc(100% - 56px);
          }
          & > :last-child {
            width: 40px;

            svg {
              path {
                fill: $grey900;
              }
            }
          }
        }

        &.recommend-wrapper {
          align-items: flex-end;
        }

        .sub-title {
          font-weight: 500;
          color: $grey700;
          margin-bottom: 12px;
        }

        .apply-agree-radio-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;

          .input-label {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 145%;
            letter-spacing: -0.14px;
            color: $grey700;

            i {
              color: $red500;
            }
          }
        }

        .grey-box {
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          background-color: $grey50;
          border: 1px solid $grey300;
          border-radius: 4px;

          > label {
            color: $grey800;
            font-weight: 600;

            i {
              color: $red500;
              font-weight: 600;
            }
          }

          .basic-select {
            gap: 0;

            .toggle {
              background-color: $white;
            }
          }

          .input-caution {
            color: $grey700;
          }
        }

        .inputs-check {
          .MuiInputLabel-root {
            color: $grey700;
            font-weight: 500;

            > span {
              color: $red500;
            }
          }
        }
      }
    }

    .apply-agree-wrapper {
      .apply-agree-box {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .apply-agree-label {
          color: $grey700;
          font-weight: 500;
        }

        .apply-agree-checkbox-wrapper {
          .apply-agree-checkbox {
            display: flex;
            gap: 10px;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            padding: 16px 14px;
            border: 1px solid $grey300;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            aside.check-box-flex {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 4px;
              width: calc(100% - 20px);

              .apply-checkbox {
                margin: 0;
                max-width: 340px;

                .MuiFormControlLabel-label {
                  max-width: 90%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-weight: 500;
                  color: $grey900;
                }

                .check {
                  padding: 0;
                }
              }

              i {
                display: inline-block;
                padding: 4px 8px;
                border-radius: 16px;
                font-weight: 700;
                background-color: $grey100;

                &.required {
                  background-color: $pink50;
                  color: $pink500;
                }
              }

              .apply-agree-title {
                font-weight: 500;
                color: $grey900;
                cursor: pointer;
              }
            }

            aside.icon {
              width: 10px;
              cursor: pointer;

              svg {
                transition: all 0.2s ease-out;
              }
            }

            &.true {
              aside.icon {
                svg {
                  transform: rotate(180deg);
                }
              }
            }
          }

          .apply-agree-desc {
            overflow: hidden;

            .apply-dropdown-animation {
              padding: 16px 14px;
              background-color: $grey50;
              border: 1px solid $grey300;
              border-top: none;
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
              max-height: 240px;
              overflow-y: auto;

              &.slide-in {
                animation: slide-in ease-out 0.15s;
              }

              &.slide-out {
                animation: slide-out ease-out 0.15s forwards;
              }
            }
          }
        }
      }
    }

    .apply-submit-button {
      button {
        width: 100%;
      }
    }
  }
}

#pages-completed-apply {
  display: flex;
  align-items: center;
  justify-content: center;

  &.pages-completed-date {
    max-width: none !important;
    width: 100%;

    .completed-text-wrapper {
      h2 {
        text-align: center;
        margin-bottom: 16px;
      }

      p {
        text-align: center;
      }
    }
  }

  &.custom-completed {
    .completed-apply-container {
      max-width: 1130px !important;

      .completed-text-wrapper {
        gap: 20px;

        .color-box {
          position: relative;
          width: 100%;
          height: 500px;
          background-color: #4c000a;

          h3 {
            font-size: 80px;
            font-style: normal;
            font-weight: 600;
            line-height: 89%; /* 71.2px */
            letter-spacing: -0.8px;
            font-weight: 600;
            color: $white;
            position: absolute;
            top: 50%;
            width: 100%;
            left: 0;
            text-align: center;
            transform: translateY(-50%);
          }

          .img-box {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            height: auto;

            img {
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .completed-apply-container {
    max-width: 540px !important;
    width: 100% !important;
    height: auto !important;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .completed-text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h2 {
        font-weight: 700;
        color: $grey900;
      }

      p {
        font-weight: 500;
      }
    }

    .button-wrapper {
      > button {
        width: 100%;
      }
    }
  }
}

#pages-expired-apply {
  display: flex;
  align-items: center;
  justify-content: center;

  .expired-apply-container {
    max-width: 540px !important;
    width: 100% !important;
    height: auto !important;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .expired-text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h2 {
        font-weight: 700;
        color: $grey900;
      }

      p {
        font-weight: 500;
      }
    }
  }
}

// compensation page
#pages-compensation-form {
  max-width: 720px !important;
  width: 100% !important;
  background-color: $white;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);

  .container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .flex-column-gap32 {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    > section.title-wrapper {
      article.recruitment-title {
        h3 {
          font-weight: 700;
          color: $black;
          padding: 8px;
          background-color: $grey50;
          border-radius: 8px;
          text-align: center;
        }
      }

      article.main-title {
        h2 {
          font-weight: 700;
          color: $grey900;
        }
      }
    }

    > section.detail-wrapper {
      article.sub-title {
        h3 {
          font-weight: 700;
          color: $grey900;
        }
      }

      article.detail-form {
        > .flex {
          display: flex;
          gap: 12px;
        }

        aside {
          flex: 1;
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          border-radius: 4px;
          background-color: $grey50;
          border: 1px solid $grey300;

          .flex {
            display: flex;
            gap: 8px;
            align-items: flex-end;
          }
        }

        aside.left {
          > .flex {
            gap: 16px;

            > * {
              flex: 1;
            }
          }

          .form-box {
            > * {
              &:first-child {
                flex: 7;
              }
              &:last-child {
                flex: 4;
              }
            }

            .currency-form {
              .toggle {
                background-color: $white;
              }
            }
          }

          .salary-box {
            padding-top: 12px;
            border-top: 1px solid $grey300;
            text-align: right;

            h4 {
              margin-bottom: 6px;
              color: $grey700;
              font-weight: 500;
            }

            p {
              font-weight: 700;
              color: $grey900;
            }
          }
        }
        aside.right {
          .form-box {
            .document-type {
              .toggle {
                background-color: $white;
              }
            }

            .document-file {
              &.active {
                .input-box {
                  label {
                    border-color: $grey400;
                    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
                      0px 1px 2px rgba(0, 0, 0, 0.15);
                  }
                }
              }

              .input-box {
                label {
                  transition: all 0.2s ease-in-out;
                  background-color: $white;
                  min-height: 84px;
                  justify-content: center;
                  flex-direction: column;
                  gap: 12px;

                  span {
                    order: 3;
                    color: #808080;
                  }
                }
              }
            }
          }

          .files-list {
            ul.list-wrapper {
              display: flex;
              flex-direction: column;
              gap: 16px;

              li {
                display: flex;
                align-items: center;
                gap: 8px;

                .file-info {
                  padding: 8px 14px;
                  background-color: $grey100;
                  border: 1px solid $grey300;
                  border-radius: 4px;
                  width: calc(100% - 28px - 8px);
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 145%; /* 17.4px */
                  letter-spacing: -0.12px;
                  color: $grey500;
                }

                .file-delete {
                  width: 28px;
                }
              }
            }
          }
        }
      }
    }

    > section.start-date-wrapper {
      article.title-box {
        .sub-title {
          font-weight: 700;
          color: $grey900;
          margin-bottom: 8px;
        }
        .sub-description {
          font-weight: 500;
          color: $grey700;
        }
      }

      article.selected-date-box {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    > section.reference-wrapper {
      article.title-box {
        .sub-title {
          font-weight: 700;
          color: $grey900;
          margin-bottom: 8px;
        }
      }

      article.reference-box {
        display: flex;
        flex-direction: column;
        gap: 16px;

        > h5 {
          font-weight: 600;
          color: $grey800;
        }

        > .flex {
          display: flex;
          gap: 16px;

          & > * {
            flex: 1;
          }
        }
      }
    }

    > section.button-wrapper {
      article.button-box {
        display: flex;
        gap: 16px;

        & > * {
          flex: 1;
          font-weight: 500;
        }
      }
    }
  }
}

.privacy {
  p {
    font-size: 14px;
    line-height: 145%;
    color: $grey900;
    margin-bottom: 20px;
    letter-spacing: -0.3px;
  }

  h3 {
    font-size: 16px;
    line-height: 145%;
    color: $grey900;
    font-weight: 600;
    margin-bottom: 15px;
    letter-spacing: -0.3px;
  }

  .box {
    margin-bottom: 20px;
  }

  ol {
    list-style: auto;
    padding: 10px 0;
    padding-left: 16px;

    li {
      font-size: 14px;
      line-height: 145%;
      color: $grey900;
      letter-spacing: -0.3px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  ul {
    list-style: square;
    padding: 10px 0;
    padding-left: 16px;

    li {
      font-size: 14px;
      line-height: 145%;
      color: $grey900;
      letter-spacing: -0.3px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  table {
    margin-top: 10px;
    border: 1px solid $grey300;
    width: 100%;

    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $grey300;
      }

      td {
        padding: 6px 10px;
        font-size: 14px;
        line-height: 145%;
        color: $grey900;
        letter-spacing: -0.3px;

        &:not(:last-child) {
          border-right: 1px solid $grey300;
        }
      }
    }
  }
}
