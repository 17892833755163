@import './styles/include.scss';

html,
body,
#root {
  height: 100%;
}

#modal {
  > .modal-overlay {
    z-index: 100;
  }
}

#sub-modal {
  > .modal-overlay {
    z-index: 200;
  }
}

body {
  margin: 0;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $app_background_color;
  transition: background-color 0.4s;
}
body * {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
}

code {
  font-family: Consolas, source-code-pro, Menlo, Monaco, 'Courier New',
    monospace !important;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  transition: background-color 0.4s;

  &.hasModal {
    // overflow: hidden;
  }

  > header {
    background-color: white;
  }

  > main {
    flex: 1;
  }

  > footer {
  }
}
