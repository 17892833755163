#recruitment {
  margin: 0 auto !important;
  padding: 0 !important;
  width: 100% !important;
  max-width: none !important;
  background-color: $white;

  .recruitment-container {
    position: relative;
    display: flex;
    gap: 40px;
    height: calc(100vh - 64px);
    border-bottom: 1px solid $grey300;
  }
}

#dashboard {
  .tab-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.recruit-left-container {
  max-width: 320px;
  width: 100%;
  height: 100%;
  background-color: $white;
  overflow-y: auto;
  border-radius: 0 28px 28px 0;
  box-shadow: 8px 0 24px 0 rgba(0, 0, 0, 0.06);

  .recruit-left-dashboard {
    button.dashboard-btn {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      padding: 16px 16px 16px 32px;
      text-align: left;
      font-weight: 700;
    }
  }

  .recruit-left-list-box {
    padding-top: 56px;

    .list-title {
      padding: 8px 16px 8px 32px;

      h4 {
        font-weight: 700;
        color: $grey700;
      }
    }

    ul.recruitment-list {
      li.dashboard-button {
        display: flex;
        gap: 13px;
        align-items: center;
        color: $grey900;
        height: 50px;
        padding: 16px 16px 16px 60px;
        cursor: pointer;
        transition: background-color 0.2s ease-out;

        &:hover {
          background-color: $grey100;
        }

        &.active {
          background-color: $grey100;

          i {
            svg {
              path {
                fill: $blue500;
              }
            }
          }

          h6 {
            color: #3193ee;
          }
        }

        i {
          font-size: 0;

          svg {
            path {
              fill: $grey900;
            }
          }
        }

        h6 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          max-width: 200px;
          font-weight: 700;
        }
      }

      li.list-item {
        display: flex;
        gap: 13px;
        align-items: center;
        color: $grey900;
        height: 50px;
        padding: 16px 16px 16px 80px;
        cursor: pointer;
        transition: background-color 0.2s ease-out;

        h6 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          max-width: 200px;
          font-weight: 700;
        }

        &:hover {
          background-color: $grey100;
        }

        &.active {
          background-color: $grey100;
          h6 {
            color: #3193ee;
          }
        }
      }

      li.create-button {
        padding: 0 50px 35px;

        button {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;
          padding: 8px 12px;
          border-radius: 4px;
          font-weight: 500;
        }
      }
    }
  }
}

.recruit-right-container {
  max-width: calc(100% - 320px);
  width: 100%;
  height: 100%;
  background-color: $white;
  padding: 56px 80px 120px 0;
  overflow-y: auto;
}

// tab button
.toggle-tab-container {
  margin-bottom: 35px;

  ul {
    display: flex;
    align-items: center;

    li {
      position: relative;
      padding: 8.5px 22px;
      cursor: pointer;
      box-sizing: border-box;
      transition: all 0.2s;
      background-color: $grey100;
      font-weight: 500;
      text-align: center;
      width: 100px;

      &:not(:last-child) {
        border-right: 1px solid $blue50;
      }

      &.current,
      &.status {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &.all,
      &.setting {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &.active {
        background: $blue500;
        color: $white;
        font-weight: 700;
        z-index: 2;
      }
    }
  }
}

#pages-recruitment-detail {
  margin-top: -24px;

  .recruitment-tab-container {
    margin-bottom: 28px;

    ul {
      display: flex;
      border-bottom: 1px solid $grey100;

      li {
        position: relative;
        padding: 20px 24px;
        transition: background 0.2s;
        cursor: pointer;

        &::after {
          content: '';
          display: inline-block;
          width: 0;
          height: 2px;
          background-color: none;
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translate(-50%);
          transition: all 0.2s ease-out;
        }

        &.active {
          color: $blue500;

          &::after {
            width: 50%;
            background-color: $blue500;
          }
        }

        &:hover {
          background-color: $grey100;
        }
      }
    }
  }
}

// candidate list
.recruitment-candidate-list-container {
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .candidate-list-title-box {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.28px;
  }

  .candidate-list-search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

// dashboard
.dashboard-container {
  .dashboard-wrapper {
    ul {
      display: flex;
      gap: 28px;
      flex-direction: column;
      li {
        .process-area {
          .flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .recruitment-name {
            display: inline-block;
            font-weight: 600;
            color: $grey600;
            margin-bottom: 12px;
          }

          .step-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 24px;
            cursor: pointer;

            > .step {
              background-color: $grey300;
              padding: 8px 12px;
              border-radius: 32px;
              text-align: center;
              font-weight: 600;
              color: $white;
              position: relative;
              @media (min-width: 1430px) {
                flex: 1;
              }
              &:not(:last-child) {
                &::after {
                  content: '';
                  width: 16px;
                  height: 16px;
                  background: url('/assets/images/icon/chevron-right-round.svg');
                  background-position: center;
                  background-size: cover;
                  background-repeat: no-repeat;
                  position: absolute;
                  top: 50%;
                  right: -20px;
                  transform: translateY(-50%);
                }
              }

              &.reviewing {
                background-color: $blue700;
              }
              &.digital {
                background-color: $blue600;
              }
              &.in-person {
                background-color: $blue500;
              }
              &.document {
                background-color: $blue200;
              }
              &.offer {
                min-width: 75px;
                color: $grey600;
              }
              &.certificate {
                min-width: 105px;
                background-color: $green500;
              }
              &.reject {
                min-width: 80px;
                background-color: $red500;
              }
            }
          }

          &.no-area {
            display: block;

            .no-apply-candidate {
              display: block;
              background-color: $grey300;
              color: $grey700;
              text-align: left;
              padding: 8px 12px;
              border-radius: 32px;
              text-align: center;
              font-weight: 700;
              cursor: pointer;
            }
          }

          // span {
          //   display: inline-block;
          //   padding: 8px 0;
          //   border-radius: 8px;
          //   text-align: center;
          //   width: 100%;
          //   font-size: 14px;
          //   font-weight: 500;
          //   background-color: $red500;
          //   color: $white;

          //   &.no-apply-candidate {
          //     background-color: $grey300;
          //     color: $grey700;
          //     text-align: left;
          //     padding-left: 20px;
          //   }

          //   &.process-0 {
          //     background-color: $blue500;
          //     color: $white;
          //   }
          //   &.process-1 {
          //     background-color: $blue400;
          //     color: $white;
          //   }
          //   &.process-2 {
          //     background-color: $blue300;
          //     color: $white;
          //   }
          //   &.process-3 {
          //     background-color: $blue200;
          //     color: $white;
          //   }
          //   &.process-4 {
          //     background-color: $blue100;
          //     color: $grey700;
          //   }
          //   &.process-5 {
          //     background-color: $blue50;
          //     color: $grey700;
          //   }
          //   &.process-6 {
          //     background-color: $grey50;
          //     color: $grey700;
          //   }
          // }
        }
      }
    }
  }
}

// dashboard-detail
.dashboard-detail-container {
  .dashboard-detail-wrapper {
    .dashboard-detail-process-setting {
      margin-bottom: 44px;

      ul {
        display: flex;
        align-items: stretch;
        background-color: $grey50;
        padding: 8px;
        border-radius: 16px;
        overflow: hidden;
        gap: 24px;

        li {
          position: relative;

          > .icon-box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        li.tab {
          flex: 1;
          padding: 8px;
          font-weight: 700;
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-radius: 8px;
          transition: all 0.2s ease-in-out;
          position: relative;

          &.active {
            background-color: $blue500;
            color: $white;

            &.submission {
              background-color: $green500;

              span {
                color: $white;
              }
            }

            &.reject {
              background-color: $red500;

              span {
                color: $white;
              }
            }
          }

          &.disabled {
            background-color: $grey50;
            color: $grey400;
            cursor: default;

            span {
              color: $grey400 !important;
            }
          }

          &.submission {
            span {
              transition: all 0.2s ease-in-out;
              color: $green500;
            }
          }

          &.reject {
            span {
              transition: all 0.2s ease-in-out;
              color: $red500;
            }
          }

          &:not(:last-child) {
            &::after {
              content: '';
              background: url('/assets/images/icon/arrow-forward-ios.svg');
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              width: 16px;
              height: 16px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -20px;
            }
          }

          &.division {
            &::after {
              content: '';
              background: url('/assets/images/icon/frame-33059.svg');
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              width: 16px;
              height: 32px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -20px;
            }
          }
        }
      }
    }

    .dashboard-detail-search-form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .add-candidate {
        &.hidden {
          visibility: hidden;
        }
      }

      .search-filter-form {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }

    .table-border {
      &.dashboard-reviewing-contents {
        table {
          thead {
            tr {
              th {
                &:nth-child(1) {
                  width: 10%;
                }
                &:nth-child(2) {
                  width: 15%;
                }
                &:nth-child(3) {
                  width: 10%;
                }
                &:nth-child(4) {
                  width: 10%;
                }
                &:nth-child(5) {
                  width: 10%;
                }
                &:nth-child(6) {
                  width: 10%;
                }
                &:nth-child(7) {
                  width: 16%;
                }
                &:nth-child(8) {
                  width: 7%;
                }
                &:nth-child(9) {
                  width: 13%;
                }
              }
            }
          }
        }
      }

      &.dashboard-virtual-interview-contents {
        table {
          thead {
            tr {
              th {
                &:nth-child(1) {
                  width: 10%;
                }
                &:nth-child(2) {
                  width: 15%;
                }
                &:nth-child(3) {
                  width: 15%;
                }
                &:nth-child(4) {
                  width: 15%;
                }
                &:nth-child(5) {
                  width: 15%;
                }
                &:nth-child(6) {
                  width: 20%;
                }
                &:nth-child(7) {
                  width: 10%;
                }
                &:nth-child(8) {
                  width: 10%;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                .bar-graph-score {
                  .progress-wrapper {
                    max-width: 150px;
                  }
                }
                &.remind-button-wrapper {
                  display: flex;
                  gap: 8px;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
      }

      &.dashboard-interview-contents {
        table {
          thead {
            tr {
              th {
                &:nth-child(1) {
                  width: 15%;
                }
                &:nth-child(2) {
                  width: 20%;
                }
                &:nth-child(3) {
                  width: 12%;
                }
                &:nth-child(4) {
                  width: 15%;
                }
                &:nth-child(5) {
                  width: 15%;
                }
                &:nth-child(6) {
                  width: 13%;
                }
                &:nth-child(7) {
                  width: 10%;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                .flex {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  span {
                    width: 50px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .resend-email-button {
                    white-space: nowrap;
                  }
                }

                .td-container {
                  display: inline-block; /* 내용을 가로로 정렬 */
                  text-align: left;
                  .opinion {
                    display: flex;
                    width: fit-content;
                    height: 26px;
                    padding: 8px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 28px;
                    border: 1px solid #d9d9d9;
                    color: #333;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    &.Strong {
                      border: 0;
                      background: #0baa43;
                      color: #fff;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                    }

                    &.Shortlist {
                      border: 0;
                      background: #fff338;
                      color: #333;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                    }

                    &.other {
                      border: 0;
                      background: #f3913a;
                      color: #fff;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                    }

                    &.Reject,
                    &.Giveup {
                      border: 0;
                      background: #e22b17;
                      color: #fff;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                    }
                  }

                  .invite-button {
                    display: flex;
                    width: fit-content;
                    height: 26px;
                    padding: 8px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 28px;
                    border: 1px solid #d9d9d9;
                    color: #333;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    &:hover {
                      background-color: #d9d9d9;
                    }
                  }
                }

                .bar-graph-score {
                  .progress-wrapper {
                    max-width: 150px;
                  }
                }
              }
            }
          }
        }
      }

      &.dashboard-offer-contents {
        table {
          thead {
            tr {
              th {
                &:nth-child(1) {
                  width: 20%;
                }
                &:nth-child(2) {
                  width: 25%;
                }
                &:nth-child(3) {
                  width: 40%;
                }
                &:nth-child(4) {
                  width: 15%;
                }
              }
            }
          }
        }
      }

      &.dashboard-onboarding-contents {
        table {
          thead {
            tr {
              th {
                &:nth-child(1) {
                  width: 10%;
                }
                &:nth-child(2) {
                  width: 20%;
                }
                &:nth-child(3) {
                  width: 25%;
                }
                &:nth-child(4) {
                  width: 15%;
                }
                &:nth-child(5) {
                  width: 15%;
                }
                &:nth-child(6) {
                  width: 25%;
                }
              }
            }
          }
        }
      }

      &.dashboard-reject-contents {
        table {
          thead {
            tr {
              th {
                &:nth-child(1) {
                  width: 20%;
                }
                &:nth-child(2) {
                  width: 25%;
                }
                &:nth-child(3) {
                  width: 30%;
                }
                &:nth-child(4) {
                  width: 12.5%;
                }
                &:nth-child(5) {
                  width: 12.5%;
                }
              }
            }
          }
        }
      }
    }
  }

  // component

  // button
  .recruitment-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;
    padding: 8px 8.5px;
    border: 1px solid #999;
    height: 40px;
    color: #333333;
    font-weight: 500;

    &.false {
      opacity: 0.5;
      cursor: default;
    }
  }

  .setting-recruitment-container {
    overflow-y: auto !important;
    padding: 0 !important;

    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      height: 80px;
      background-color: $blue500;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 2;

      > .header-title {
        font-weight: bold;
        color: white;
      }

      > .header-close {
        cursor: pointer;
        width: 36px;
        height: 36px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &::after {
          content: '';
          width: 21px;
          height: 21px;
          mask-image: url('/assets/images/icon/close.svg');
          mask-position: center;
          mask-size: contain;
          mask-repeat: no-repeat;
          background-color: white;
        }
      }
    }

    > .container {
      margin: 5px auto 40px 0;
      width: 95%;

      .wrapper {
        display: flex;

        &.schedule-wrapper {
          margin-bottom: 77px;
        }

        .application-link-container {
          display: flex;
          padding-bottom: 0;
          align-items: center;

          .application-link-copy-button {
            border-radius: 4px;
            border: 1px solid $blue500;
            background: $white;
            overflow: hidden;

            .copy-button {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 6px;
              color: #3193ee;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              padding: 8px 12px;
            }
          }
        }

        > .left {
          .title-box {
            display: flex;
            color: #333;
            font-size: 16px;
            font-style: normal;
            line-height: normal;

            pre {
              font-weight: 600 !important;
            }
          }
        }

        > .right {
          .label-row-group {
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;

            > .form-assessments {
              max-width: 300px;
              text-align: left;

              input {
                z-index: 0;
              }

              .toggle {
                margin-right: 0;
              }
            }
          }
        }

        .save-button {
          color: $white;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          display: flex;
          width: 220px;
          padding: 18px 80px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          background: #3193ee;
        }
      }
    }
  }
}

// input text
.recruitment-input-component {
  position: relative;
  display: inline-block;

  .recruitment-input {
    padding: 8px 15px;
    padding-right: 43px;
    border: 1px solid $grey300;
    border-radius: 32px;
    max-width: 244px;
    font-weight: 500;

    &::placeholder {
      color: $grey400;
    }
  }

  i.icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    svg {
      path {
        fill: $grey900;
      }
    }
  }
}

// select and input
.recruitment-select-input-component {
  display: flex;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid $grey300;
  background-color: $white;
  min-width: 325px;

  .search-select-wrapper {
    position: relative;
    min-width: 110px;

    &::after {
      content: '';
      width: 1px;
      height: 10px;
      background-color: $grey300;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    .search-select-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding-right: 10px;
      cursor: pointer;

      span {
        color: $grey900;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 145%;
        letter-spacing: -0.12px;
      }
    }

    .dropdown-box {
      position: absolute;
      width: calc(110% + 20px);
      top: 35px;
      overflow: hidden;
      padding: 10px;
      right: -10px;
      z-index: 2;

      > .search-select-box {
        padding: 8px 0;
        background-color: $white;
        overflow: auto;
        width: 100%;
        border-radius: 4px;
        text-align: left;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
          0px 2px 6px 2px rgba(0, 0, 0, 0.15);

        &.slide-in {
          animation: slide-in ease-out 0.15s;
        }

        &.slide-out {
          animation: slide-out ease-out 0.15s forwards;
        }

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $grey500;
          border-radius: 10px;
          border-left: 1px solid $grey100;
          border-right: 1px solid $grey100;
        }

        &::-webkit-scrollbar-track {
          margin: 3px 0;
          border-radius: 10px;
          background-color: $grey100;
        }

        &::-webkit-scrollbar-button {
          width: 0;
          height: 0;
        }

        @keyframes slide-in {
          0% {
            transform: translateY(-100%);
          }

          100% {
            transform: translateY(0);
          }
        }

        @keyframes slide-out {
          0% {
            transform: translateY(0);
          }

          100% {
            transform: translateY(calc(-100% - 10px));
          }
        }

        > li {
          padding: 12px 16px;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 145%; /* 17.4px */
          letter-spacing: -0.12px;
          cursor: pointer;
          transition: background 0.2s;

          &:hover {
            background: $grey100;
          }
        }
      }
    }
  }

  .search-icon {
    cursor: pointer;

    svg {
      path {
        fill: $grey900;
      }
    }
  }
}

// table
.table-border {
  border: 1px solid $blue50;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;

  .recruitment-table {
    width: 100%;
    text-align: left;
    min-width: 900px;

    caption {
      text-indent: -100000px;
      position: absolute;
    }

    th,
    td {
      padding: 14px 10px;
      font-size: 12px;
      position: relative;
      vertical-align: middle;

      &.resume {
        max-width: 274px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:first-child {
        padding-left: 16px;
      }
      &:last-child {
        padding-right: 24px;
      }

      &:not(:last-child) {
        &::after {
          content: '';
          display: inline-block;
          width: 1px;
          height: 6px;
          background-color: $grey200;
          position: absolute;
          top: 50%;
          right: 1px;
          transform: translateY(-50%);
        }
      }
    }

    thead {
      background-color: $grey50;

      tr {
        box-sizing: border-box;
        border-bottom: 1px solid $grey100;
        border-collapse: collapse;

        th {
          color: $blue600;
          font-weight: 700;

          &::after {
            background-color: $blue200;
          }
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid $grey100;
        }

        td {
          vertical-align: middle;
          .recruitment-table-dropdown {
            >.recruitment-table-dropdown-wrapper {
              width: 24px;
              display: flex;
              justify-content: center;
              cursor: pointer;
              align-items: center;
            }

              > .recruitment-table-dropdown-box {
                z-index: 99;
                background: white;
                position: fixed;
                right: 120px;
                box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
                rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
                border-radius: 8px;

                .recruitment-table-dropdown-list {
                  font-size: 12px;
                  padding: 16px;
                  &:hover {
                    background-color: $grey100;
                  }
                }
            }
          }

          span.tag-kiv {
            padding: 4px 6px;
            border-radius: 16px;
            border: 1px solid $blue200;
            background-color: $grey50;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 13px */
            letter-spacing: -0.1px;
            color: $blue400;
            margin-left: 4px;
          }

          .more-icon {
            cursor: pointer;
            width: 24px;
            height: 24px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            &::before {
              content: url('/assets/images/icon/more.svg');
            }
          }
        }
      }
    }
  }
}

// vitual test
.virtual-test-container {
  &:not(:first-child) {
    padding-top: 16px;
  }
  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid $grey100;
  }

  .question-title {
    margin-bottom: 16px;
  }

  .question-answer-wrapper {
    display: flex;
    gap: 20px;

    > * {
      flex: 1;
    }

    .question-box {
      p {
        word-break: keep-all;
      }
    }

    .answer-box {
      h6 {
        margin-bottom: 8px;
      }

      .video-box {
        background-color: $black;
        border-radius: 8px;
        overflow: hidden;
        text-align: center;

        video {
          max-width: 100%;
          max-height: 130px;
        }
      }

      .text-box {
        border: 1px solid $grey200;
        padding: 15px;
        border-radius: 8px;
        max-height: 250px;
        overflow-y: auto;
      }

      .img-box {
        img {
          max-width: 100%;
        }
      }
    }
  }
}

// create recruitment input text
.recruitment-input-text {
  display: inline-block;
  width: 100%;
  background-color: #f2f2f2;
  border: 1px solid #999;
  border-radius: 8px;
  padding: 8px 20px;

  &::placeholder {
    color: #808080;
    font-weight: 500;
  }
}

// create recruitment radio
.radio-group-box {
  display: flex;
  align-items: stretch;
  border: 1px solid $grey200;
  border-radius: 8px;
  width: fit-content;
  overflow: hidden;

  > .recruitment-radio-wrapper {
    .recruitment-radio-input {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;

      &:checked + label {
        span {
          font-size: 14px;
          font-weight: 700;
          background-color: $blue500;
          color: $white;
        }
      }
    }

    .recruitment-radio-label {
      display: flex;
      justify-content: center;
      height: 100%;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        font-weight: 500;
        background-color: $grey50;
        color: #808080;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid $grey200;
    }
  }

  &.disabled {
    border-color: $grey200;

    > .recruitment-radio-wrapper {
      .recruitment-radio-label {
        color: $grey400;
        background-color: $grey50;
        border-color: $grey200;
      }

      &:not(:last-child) {
        border-color: $grey200;
      }
    }
  }
}

// create recruitment select box
.recruitment-dropdown-container {
  position: relative;

  .dropdown-toggle-button {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 8px 6px 8px 12px;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.01em;
    color: $grey700;
    border: 1px solid $blue50;
    border-radius: 4px;
    width: 100%;
    transition: all 0.2s;
    box-shadow: 0px 6.657243728637695px 16.643108367919922px 0px
      rgba(50, 148, 238, 0.1);

    > span {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: 150px;

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:hover {
      border-color: $grey700;
    }
    &:focus {
      border-color: $blue500;
      box-shadow: 0px 0px 0px 3px rgba(50, 148, 238, 0.25);
    }
    &:disabled {
      * {
        opacity: 0.6;
        color: $grey500;
      }
    }
  }

  .dropdown-list-box {
    position: absolute;
    width: calc(100% + 20px);
    top: 100%;
    overflow: hidden;
    padding: 0 10px 10px;
    left: -10px;
    z-index: 2;

    > .dropdown-list {
      padding: 8px 0;
      background-color: $white;
      overflow: auto;
      width: 100%;
      border-radius: 4px;
      text-align: left;
      max-height: 250px;
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
        0px 1px 2px rgba(0, 0, 0, 0.3);

      &.slide-in {
        animation: slide-in ease-out 0.15s;
      }

      &.slide-out {
        animation: slide-out ease-out 0.15s forwards;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $grey500;
        border-radius: 10px;
        border-left: 1px solid $grey100;
        border-right: 1px solid $grey100;
      }

      &::-webkit-scrollbar-track {
        margin: 3px 0;
        border-radius: 10px;
        background-color: $grey100;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
      }

      @keyframes slide-in {
        0% {
          transform: translateY(-100%);
        }

        100% {
          transform: translateY(0);
        }
      }

      @keyframes slide-out {
        0% {
          transform: translateY(0);
        }

        100% {
          transform: translateY(calc(-100% - 10px));
        }
      }

      > .dropdown-list-item {
        padding: 12px 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 145%;
        cursor: pointer;
        transition: background 0.2s;

        &:hover {
          background: $grey100;
        }
      }
    }
  }
}
