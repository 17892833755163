@import '../include.scss';

.pages-protected-tests-list {
  padding-top: 0px !important;
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 104px;
    // margin-bottom: 40px;

    > .header-title {
      // font-size: 28px;
      font-weight: bold;
    }

    > .header-right {
      > .header-right-search {
        > .header-right-search-input {
          width: 300px;
          height: 56px;
        }
      }
    }
  }

  > .table {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 32px;
    gap: 32px;
    background: #ffffff;
    border: 1px solid $grey100;
    border-radius: 4px;
    width: 100%;

    .MuiBox-root {
      width: 100%;
    }

    > .tabs {
      width: 100%;
      margin-bottom: 16px;

      > ul.tabs-list {
        display: flex;
        overflow-x: auto;
        position: relative;
        --li-width: 0px;
        width: 100%;
        height: 64px;
        align-items: center;
        border-bottom: 1px solid $grey100;

        > li.tabs-list-item {
          height: 100%;
          > .container {
            cursor: pointer;
            padding: 0 24px;
            height: 100%;
            border-radius: 4px;
            transition: background-color 0.2s;

            > span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              // padding: 18px 0;
              height: 100%;
              font-weight: 700;
              // font-size: 18px;
              white-space: nowrap;
            }

            &:hover {
              background-color: $grey100;
            }
          }

          &.selected {
            > .container {
              // border-bottom: 2px solid $blue500;

              > span {
                color: $blue500;
                border-bottom: 2px solid $blue500;
              }
            }
          }
        }

        > .indicator {
          position: absolute;
          left: 0;
          bottom: 0;
          width: var(--li-width);
          height: 2px;
          background-color: $blue500;
        }
      }
    }

    ul.skills-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, 306px);
      grid-gap: 24px;
      justify-content: space-around;
      width: 100%;
      padding: 0 24px;

      > li.skills-list-item {
        // >.card {
        //     // width: 322px;
        //     height: 350px;
        //     background-color: white;
        //     border-radius: 16px;
        //     padding: 24px;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: space-between;

        //     >header {
        //         font-size: 18px;
        //         line-height: 26.1px;
        //         font-weight: bold;
        //     }

        //     >main {
        //         >ul {
        //             display: flex;
        //             gap: 16px;

        //             >li {
        //                 padding: 6px 12px;
        //                 background-color: rgba($blue500, 0.3);
        //                 border-radius: 8px;
        //                 font-size: 14px;
        //                 color: $grey900;
        //             }
        //         }
        //     }

        //     >footer {
        //         >span {
        //             font-size: 18px;
        //             font-weight: bold;

        //             &::before {
        //                 content: url('/assets/images/icon/timer.svg');
        //                 margin-right: 7px;
        //                 position: relative;
        //                 top: 3px;
        //             }
        //         }
        //     }
        // }
      }
    }

    .thead {
      &.title {
      }

      &.description {
      }

      &.duration {
      }

      &.actions {
      }
    }

    .tbody {
      &.title {
      }

      &.description {
      }

      &.duration {
        &::before {
          content: url('/assets/images/icon/timer.svg');
          margin-right: 7px;
          position: relative;
          top: 5px;
        }
      }

      &.actions {
        > .container {
          display: flex;
          justify-content: flex-end;

          > button {
            padding: 8px 16px;
            border-radius: 100px;

            &::after {
              content: url('/assets/images/icon/chevron-right.svg');
              margin-left: 14px;
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }

    .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 40px;

      > .thumbnail {
        width: 360px;
        height: 360px;
        background-image: url('https://user-images.githubusercontent.com/24651852/183352229-ad5a0ad9-c481-4a09-ad03-7afaccab6331.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 40px;
      }

      > p {
        // font-size: 28px;
        text-align: center;
        // line-height: 36.4px;
      }
    }
  }
}
